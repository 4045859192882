var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("新增客户")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveCustomer.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("客户信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-text-field', {
    attrs: {
      "label": "客户名称*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "person"
    },
    model: {
      value: _vm.customerItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "name", $$v);
      },
      expression: "customerItem.name"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "prepend-icon": "map"
    },
    model: {
      value: _vm.customerItem.area,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "area", $$v);
      },
      expression: "customerItem.area"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.electricType,
      "label": "用电类别*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "home_work"
    },
    model: {
      value: _vm.customerItem.electric_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "electric_type", $$v);
      },
      expression: "customerItem.electric_type"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "公司内部负责人*",
      "rules": [_vm.rules.selected],
      "hint": "请查找并选择公司内部负责人",
      "prepend-icon": "emoji_people"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      }
    },
    model: {
      value: _vm.customerItem.confirm_settle,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "confirm_settle", $$v);
      },
      expression: "customerItem.confirm_settle"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "客户联系人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "connect_without_contact"
    },
    model: {
      value: _vm.customerItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contact_name", $$v);
      },
      expression: "customerItem.contact_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "联系电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone"
    },
    model: {
      value: _vm.customerItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contact_phone", $$v);
      },
      expression: "customerItem.contact_phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "公司地址",
      "outlined": "",
      "dense": "",
      "prepend-icon": "place"
    },
    model: {
      value: _vm.customerItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "address", $$v);
      },
      expression: "customerItem.address"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "设备总容量",
      "outlined": "",
      "dense": "",
      "prepend-icon": "settings_applications",
      "suffix": "兆瓦"
    },
    model: {
      value: _vm.customerItem.total_capacity,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "total_capacity", $$v);
      },
      expression: "customerItem.total_capacity"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "设备可调容量",
      "outlined": "",
      "dense": "",
      "prepend-icon": "manage_history",
      "suffix": "兆瓦"
    },
    model: {
      value: _vm.customerItem.adjustable_capacity,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "adjustable_capacity", $$v);
      },
      expression: "customerItem.adjustable_capacity"
    }
  }), _c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-divider'), _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-avatar', {
    attrs: {
      "rounded": "0",
      "size": "80"
    }
  }, [!_vm.preUrl ? _c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("image")]) : _c('v-img', {
    attrs: {
      "src": _vm.preUrl ? _vm.preUrl : '',
      "width": "80",
      "height": "80"
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "caption"
  }, [_vm._v(" 客户营业执照，支持JPG、PNG图片文件 ")])], 1), _c('v-list-item-action', [_c('v-row', [_c('v-col', [_vm.preUrl ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.removeImg('license_image');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1) : _vm._e()], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('license_image');
      }
    }
  }, [_c('v-icon', [_vm._v("upload")])], 1)], 1)], 1)], 1)], 1), _c('v-divider')], 1), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": ".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("合同信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "合同类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "prepend-icon": "list"
    },
    model: {
      value: _vm.customerItem.contracts[0].business_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "business_type", $$v);
      },
      expression: "customerItem.contracts[0].business_type"
    }
  }), _vm.customerItem.contracts[0].business_type ? _c('v-text-field', {
    attrs: {
      "label": "合同名称*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "gavel"
    },
    model: {
      value: _vm.customerItem.contracts[0].contract_name,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "contract_name", $$v);
      },
      expression: "customerItem.contracts[0].contract_name"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-text-field', {
    attrs: {
      "label": "档案号",
      "outlined": "",
      "dense": "",
      "prepend-icon": "pin"
    },
    model: {
      value: _vm.customerItem.contracts[0].file_no,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "file_no", $$v);
      },
      expression: "customerItem.contracts[0].file_no"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-text-field', {
    attrs: {
      "label": "年用电量*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "兆瓦时",
      "prepend-icon": "bolt"
    },
    model: {
      value: _vm.customerItem.contracts[0].electric_use,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "electric_use", $$v);
      },
      expression: "customerItem.contracts[0].electric_use"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "签约时间*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "fact_check"
          },
          model: {
            value: _vm.customerItem.contracts[0].sign_date,
            callback: function callback($$v) {
              _vm.$set(_vm.customerItem.contracts[0], "sign_date", $$v);
            },
            expression: "customerItem.contracts[0].sign_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2494389717),
    model: {
      value: _vm.sign_date_menu,
      callback: function callback($$v) {
        _vm.sign_date_menu = $$v;
      },
      expression: "sign_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.sign_date_menu = false;
      }
    },
    model: {
      value: _vm.customerItem.contracts[0].sign_date,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "sign_date", $$v);
      },
      expression: "customerItem.contracts[0].sign_date"
    }
  })], 1) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_vm.customerItem.contracts[0].business_type ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同开始日期*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available"
          },
          model: {
            value: _vm.customerItem.contracts[0].contract_start,
            callback: function callback($$v) {
              _vm.$set(_vm.customerItem.contracts[0], "contract_start", $$v);
            },
            expression: "customerItem.contracts[0].contract_start"
          }
        }, 'v-text-field', attrs, false), on)) : _vm._e()];
      }
    }], null, false, 1185248852),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      }
    },
    model: {
      value: _vm.customerItem.contracts[0].contract_start,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "contract_start", $$v);
      },
      expression: "customerItem.contracts[0].contract_start"
    }
  })], 1) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_vm.customerItem.contracts[0].business_type ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同结束日期*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_busy"
          },
          model: {
            value: _vm.customerItem.contracts[0].contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.customerItem.contracts[0], "contract_end", $$v);
            },
            expression: "customerItem.contracts[0].contract_end"
          }
        }, 'v-text-field', attrs, false), on)) : _vm._e()];
      }
    }], null, false, 2850428248),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      }
    },
    model: {
      value: _vm.customerItem.contracts[0].contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "contract_end", $$v);
      },
      expression: "customerItem.contracts[0].contract_end"
    }
  })], 1) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-text-field', {
    attrs: {
      "label": "签约电价*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.customerItem.contracts[0].price,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "price", $$v);
      },
      expression: "customerItem.contracts[0].price"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-select', {
    attrs: {
      "items": _vm.signType,
      "label": "签约方式*",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "rules": [_vm.rules.selected],
      "dense": "",
      "prepend-icon": "checklist"
    },
    model: {
      value: _vm.customerItem.contracts[0].sign_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "sign_type", $$v);
      },
      expression: "customerItem.contracts[0].sign_type"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type && _vm.customerItem.contracts[0].sign_type === 5 ? _c('v-text-field', {
    attrs: {
      "label": "签约方式说明",
      "outlined": "",
      "dense": "",
      "prepend-icon": "assignment"
    },
    model: {
      value: _vm.customerItem.contracts[0].sign_other,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "sign_other", $$v);
      },
      expression: "customerItem.contracts[0].sign_other"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type && _vm.customerItem.contracts[0].sign_type === 1 ? _c('v-text-field', {
    attrs: {
      "label": "鑫泰比例",
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%",
      "prepend-icon": "group_add"
    },
    model: {
      value: _vm.customerItem.contracts[0].xt_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "xt_ratio", $$v);
      },
      expression: "customerItem.contracts[0].xt_ratio"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type && _vm.customerItem.contracts[0].sign_type === 1 ? _c('v-text-field', {
    attrs: {
      "label": "客户比例",
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%",
      "prepend-icon": "group_remove"
    },
    model: {
      value: _vm.customerItem.contracts[0].customer_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "customer_ratio", $$v);
      },
      expression: "customerItem.contracts[0].customer_ratio"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type && _vm.customerItem.contracts[0].sign_type === 3 ? _c('v-text-field', {
    attrs: {
      "label": "固定单价金额",
      "outlined": "",
      "rules": [_vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.customerItem.contracts[0].fixed_price,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "fixed_price", $$v);
      },
      expression: "customerItem.contracts[0].fixed_price"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type && _vm.customerItem.contracts[0].sign_type === 2 ? _c('v-text-field', {
    attrs: {
      "label": "固定服务费金额",
      "outlined": "",
      "rules": [_vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.customerItem.contracts[0].fixed_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "fixed_fee", $$v);
      },
      expression: "customerItem.contracts[0].fixed_fee"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-text-field', {
    attrs: {
      "label": "承担偏差",
      "outlined": "",
      "dense": "",
      "prepend-icon": "running_with_errors"
    },
    model: {
      value: _vm.customerItem.contracts[0].bear_diff,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "bear_diff", $$v);
      },
      expression: "customerItem.contracts[0].bear_diff"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-textarea', {
    attrs: {
      "label": "备注",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "no-resize": "",
      "prepend-icon": "comment"
    },
    model: {
      value: _vm.customerItem.contracts[0].remark,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem.contracts[0], "remark", $$v);
      },
      expression: "customerItem.contracts[0].remark"
    }
  }) : _vm._e(), _vm.customerItem.contracts[0].business_type ? _c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-divider'), _c('v-list-item', {
    staticClass: "px-0",
    attrs: {
      "bordered": ""
    }
  }, [_c('v-list-item-avatar', {
    attrs: {
      "rounded": "0",
      "size": "80"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("description")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "caption"
  }, [_c('div', [_vm._v("合同文件，支持图片、PDF、Word格式及压缩包")]), _vm.customerItem.contracts[0].file_name ? _c('div', [_vm._v("当前文件："), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.customerItem.contracts[0].file_name))])]) : _vm._e()])], 1), _c('v-list-item-action', [_c('v-row', [_c('v-col', [_vm.customerItem.contracts[0].file_name ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.removeImg('href');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1) : _vm._e()], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('href');
      }
    }
  }, [_c('v-icon', [_vm._v("upload")])], 1)], 1)], 1)], 1)], 1), _c('v-divider')], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveCustomer
    }
  }, [_vm._v(" 保存 ")]), _c('v-spacer')], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.Upload.uploading,
      callback: function callback($$v) {
        _vm.$set(_vm.Upload, "uploading", $$v);
      },
      expression: "Upload.uploading"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-card-title', [_vm._v("文件上传中...")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "color": "white",
      "value": _vm.Upload.progressUpload
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }