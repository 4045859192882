var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/distributor/report');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("公司数据统计")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "location_city",
      "count": _vm.report.customerCount,
      "label": "客户总数",
      "path": "/dealer/enterprise"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "real_estate_agent",
      "count": _vm.report.agencyCount,
      "label": "代理商总数",
      "path": "/dealer/asset/list"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "bolt",
      "count": _vm.$options.filters['formatMoneyUnitInt'](_vm.report.totalElec),
      "label": "累计电量(兆瓦时)",
      "path": "/dealer/workbench/order/list"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "attach_money",
      "count": _vm.$options.filters['formatMoneyUnitInt'](_vm.report.totalXtServiceFee),
      "label": "累计鑫泰服务费(元)",
      "path": "/dealer/workbench"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "price_check",
      "count": _vm.$options.filters['formatMoneyUnitInt'](_vm.report.totalNetProfit),
      "label": "累计净利润(元)",
      "path": "/dealer/workbench"
    }
  })], 1)], 1), _c('report-elec-analysis'), _c('report-service-analysis'), _c('report-income-analysis')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }