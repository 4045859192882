<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>主体管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
          @keyup="distributor_list"
          label="关键字搜索（按回车）"
          prepend-inner-icon="search"
          solo
          flat
          dense
          hide-details
          clearable
        ></v-text-field>
<!--        <v-text-field-->
<!--          v-model="search"-->
<!--          @keydown.enter="distributor_list;"-->
<!--          dark-->
<!--          class="mx-4"-->
<!--          flat-->
<!--          hide-details-->
<!--          label="搜索经销商名称"-->
<!--          prepend-inner-icon="search"-->
<!--          solo-inverted-->
<!--          dense-->
<!--        ></v-text-field>-->
        <v-btn class="mx-2" color="primary" depressed @click="$router.push({path:'/admin/distributor/edit/0'})"><v-icon>add</v-icon> 注册经销商</v-btn>
      </v-toolbar>
      <v-tabs right>
        <v-tab @click="citys = ''">全部</v-tab>
        <v-tab v-for="(city, i) in distributor_city" :key="i" @click="citys = city">{{city || '未知'}}</v-tab>
        <v-tab-item v-for="n in distributor_city.length + 1" :key="n">
          <v-container fluid>
            <v-row>
              <v-col v-for="(item, j) in distributor_list.filter(n => { return n.city === citys || citys === ''})" :key="j" cols="12" md="4">
                <v-skeleton-loader
                  :loading="distributorLoading"
                  type="card"
                >
                  <v-card class="mx-auto" max-width="360" outlined>
                    <v-img
                      :src="item.profileImage ? ossURL +'/'+ item.profileImage : ossURL+'/noimage.jpg'"
                      class="white--text align-end"
                      :aspect-ratio="16/9"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      dark>
                      <v-card-title class="text-right justify-end">
                        <v-icon @click="action('set_bookmark', item)">{{item.isDefault ? 'bookmark' : 'bookmark_border'}}</v-icon>
                      </v-card-title>
                      <v-card-title class="text-center justify-center">
                        <v-img
                          :src="item.logoImage ? ossURL +'/'+ item.logoImage : ossURL+'/noimage.jpg'"
                          aspect-ratio="1" contain max-width="55">
                        </v-img>
                      </v-card-title>
                      <v-card-title class="text-center justify-center">
                        <div class="hideText">{{ item.sub_title || '未设置标语' }}</div>
                      </v-card-title>
                    </v-img>
                    <v-card-title>
                      <div class="hideText">{{item.name}}</div>
                    </v-card-title>
                    <v-card-subtitle>
                      <v-icon color="primary" small class="mr-1">people</v-icon>
                      <span class="subheading mr-2">{{item.employeeCount || 0}}</span>
                    </v-card-subtitle>
                    <v-card-text>
                      <div></div>
                      <div class="hideText">{{item.address || '地址未设置'}}</div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-chip v-if="item.isBlock" class="ma-2" small outlined label>暂停运营</v-chip>
                      <v-chip v-else-if="item.status === 1" class="ma-2" small outlined label>待审核</v-chip>
                      <v-chip v-else class="ma-2" small label>正常运营</v-chip>
                      <v-spacer></v-spacer>
                      <v-btn v-if="item.isBlock" text @click="action('recover', item)">恢复运营</v-btn>
                      <v-btn v-else-if="item.status === 1" text @click="action('auth', item)">审核</v-btn>
                      <v-btn v-else text @click="action('block', item)">暂停运营</v-btn>
                      <v-btn color="secondary" text @click="$router.push({path:'/admin/distributor/edit/'+ item._id})">编辑</v-btn>
                      <v-btn text @click="action('delete', item)">删除</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_DISTRIBUTOR_LIST,
  AUTH_DISTRIBUTOR,
  BLOCK_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
  BOOKMARK_DISTRIBUTOR,
} from "@/store/actions.type";
export default {
  data() {
    return {
      search: '',
      citys: '',
    }
  },
  created() {},
  computed: {
    distributor_list() {
      if (this.search !== '') {
        const key = this.search.toLowerCase();
        return this.distributors.filter(({ name }) =>
          name.toLowerCase().includes(key)
        );
      } else {
        return this.distributors;
      }
    },
    ...mapGetters(["currentUser", "distributors", "distributor_city", "distributorLoading"]),
  },
  mounted () {
    this.getList();
  },
  methods: {
    getList() {
      this.$store.dispatch(FETCH_DISTRIBUTOR_LIST);
    },
    action(type, item) {
      if (type === 'auth') {
        if (confirm('确定要审核 '+ item.name +' 审核通过后该经销商即可提供企业服务。')) {
          this.$store.dispatch(AUTH_DISTRIBUTOR, item)
            .then(() => {
              this.getList();
              this.$store.commit(SET_ERROR, {msg: '审核成功', color: 'success'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
        }
      }
      if (type === 'block') {
        if (confirm('确定要阻止 '+ item.name +' ，阻止成功后该经销商将不能为企业提供服务。')) {
          this.$store.dispatch(BLOCK_DISTRIBUTOR, {_id: item._id, reply: true})
            .then(() => {
              this.getList();
              this.$store.commit(SET_ERROR, {msg: '阻止成功', color: 'success'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
        }
      }
      if (type === 'recover') {
        if (confirm('确定要恢复 '+ item.name +' ，恢复成功后该经销商可继续为企业提供服务。')) {
          this.$store.dispatch(BLOCK_DISTRIBUTOR, {_id: item._id, reply: false})
            .then(() => {
              this.getList();
              this.$store.commit(SET_ERROR, {msg: '恢复成功', color: 'success'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
        }
      }
      if (type === 'delete') {
        if (confirm('确定要删除 '+ item.name +', 删除后该经销商及所属员工将不能登陆系统。')) {
          if (confirm('该操作不能撤销或恢复，请再次确认，删除后该经销商不能恢复，且该经销商所有账号及资料将被删除。')) {
            this.$store.dispatch(DELETE_DISTRIBUTOR, item)
              .then(() => {
                this.getList();
                this.$store.commit(SET_ERROR, {msg: '删除成功，所有账号和资料已被删除。', color: 'success'});
              })
              .catch((err) => {
                this.$store.commit(SET_ERROR, {msg: err});
              });
          }
        }
      }
      if (type === 'set_bookmark') {
        let reply = !item.isDefault
        let hasDefault = this.distributor_list.find(o => o.isDefault)
        if (hasDefault && reply) {
          this.$store.commit(SET_ERROR, {msg: '已经存在默认经销商，请先取消默认经销商后再设置其它经销商为默认'});
        } else {
          this.$store.dispatch(BOOKMARK_DISTRIBUTOR, {_id: item._id, reply: reply})
            .then(() => {
              this.getList();
              this.$store.commit(SET_ERROR, {msg: '设置成功', color: 'success'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
        }
      }
    },
  },
  directives: {}
}
</script>
<style>
  .hideText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
