<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>客户详情</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <widgets-watermark :watermarkText="wmText"></widgets-watermark>
        <v-toolbar flat>
          <span class="panelTitle">基本信息</span>
          <v-spacer></v-spacer>
          <v-btn v-if="isEdit" depressed color="error" @click="doAction('open_delete')"><v-icon left>delete</v-icon>删除客户</v-btn>
        </v-toolbar>
        <v-card flat>
          <v-card-title class="pl-8">
            {{ customerInfo.name }}
            <v-btn v-if="isEdit" icon small class="ml-2" @click="editCustomer('name', '客户名称')">
              <v-icon small>edit</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        所属地区
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editCustomer('area', '所属地区')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="customerInfo.area">
                        {{ customerInfo.area.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        用电类别
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editCustomer('electric_type', '用电类别')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="customerInfo.electric_type === 1">工业</span>
                        <span v-else-if="customerInfo.electric_type === 2">商业</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
<!--              <v-col cols="3">-->
<!--                <v-list subheader three-line>-->
<!--                  <v-list-item @click="editCustomer('agency', '代理商')">-->
<!--                    <v-list-item-content>-->
<!--                      <v-list-item-title class="body-1">-->
<!--                        代理商-->
<!--                        <v-icon x-small class="ml-2 mb-1">edit</v-icon>-->
<!--                      </v-list-item-title>-->
<!--                      <v-list-item-subtitle>-->
<!--                        {{ customerInfo.agency?customerInfo.agency.name:'无'}}-->
<!--                      </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->
<!--                </v-list>-->
<!--              </v-col>-->
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        内部负责人
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editCustomer('confirm_settle', '内部负责人')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="customerInfo.confirm_settle">
                        {{ customerInfo.confirm_settle.personal.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        客户联系人
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editCustomer('contact_name', '客户联系人')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ customerInfo.contact_name || '无' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        客户联系方式
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editCustomer('contact_phone', '联系电话')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ customerInfo.contact_phone || '无' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        公司地址
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editCustomer('address', '公司地址')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ customerInfo.address || '无' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        客户营业执照
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-btn text color="primary" small v-if="customerInfo.license_image" @click="doAction('check_img')">查看</v-btn>
                        <v-btn v-if="isEdit" text color="primary" small @click="editCustomer('license_image', '客户营业执照')">上传</v-btn>
                        <v-btn text color="error" small v-if="customerInfo.license_image" @click="doAction('delete_img')">删除</v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">合同明细</span>
          <v-spacer></v-spacer>
          <contract-edit getType="customer" :getId="customerId" :isEdit="isEdit">
            <v-btn v-if="isEdit" color="primary" depressed>
              <v-icon left>add</v-icon>
              新增合同
            </v-btn>
          </contract-edit>
        </v-toolbar>
        <contract-list getType="customer" contractType="1" :getId="customerId" :isEdit="isEdit" :reload="reload"></contract-list>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDlg" max-width="600px" persistent>
      <v-form v-model="editValid">
        <v-card>
          <v-card-title>{{ this.editDlgTitle }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-if="editDlgTitle === '客户名称'"
              v-model="customerItem.name"
              label="客户名称"
              outlined
              :rules="[rules.required]"
              dense
              prepend-icon="person"
            ></v-text-field>
            <v-select
              v-if="editDlgTitle === '所属地区'"
              v-model="customerItem.area"
              :items="areaList"
              label="所属区域"
              item-text="name"
              item-value="_id"
              outlined
              dense
              prepend-icon="map"
            ></v-select>
            <v-select
              v-if="editDlgTitle === '用电类别'"
              v-model="customerItem.electric_type"
              :items="electricType"
              label="用电类别"
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              outlined
              dense
              prepend-icon="home_work"
            ></v-select>
            <v-autocomplete
              v-if="editDlgTitle === '代理商'"
              v-model="customerItem.agency"
              :loading="agencyLoading"
              :items="agencyList"
              :search-input.sync="searchAgency"
              item-text="name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              clearable
              label="代理商名称"
              prepend-icon="group"
            ></v-autocomplete>
            <v-autocomplete
              v-if="editDlgTitle === '内部负责人'"
              v-model="customerItem.confirm_settle"
              :items="accountList"
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              label="公司内部负责人"
              hint="请查找并选择公司内部负责人"
              prepend-icon="emoji_people"
            ></v-autocomplete>
            <v-text-field
              v-if="editDlgTitle === '客户联系人'"
              v-model="customerItem.contact_name"
              label="客户联系人"
              outlined
              dense
              prepend-icon="connect_without_contact"
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '联系电话'"
              v-model="customerItem.contact_phone"
              label="联系电话"
              outlined
              dense
              prepend-icon="phone"
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '公司地址'"
              v-model="customerItem.address"
              label="公司地址"
              outlined
              dense
              prepend-icon="place"
            ></v-text-field>
            <v-file-input
              v-if="editDlgTitle === '客户营业执照'"
              v-model="editedAttach.files"
              label="单击此处选择文件上传"
              outlined
              dense
              small-chips
              multiple
              show-size
              counter
            ></v-file-input>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEdit">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="submitEdit"
              :disabled="!editValid"
            >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="imgDlg" persistent scrollable width="800px">
      <v-card>
        <v-card-title>
          客户营业执照
          <v-btn v-if="imgUrl" icon @click="doAction('download_img')" class="ml-2"><v-icon>download</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_img')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img :src="imgUrl" contain></v-img>
          <v-overlay
            v-model="overlay"
            absolute
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除客户
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除此客户吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="doAction('confirm_delete')">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlgImg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除客户营业执照
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除客户营业执照吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="doAction('confirm_delete_img')">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import {
  FETCH_CUSTOMER,
  EDIT_CUSTOMER,
  FETCH_URL,
  FETCH_UPLOAD,
  FETCH_AREA_LIST,
  FETCH_ACCOUNT_LIST, FETCH_AGENCY_LIST, REMOVE_CUSTOMER
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import store from "@/store";
export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      customerInfo: '',
      customerItem: {
        name: '',
        area: '',
        electric_type: '',
        agency: '',
        confirm_settle: '',
        contact_name: '',
        contact_phone: '',
        address: '',
        license_image: '',
      },
      defaultItem: {
        name: '',
        area: '',
        electric_type: '',
        agency: '',
        confirm_settle: '',
        contact_name: '',
        contact_phone: '',
        address: '',
        license_image: '',
      },
      editDlg: false,
      editValid: true,
      editDlgTitle: '',
      activeAction: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      editedAttach: {
        files: [],
        progressUpload: [],
      },
      defaultAttach: {
        files: [],
        progressUpload: [],
      },
      agency: [],
      area: [],
      customerId: '',
      reload: false,
      searchAccount: null,
      searchAgency: null,
      wmText: '',
      isEdit: false,
      warnDlg: false,
      warnDlgImg: false,
    }
  },
  watch: {
    searchAccount (val) {
      val && val !== this.customerItem.confirm_settle && this.searchEmployee(val)
    },
    searchAgency (val) {
      val && val !== this.customerItem.agency && this.getAgencyList(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_ACCOUNT_LIST, 'limit=-1')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.setWatermark()
  },
  computed: {
    ...mapGetters([
      "currentUser", "uploadSign", "areaList", "accountLoading", "accountList", "agencyList", "agencyCount", "agencyLoading"
    ]),
    electricType () {
      return Util.categories('electricType')
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.customerId = this.$route.params.id
      this.getCustomer(this.customerId)
    }
  },
  methods: {
    checkPermission(info) {
      // if (info.creator._id === this.currentUser._id || info.confirm_settle._id === this.currentUser._id || this.currentUser.hasAdmin.includes('distributor') || this.currentUser.permission.includes('Customer_Edit_Detail')) this.isEdit = true
      if (info.creator._id === this.currentUser._id || info.confirm_settle._id === this.currentUser._id || this.currentUser.hasAdmin.includes('distributor')) this.isEdit = true

    },
    setWatermark() {
      this.wmText = this.currentUser.personal.name + this.currentUser.phone.substr(-4)
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    getAgencyList (key) {
      store.dispatch(FETCH_AGENCY_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    doAction(action) {
      switch(action) {
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, this.customerInfo.license_image).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'delete_img': {
          this.warnDlgImg = true
          break
        }
        case 'download_img': {
          window.location.href = this.imgUrl;
          break
        }
        case 'open_delete': {
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.warnDlg = false
          this.warnDlgImg = false
          break
        }
        case 'confirm_delete': {
          this.$store.dispatch(REMOVE_CUSTOMER, this.customerInfo._id).then(() => {
            this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
            this.warnDlg = false
            this.$router.push('/distributor/customer')
          }).catch((err) => {
            this.warnDlg = false
            this.$store.commit(SET_ERROR, {msg: err.response.data.message})
          })
          break
        }
        case 'confirm_delete_img': {
          this.fetchSubmit({
            id: this.customerInfo._id,
            item: {
              license_image: ''
            }
          })
          break
        }
      }
    },
    createContract() {
      this.$router.push('/distributor/customer/contract/edit')
    },
    getCustomer(id) {
      this.$store.dispatch(FETCH_CUSTOMER, {query: id}).then((res) => {
        this.customerInfo = res
        this.checkPermission(this.customerInfo)
        this.$forceUpdate()
      })
    },
    editCustomer(action, title) {
      this.customerItem = JSON.parse(JSON.stringify(this.customerInfo))
      this.activeAction = action
      this.editDlgTitle = title
      this.editDlg = true
    },
    uploadAttach(file, index) {
      return new Promise((resolve, reject) => {
        let fileFullName = file.name
        fileFullName = fileFullName.replace(/[%#&]/g,"");

        let param = new FormData();
        param.append("name", fileFullName);
        param.append("key", "customer/" + this.uploadSign.key + "/" + fileFullName);
        param.append("policy", this.uploadSign.policy);
        param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
        param.append("success_action_status", 200);
        param.append("signature", this.uploadSign.signature);
        param.append("file", file, fileFullName);

        let config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.$set(this.editedAttach.progressUpload,index,Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            ))
          },
        };
        axios.post(this.uploadSign.host, param, config)
          .then(() => {
            resolve({
              href: param.get("key")
            })
          })
          .catch((error) => {
            reject(error.message)
          });
      });
    },
    async submitEdit() {
      let json = {}
      json.id = this.customerInfo._id
      let obj = {}
      if (this.activeAction === 'license_image') {
        if (this.editedAttach.files && this.editedAttach.files.length) {
          await this.$store.dispatch(FETCH_UPLOAD, 'private').then(() => {
            this.editedAttach.progressUpload = new Array(this.editedAttach.files.length).fill(0)
            Promise.all(this.editedAttach.files.map((file, index) => {
              this.uploadAttach(file, index).then((result) => {
                obj.license_image = result.href
                json.item = obj
                this.fetchSubmit(json)
              })
            }))
          })
        }
      }else {
        obj[this.activeAction] = this.customerItem[this.activeAction]
        json.item = obj
        this.fetchSubmit(json)
      }
    },
    fetchSubmit(json) {
      this.$store.dispatch(EDIT_CUSTOMER, json).then(() => {
        this.getCustomer(this.customerId)
        this.$store.commit(SET_ERROR, { msg: "修改成功！", color: "primary" })
        this.closeEdit()
      })
    },
    closeEdit() {
      this.customerItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.activeAction = ''
      this.editDlgTitle = ''
      this.editDlg = false
      this.warnDlgImg = false
    },
  },
  directives: {}
}
</script>

<style scoped>
.panelTitle {
  padding-left: 14px;
  border-left: 2px solid #1976D2;
}
</style>
