var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/distributor/report');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("代理商结算数据-明细")]), _c('v-spacer'), _vm.reportSettle && _vm.reportSettle.length ? _c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "outlined": "",
      "color": "success",
      "small": ""
    }
  }, [_vm._v("已付")]) : _vm._e(), _vm.reportSettle && _vm.reportSettle.length ? _c('v-chip', {
    staticClass: "mr-4",
    attrs: {
      "label": "",
      "outlined": "",
      "color": "warning",
      "small": ""
    }
  }, [_vm._v("未付")]) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "icon": "",
            "disabled": !_vm.reportSettle || !_vm.reportSettle.length
          },
          on: {
            "click": _vm.downloadReport
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("download")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("下载数据")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.filter_item.apply(null, arguments);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("filter_list")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("过滤数据")])])], 1), _c('v-divider'), _c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.settleHeader,
      "items": _vm.reportSettle,
      "loading": _vm.reportSettleLoading,
      "server-items-length": _vm.reportSettleCount,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": "",
      "height": "600"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.customerName",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.customerName ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.customerName))]) : _vm._e()];
      }
    }, {
      key: "item.areaName",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.areaName ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.areaName))]) : _vm._e()];
      }
    }, {
      key: "item.January",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[0].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[0].other)))])])];
      }
    }, {
      key: "item.February",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[1].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[1].other)))])])];
      }
    }, {
      key: "item.March",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[2].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[2].other)))])])];
      }
    }, {
      key: "item.April",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[3].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[3].other)))])])];
      }
    }, {
      key: "item.May",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[4].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[4].other)))])])];
      }
    }, {
      key: "item.June",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.list[5].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.list[5].other)))])])];
      }
    }, {
      key: "item.July",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[6].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[6].other)))])])];
      }
    }, {
      key: "item.August",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[7].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[7].other)))])])];
      }
    }, {
      key: "item.September",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[8].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[8].other)))])])];
      }
    }, {
      key: "item.October",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[9].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[9].other)))])])];
      }
    }, {
      key: "item.November",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[10].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[10].other)))])])];
      }
    }, {
      key: "item.December",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[11].done)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.list[11].other)))])])];
      }
    }, {
      key: "item.total",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_c('div', {
          staticClass: "my-2 success--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.doneTotal)))]), _c('div', {
          staticClass: "my-2 warning--text"
        }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(item.otherTotal)))])])];
      }
    }])
  })], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.filter_item
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": _vm.clear_filter
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.filter_item
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "业务类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.group,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "group", $$v);
      },
      expression: "query.group"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.agencyLoading,
      "items": _vm.agencyList,
      "search-input": _vm.searchAgency,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "label": "代理商名称"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.agency,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "agency", $$v);
      },
      expression: "query.agency"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    on: {
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.area,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "area", $$v);
      },
      expression: "query.area"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.customerLoading,
      "items": _vm.customerList,
      "search-input": _vm.searchCustomer,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "dense": "",
      "label": "客户名称",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchCustomer = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchCustomer = $event;
      },
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.customer,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customer", $$v);
      },
      expression: "query.customer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.yearList,
      "label": "时间",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.year,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "year", $$v);
      },
      expression: "query.year"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }