<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.push('/distributor/settle')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          创建工单
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="saveCase" :disabled="!valid || !this.settleSelected.list || !this.settleSelected.list.length">
          创建
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.settleTitle"
                outlined
                label="结算单表头*"
                :rules="[rules.required]"
                hint="默认系统生成，可编辑"
                append-outer-icon="generating_tokens"
                @click:append-outer="resetTitle"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.producedBy"
                :items="accountList"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                label="制表人*"
                :rules="[rules.selected]"
                hint="请查找并选择用户"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="editedItem.approvedBy"
                :items="accountList"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                label="核定人*"
                :rules="[rules.selected]"
                hint="请查找并选择用户"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="editedItem.reviewedBy"
                :items="accountList"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                label="财务复核人*"
                :rules="[rules.selected]"
                hint="请查找并选择用户"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="editedItem.closedBy"
                :items="accountList"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                label="财务付款人*"
                :rules="[rules.selected]"
                hint="请查找并选择用户"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-tabs>
          <v-tab>数据</v-tab>
          <v-tab>附件</v-tab>
          <v-tab>备注</v-tab>
          <v-tab-item>
            <v-data-table
              :headers="settleSelected.headers"
              :items="settleSelected.list"
              class="elevation-0 mt-4"
              transition
              fixed-header
              hide-default-footer
            >
              <template v-slot:body.append>
                <tr
                  v-if="settleSelected.type === 1"
                  class="grey lighten-3 primary--text"
                  style="width: 100%"
                >
                  <td>合计</td>
                  <td colspan="3"></td>
                  <td>{{ xt_service_fee_total | formatMoney }}</td>
                  <td>{{ xt_service_fee_no_tax_total | formatMoney }}</td>
                  <td></td>
                  <td>{{ agency_service_fee_no_tax_total | formatMoney }}</td>
                  <td colspan="2"></td>
                  <td>{{ tax_total | formatMoney }}</td>
                  <td></td>
                  <td>{{ agency_fee_no_tax_total | formatMoney }}</td>
                  <td>{{ agency_fee_total | formatMoney }}</td>
                  <td colspan="4"></td>
                </tr>
                <tr
                  v-if="settleSelected.type === 2"
                  class="grey lighten-3 primary--text"
                  style="width: 100%"
                >
                  <td>合计</td>
                  <td colspan="3"></td>
                  <td>{{ project_income_total | formatMoney }}</td>
                  <td colspan="10"></td>
                  <td>{{ agency_service_fee_no_tax_total | formatMoney }}</td>
                  <td></td>
                  <td>{{ agency_fee_total | formatMoney }}</td>
                  <td colspan="3"></td>
                </tr>
                <tr
                  v-if="settleSelected.type === 3"
                  class="grey lighten-3 primary--text"
                  style="width: 100%"
                >
                  <td>合计</td>
                  <td colspan="9"></td>
                  <td>{{ agency_service_fee_total | formatMoney }}</td>
                  <td>{{ agency_service_fee_no_tax_total | formatMoney }}</td>
                  <td>{{ tax_total | formatMoney }}</td>
                  <td>{{ agency_fee_total | formatMoney }}</td>
                  <td>{{ agency_fee_no_tax_total | formatMoney }}</td>
                  <td colspan="3"></td>
                </tr>
                <tr
                  v-if="settleSelected.type === 4"
                  class="grey lighten-3 primary--text"
                  style="width: 100%"
                >
                  <td>合计</td>
                  <td colspan="6"></td>
                  <td>{{ agency_service_fee_total | formatMoney }}</td>
                  <td>{{ agency_service_fee_no_tax_total | formatMoney }}</td>
                  <td>{{ tax_total | formatMoney }}</td>
                  <td>{{ agency_fee_no_tax_total | formatMoney }}</td>
                  <td>{{ agency_fee_total | formatMoney }}</td>
                  <td colspan="3"></td>
                </tr>
              </template>
              <template v-slot:item.date="{ item }">
                <span>{{ item.date | formatMonth}}</span>
              </template>
              <template v-slot:item.area="{ item }">
                <span v-if="item.area">{{ item.area.name }}</span>
              </template>
              <template v-slot:item.agency="{ item }">
                <td nowrap="true" v-if="item.agency">{{ item.agency.name }}</td>
              </template>
              <template v-slot:item.customer="{ item }">
                <td nowrap="true" v-if="item.customer">{{ item.customer.name }}</td>
              </template>
              <template v-slot:item.report_elec_total="{ item }">
                <td nowrap="true" v-if="item.report_elec_total || item.report_elec_total === 0">{{ (item.report_elec_total).toFixed(3) }}</td>
              </template>
              <template v-slot:item.read_elec_total="{ item }">
                <td nowrap="true" v-if="item.read_elec_total || item.read_elec_total === 0">{{ (item.read_elec_total).toFixed(3) }}</td>
              </template>
              <template v-slot:item.settle_elec_total="{ item }">
                <td nowrap="true" v-if="item.settle_elec_total || item.settle_elec_total === 0">{{ (item.settle_elec_total).toFixed(3) }}</td>
              </template>
              <template v-slot:item.diff_elec_total="{ item }">
                <td nowrap="true" v-if="item.diff_elec_total || item.diff_elec_total === 0">{{ (item.diff_elec_total).toFixed(3) }}</td>
              </template>
              <template v-slot:item.agency_service_fee="{ item }">
                <td nowrap="true" v-if="item.agency_service_fee || item.agency_service_fee === 0">{{ item.agency_service_fee | formatMoney }}</td>
              </template>
              <template v-slot:item.agency_service_fee_no_tax="{ item }">
                <td nowrap="true" v-if="item.agency_service_fee_no_tax || item.agency_service_fee_no_tax === 0">{{ item.agency_service_fee_no_tax | formatMoney }}</td>
              </template>
              <template v-slot:item.agency_fee="{ item }">
                <td nowrap="true" v-if="item.agency_fee || item.agency_fee === 0">{{ item.agency_fee | formatMoney }}</td>
              </template>
              <template v-slot:item.agency_fee_no_tax="{ item }">
                <td nowrap="true" v-if="item.agency_fee_no_tax || item.agency_fee_no_tax === 0">{{ item.agency_fee_no_tax | formatMoney }}</td>
              </template>
              <template v-slot:item.diff_ratio_total="{ item }">
                <span v-if="item.diff_ratio_total || item.diff_ratio_total === 0">{{ (item.diff_ratio_total).toFixed(2) }}</span>
              </template>
              <template v-slot:item.tax="{ item }">
                <span v-if="item.tax || item.tax === 0">{{ (item.tax).toFixed(2) }}</span>
              </template>
              <!--调峰-->
              <template v-slot:item.project_income="{ item }">
                <td nowrap="true"  v-if="item.project_income || item.project_income === 0">{{ item.project_income | formatMoney }}</td>
              </template>
              <template v-slot:item.project_income_no_tax="{ item }">
                <td nowrap="true" v-if="item.project_income_no_tax || item.project_income_no_tax === 0">{{ item.project_income_no_tax | formatMoney }}</td>
              </template>
              <template v-slot:item.customer_ratio="{ item }">
                <span v-if="item.customer_ratio || item.customer_ratio === 0">{{ item.customer_ratio }}%</span>
              </template>
              <template v-slot:item.customer_tax_rate="{ item }">
                <span v-if="item.customer_tax_rate || item.customer_tax_rate === 0">{{ item.customer_tax_rate.toFixed(2) }}%</span>
              </template>
              <template v-slot:item.agency_tax_rate="{ item }">
                <span v-if="item.agency_tax_rate || item.agency_tax_rate === 0">{{ item.agency_tax_rate.toFixed(2) }}%</span>
              </template>
              <template v-slot:item.tax_rate="{ item }">
                <span v-if="item.tax_rate || item.tax_rate === 0">{{ item.tax_rate.toFixed(2) }}%</span>
              </template>
              <template v-slot:item.customer_invoice_type="{ item }">
                <span v-if="item.customer_invoice_type === 1">增值税专用发票</span>
                <span v-if="item.customer_invoice_type === 0">增值税普通发票</span>
              </template>
              <template v-slot:item.customer_service_fee_no_tax="{ item }">
                <td nowrap="true" v-if="item.customer_service_fee_no_tax || item.customer_service_fee_no_tax === 0">{{ item.customer_service_fee_no_tax | formatMoney }}</td>
              </template>
              <template v-slot:item.customer_tax="{ item }">
                <td nowrap="true" v-if="item.customer_tax || item.customer_tax === 0">{{ item.customer_tax | formatMoney }}</td>
              </template>
              <template v-slot:item.customer_fee="{ item }">
                <td nowrap="true" v-if="item.customer_fee || item.customer_fee === 0">{{ item.customer_fee | formatMoney }}</td>
              </template>
              <template v-slot:item.agency_ratio="{ item }">
                <span v-if="item.agency_ratio || item.agency_ratio === 0">{{ item.agency_ratio }}%</span>
              </template>
              <template v-slot:item.agency_tax="{ item }">
                <span v-if="item.agency_tax || item.agency_tax === 0">{{ item.agency_tax.toFixed(2) }}%</span>
              </template>
              <template v-slot:item.agency_invoice_type="{ item }">
                <span v-if="item.agency_invoice_type === 1">增值税专用发票</span>
                <span v-if="item.agency_invoice_type === 0">增值税普通发票</span>
              </template>
              <!--          <template v-slot:item.agency_service_fee_no_tax="{ item }">-->
              <!--            <span v-if="item.agency_service_fee_no_tax">{{ (item.agency_service_fee_no_tax).toFixed(2) }}</span>-->
              <!--          </template>-->
              <template v-slot:item.tax="{ item }">
                <td nowrap="true" v-if="item.tax || item.tax === 0">{{ item.tax | formatMoney }}</td>
              </template>
              <!--          <template v-slot:item.agency_fee="{ item }">-->
              <!--            <span v-if="item.agency_fee">{{ (item.agency_fee).toFixed(2) }}</span>-->
              <!--          </template>-->
              <!--能源-->
              <!--          <template v-slot:item.agency_fee_no_tax_minus="{ item }">-->
              <!--            <span v-if="item.agency_fee_no_tax_minus">{{ (item.agency_fee_no_tax_minus).toFixed(2) }}</span>-->
              <!--          </template>-->
              <template v-slot:item.xt_service_fee="{ item }">
                <td nowrap="true" v-if="item.xt_service_fee || item.xt_service_fee === 0">{{ item.xt_service_fee | formatMoney }}</td>
              </template>
              <template v-slot:item.xt_service_fee_no_tax="{ item }">
                <td nowrap="true" v-if="item.xt_service_fee_no_tax || item.xt_service_fee_no_tax === 0">{{ item.xt_service_fee_no_tax | formatMoney }}</td>
              </template>
              <template v-slot:item.agency_fee_total="{ item }">
                <span v-if="item.agency_fee_total || item.agency_fee_total === 0">{{ (item.agency_fee_total).toFixed(2) }}</span>
              </template>
              <template v-slot:item.gross_income="{ item }">
                <td nowrap="true" v-if="item.gross_income || item.gross_income === 0">{{ item.gross_income | formatMoney }}</td>
              </template>
              <template v-slot:item.settle_status="{ item }">
                <span v-if="item.settle_status === 1">待结算</span>
                <span v-if="item.settle_status === 2" class="success--text">结算中</span>
                <span v-if="item.settle_status === 3">已结算</span>
              </template>
              <template v-slot:item.createTime="{ item }">
                <span>{{ item.createTime | formatTime }}</span>
              </template>
              <template v-slot:item.actions>
                <span>-</span>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
              <v-list subheader dense>
                <v-list-item v-for="(file, i) in editedItem.files" :key="file.href">
                  <v-list-item-avatar>
                    <v-icon>text_snippet</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="file.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="d-inline">
                    <v-btn icon>
                      <v-icon @click="removeAttach(i)">delete</v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon @click="downloadAttach(file.href)">get_app</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title align="end">
                      <v-btn text @click="createAttach()">
                        <v-icon left>attach_file</v-icon>上传附件
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
              <v-list dense>
                <template v-for="(item, index) in editedItem.remarks">
                  <v-divider v-if="item.divider" :key="index + '_remarks_hr'" :inset="item.inset"></v-divider>
                  <v-list-item v-else :key="index + '_remarks_title'" three-line>
                    <account-dialogs :account="item.account">
                      <v-list-item-avatar>
                        <v-img :src="item.avatar ? ossURL + '/' + item.avatar : '/static/grey_silhouette.png'"></v-img>
                      </v-list-item-avatar>
                    </account-dialogs>
                    <v-list-item-content>
                      <v-list-item-title>{{item.title}} {{ item.createTime | fromDate }} {{item.private ? ' - 仅个人可见' : ''}}</v-list-item-title>
                      {{item.context}}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon @click="removeRemark(item)">delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title align="end">
                      <v-btn text @click="createRemarks()">
                        <v-icon left>rate_review</v-icon>增加备注
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dlgAttach" persistent max-width="550px">
      <v-card v-if="editedAttach.progressUpload && editedAttach.progressUpload.length" color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-for="(progress, i) in editedAttach.progressUpload" :key="'progress_'+ i">
              {{editedAttach.files[i].name}}
              <v-progress-linear
                color="white"
                :value="progress"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          上传附件
          <v-spacer></v-spacer>
          <v-btn icon @click="closeAttach()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
<!--              <v-switch v-model="editedAttach.public" :label="`客户可见: ${editedAttach.public ? '是' : '否'}`"></v-switch>-->
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="editedAttach.files"
                label="单击此处选择文件上传"
                outlined
                dense
                small-chips
                multiple
                show-size
                counter
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAttach()">取消</v-btn>
          <v-btn text color="secondary"
                 :disabled="!editedAttach.files.length"
                 @click="addAttach()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgRemarks" persistent max-width="550px">
      <v-card>
        <v-card-title>
          增加备注
          <v-spacer></v-spacer>
          <v-btn icon @click="closeRemarks"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
<!--              <v-switch v-model="editedRemark.private" :label="`仅个人可见: ${editedRemark.private ? '是' : '否'}`"></v-switch>-->
              <v-textarea
                outlined
                auto-grow
                rows="3"
                v-model="editedRemark.context"
                label="备注内容"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemarks()">取消</v-btn>
          <v-btn text color="secondary"
                 :disabled="!editedRemark.context"
                 @click="addRemarks(editedRemark)">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import {
  FETCH_UPLOAD,
  FETCH_URL,
  FETCH_ACCOUNT_LIST,
  CREATE_CASE,
  INIT_CASE,
} from "@/store/actions.type";

export default {
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      defaultHeader: [
        { text: '结算日期', value: 'code', width: '120', align: 'center', sortable: false},
        { text: '代理商名称', value: 'name', width: '120', align: 'center', sortable: false},
        { text: '客户名称', value: 'name', width: '120', align: 'center', sortable: false},
        { text: '所属区域', value: 'area', width: '120', align: 'center', sortable: false},
        { text: '合同电量(兆瓦时)', value: 'customer_count', width: '160', align: 'center', sortable: false},
        { text: '抄表电量', value: 'person_in_charge', width: '140', align: 'center', sortable: false},
        { text: '结算电量', value: 'contact_name', width: '120', align: 'center', sortable: false},
        { text: '偏差电量', value: 'contact_phone', width: '120', align: 'center', sortable: false},
        { text: '偏差率', value: 'invoice_type', width: '134', align: 'center', sortable: false},
        { text: '代理商费用(含税)(元)', value: 'invoice_rate', width: '120', align: 'center', sortable: false},
        { text: '代理商费用(不含税)(元)', value: 'creator', width: '140', align: 'center', sortable: false},
        { text: '税金及附加(元)', value: 'createTime', width: '120', align: 'center', sortable: false},
        { text: '总开票金额(元)', value: 'edit_time', width: '120', align: 'center', sortable: false},
        { text: '操作', value: 'actions', width: '120', align: 'center', sortable: false},
      ],
      settleList: [],
      editedItem: {
        producedBy: '',
        approvedBy: '',
        reviewedBy: '',
        closedBy: '',
        settleTitle: '',
        agency: '',
        settleType: null,
        settleList: [],
        files: [],
        remarks: [],
      },
      dlgRemarks: false,
      editedRemark: {
        title: "",
        context: "",
        private: false,
      },
      defaultRemarks: {
        title: "",
        context: "",
        private: false,
      },
      dlgAttach: false,
      editedAttach: {
        files: [],
        progressUpload: [],
        public: false
      },
      defaultAttach: {
        files: [],
        progressUpload: [],
        public: false
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fileName: '请上传文件',
      preUrl: '',
      agency_service_fee_total: 0,
      agency_service_fee_no_tax_total: 0,
      agency_fee_total: 0,
      agency_fee_no_tax_total: 0,
      tax_total: 0,
      xt_service_fee_total: 0,
      xt_service_fee_no_tax_total: 0,
      project_income_total: 0,
      dateArr: [],
      minDate: '',
      maxDate: '',
      defaultSettleTitle: '',
    }
  },
  watch: {
    'editedAttach.progressUpload': {
      handler: function (val) {
        if (val.reduce((a, b) => a + b, 0) / val.length === 100) this.closeAttach()

      },
      deep: true
    },
  },
  created() {
    store.dispatch(FETCH_ACCOUNT_LIST)
    this.setTotalData()
  },
  computed: {
    ...mapGetters(["currentUser", "uploadSign", "accountLoading", "accountList", "settleSelected", "process_info"]),
  },
  mounted() {},
  methods: {
    saveCase() {
      let json = JSON.parse(JSON.stringify(this.editedItem))
      store.dispatch(CREATE_CASE, json)
        .then(() => {
          store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.$router.push({path: '/distributor/workbench/case'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.data});
        });
    },
    resetTitle() {
      this.editedItem.settleTitle = this.defaultSettleTitle
    },
    setTotalData() {
      if (!this.settleSelected.list) {
        store.commit(SET_ERROR, {msg: '请重新选择结算数据后创建工单。'});
        this.$router.go(-1)
        return
      }
      this.editedItem.producedBy = this.currentUser._id
      this.editedItem.agency = this.settleSelected.agency
      this.editedItem.settleType = this.settleSelected.type
      this.settleSelected.list.map(item => {
        this.dateArr.push(Date.parse(item.date))
        this.editedItem.settleList.push(item._id)
      })
      this.dateArr = [...new Set(this.dateArr)]
      let titleDate = ''
      if (this.dateArr.length > 1) {
        this.minDate = new Date(Math.min(...this.dateArr))
        this.maxDate = new Date(Math.max(...this.dateArr))
        titleDate = this.minDate.getFullYear() + '年' + (this.minDate.getMonth()+1) + '月' + '-' + this.maxDate.getFullYear() + '年' + (this.maxDate.getMonth()+1) + '月'
      } else {
        titleDate = new Date(this.dateArr[0]).getFullYear() + '年' + (new Date(this.dateArr[0]).getMonth()+1) + '月'
      }
      if (this.editedItem.settleType === 1) {
        this.defaultSettleTitle = this.settleSelected.list[0].customer.name + '项目' + titleDate + '代理商结算单'
      } else if (this.editedItem.settleType === 2) {
        this.defaultSettleTitle = this.settleSelected.list[0].agency.name + '电力调峰辅助服务' + titleDate + '结算单'
      } else if (this.editedItem.settleType === 3) {
        this.defaultSettleTitle = titleDate + this.settleSelected.list[0].area.name + '地区代理商结算单'
      } else if (this.editedItem.settleType === 4) {
        this.defaultSettleTitle = this.settleSelected.list[0].customer.name + '项目' + titleDate + '代理商结算单'
      }
      this.editedItem.settleTitle = this.defaultSettleTitle
      // this.editedItem.settleList = this.settleSelected.list.map(item=>item._id)
      if (this.settleSelected.list && this.settleSelected.list.length) {
        this.settleSelected.list.map(item => {
          if (this.settleSelected.type === 3 || this.settleSelected.type === 4) {
            this.agency_service_fee_total += item.agency_service_fee
            this.agency_service_fee_no_tax_total += item.agency_service_fee_no_tax
            this.agency_fee_total += item.agency_fee
            this.agency_fee_no_tax_total += item.agency_fee_no_tax
            this.tax_total += item.tax
          } else if (this.settleSelected.type === 1) {
            this.xt_service_fee_total += item.xt_service_fee
            this.xt_service_fee_no_tax_total += item.xt_service_fee_no_tax
            this.agency_service_fee_no_tax_total += item.agency_service_fee_no_tax
            this.tax_total += item.tax
            this.agency_fee_total += item.agency_fee
            this.agency_fee_no_tax_total += item.agency_fee_no_tax
          } else if (this.settleSelected.type === 2) {
            this.project_income_total += item.project_income
            this.agency_service_fee_no_tax_total += item.agency_service_fee_no_tax
            this.agency_fee_total += item.agency_fee
          }
        })
      }
      store.dispatch(INIT_CASE, {query: 'agency='+ this.settleSelected.agency +'&area='+ this.settleSelected.area +'&business='+ this.editedItem.settleType})
        .then((data) =>{
          if (data.approvedBy) this.editedItem.approvedBy = data.approvedBy
          if (data.reviewedBy) this.editedItem.reviewedBy = data.reviewedBy
          if (data.closedBy) this.editedItem.closedBy = data.closedBy
        })

    },
    createAttach() {
      this.dlgAttach = true
    },
    async addAttach() {
      if (this.editedAttach.files && this.editedAttach.files.length) {
        let filePublic = this.editedAttach.public
        store.dispatch(FETCH_UPLOAD, "private").then(() => {
          this.editedAttach.progressUpload = new Array(this.editedAttach.files.length).fill(0);
          Promise.all(this.editedAttach.files.map((file, index) => {
            this.uploadAttach(file, index).then((result) => {
              this.editedItem.files.push(Object.assign(result, {public: filePublic}));
            })
          }))
        })
      }
    },
    uploadAttach(file, index) {
      return new Promise((resolve, reject) => {
        let fileFullName = file.name,
          fileSuffix = fileFullName.substring(fileFullName.lastIndexOf(".") + 1);
        fileFullName = fileFullName.replace(/[%#&]/g,"");

        let param = new FormData();
        param.append("name", fileFullName);
        param.append("key", "case/settle/files/" + this.uploadSign.key + "/" + fileFullName);
        param.append("policy", this.uploadSign.policy);
        param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
        param.append("success_action_status", 200);
        param.append("signature", this.uploadSign.signature);
        param.append("file", file, fileFullName);

        let config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.$set(this.editedAttach.progressUpload,index,Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            ))
          },
        };
        axios.post(this.uploadSign.host, param, config)
          .then(() => {
            resolve({
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get("key")
            })
          })
          .catch((error) => {
            reject(error.message)
          });
      });
    },
    removeAttach(index) {
      this.editedItem.files.splice(index, 1);
    },
    downloadAttach(href) {
      store.dispatch(FETCH_URL, href)
        .then((url) => {
          window.location.href = url
        }).catch((err) => {
          store.commit(SET_ERROR, { msg: err })
        })
    },
    closeAttach() {
      this.dlgAttach = false
      this.$nextTick(() =>{
        this.editedAttach = Object.assign({}, this.defaultAttach)
      })
    },
    createRemarks() {
      this.dlgRemarks = true;
    },
    addRemarks(objRemarks) {
      objRemarks["account"] = this.currentUser._id;
      objRemarks["avatar"] = this.currentUser.personal.profile || "";
      objRemarks['createTime'] = new Date()
      this.editedItem.remarks.push(Object.assign({}, objRemarks));
      this.closeRemarks()
    },
    removeRemark(item) {
      this.editedItem.remarks.splice(this.editedItem.remarks.indexOf(item), 2);
    },
    closeRemarks() {
      this.dlgRemarks = false;
      this.$nextTick(() => {
        this.editedRemark = Object.assign({}, this.defaultRemarks);
      });
    },
  },
  directives: {}
}
</script>

<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.hover-btn {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}
</style>
