var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-card-text', [_c('widgets-watermark', {
    attrs: {
      "watermarkText": _vm.wmText
    }
  }), _c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.settleList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.settleCount,
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "loading": _vm.settleLoading,
      "loading-text": "数据读取中... 请稍后",
      "item-key": "_id",
      "transition": "",
      "fixed-header": "",
      "show-select": !!_vm.query.agency,
      "selectable-key": "isSelectable",
      "height": "600"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.business_type ? _vm.userBusinessType.find(function (item) {
          return item.code === _vm.business_type;
        }).name + '结算数据' : '请先筛选业务类型后查看结算数据'))]), _c('v-spacer'), _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "color": "primary",
            "depressed": "",
            "disabled": !_vm.business_type || !_vm.isEdit
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('batch_create');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("upgrade")]), _vm._v(" 数据导入 ")], 1), _c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": "",
            "disabled": !_vm.business_type || !_vm.selectedItem.length || !_vm.isEdit
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_case');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("add")]), _vm._v(" 创建工单 ")], 1), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "ml-2",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("info")])];
            }
          }])
        }, [_c('span', [_vm._v("创建工单必须先选择代理商")])]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "disabled": !_vm.isEdit || !_vm.settleList.length
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('download_create_template', 'settle');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("数据导出")])]), _vm.defaultHeader.length ? _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 2,
            "tableName": _vm.settleHeader(_vm.business_type)
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.doAction('cancel_header');
            },
            "Default": function Default($event) {
              return _vm.doAction('default_header');
            },
            "Save": function Save($event) {
              return _vm.doAction('set_header');
            }
          }
        }) : _vm._e(), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('filter_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('reload_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("autorenew")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("刷新列表")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.date",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatMonth")(item.date)))])];
      }
    }, {
      key: "item.area",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.area ? _c('span', [_vm._v(_vm._s(item.area.name))]) : _vm._e()];
      }
    }, {
      key: "item.agency",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.agency ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.agency.name))]) : _vm._e()];
      }
    }, {
      key: "item.customer",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.customer ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.customer.name))]) : _vm._e()];
      }
    }, {
      key: "item.report_elec_total",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.report_elec_total || item.report_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.report_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.read_elec_total",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.read_elec_total || item.read_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.read_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.settle_elec_total",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.settle_elec_total || item.settle_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.settle_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.diff_elec_total",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.diff_elec_total || item.diff_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.diff_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_service_fee",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.agency_service_fee || item.agency_service_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_service_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_service_fee_no_tax",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.agency_service_fee_no_tax || item.agency_service_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_service_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_fee",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.agency_fee || item.agency_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_fee_no_tax",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.agency_fee_no_tax || item.agency_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.diff_ratio_total",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.diff_ratio_total || item.diff_ratio_total === 0 ? _c('span', [_vm._v(_vm._s(item.diff_ratio_total.toFixed(2)))]) : _vm._e()];
      }
    }, {
      key: "item.tax",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [item.tax || item.tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.tax)))]) : _vm._e()];
      }
    }, {
      key: "item.project_income",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [item.project_income || item.project_income === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.project_income)))]) : _vm._e()];
      }
    }, {
      key: "item.project_income_no_tax",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.project_income_no_tax || item.project_income_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.project_income_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.customer_ratio",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [item.customer_ratio || item.customer_ratio === 0 ? _c('span', [_vm._v(_vm._s(item.customer_ratio) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.customer_tax_rate",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [item.customer_tax_rate || item.customer_tax_rate === 0 ? _c('span', [_vm._v(_vm._s(item.customer_tax_rate.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.agency_tax_rate",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [item.agency_tax_rate || item.agency_tax_rate === 0 ? _c('span', [_vm._v(_vm._s(item.agency_tax_rate.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.customer_invoice_type",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [item.customer_invoice_type === 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e(), item.customer_invoice_type === 0 ? _c('span', [_vm._v("增值税普通发票")]) : _vm._e()];
      }
    }, {
      key: "item.customer_service_fee_no_tax",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [item.customer_service_fee_no_tax || item.customer_service_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.customer_service_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.customer_tax",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [item.customer_tax || item.customer_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.customer_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.customer_fee",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [item.customer_fee || item.customer_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.customer_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_ratio",
      fn: function fn(_ref28) {
        var _item$agency_ratio;
        var item = _ref28.item;
        return [item.agency_ratio && item.agency_ratio.$numberDecimal ? _c('span', [_vm._v(_vm._s((_item$agency_ratio = item.agency_ratio) === null || _item$agency_ratio === void 0 ? void 0 : _item$agency_ratio.$numberDecimal) + "%")]) : item.agency_ratio || item.agency_ratio === 0 ? _c('span', [_vm._v(_vm._s(item.agency_ratio) + "%")]) : _c('span')];
      }
    }, {
      key: "item.agency_tax",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [item.agency_tax || item.agency_tax === 0 ? _c('span', [_vm._v(_vm._s(item.agency_tax.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.agency_invoice_type",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [item.agency_invoice_type === 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e(), item.agency_invoice_type === 0 ? _c('span', [_vm._v("增值税普通发票")]) : _vm._e()];
      }
    }, {
      key: "item.tax_rate",
      fn: function fn(_ref31) {
        var item = _ref31.item;
        return [item.tax_rate || item.tax_rate === 0 ? _c('span', [_vm._v(_vm._s(item.tax_rate.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.xt_service_fee",
      fn: function fn(_ref32) {
        var item = _ref32.item;
        return [item.xt_service_fee || item.xt_service_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.xt_service_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.xt_service_fee_no_tax",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return [item.xt_service_fee_no_tax || item.xt_service_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.xt_service_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_fee_total",
      fn: function fn(_ref34) {
        var item = _ref34.item;
        return [item.agency_fee_total || item.agency_fee_total === 0 ? _c('span', [_vm._v(_vm._s(item.agency_fee_total.toFixed(2)))]) : _vm._e()];
      }
    }, {
      key: "item.gross_income",
      fn: function fn(_ref35) {
        var item = _ref35.item;
        return [item.gross_income || item.gross_income === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.gross_income)))]) : _vm._e()];
      }
    }, {
      key: "item.settle_status",
      fn: function fn(_ref36) {
        var item = _ref36.item;
        return [item.settle_status === 1 ? _c('span', [_vm._v("待结算")]) : _vm._e(), item.settle_status === 2 ? _c('span', {
          staticClass: "success--text"
        }, [_vm._v("结算中")]) : _vm._e(), item.settle_status === 3 ? _c('span', [_vm._v("已结算")]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref37) {
        var item = _ref37.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref38) {
        var item = _ref38.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref39) {
              var on = _ref39.on,
                attrs = _ref39.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [item.settle_status === 1 && _vm.business_type === 3 && _vm.isEdit ? _c('settle-edit', {
          attrs: {
            "editData": item
          },
          on: {
            "reload": _vm.getList
          }
        }, [_c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.doAction('default');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")])], 1), _c('v-list-item-title', [_vm._v(" 编辑 ")])], 1)], 1) : _vm._e(), _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.doAction('change_record', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit_note")])], 1), _c('v-list-item-title', [_vm._v(" 变更记录 ")])], 1), item.settle_status !== 2 && _vm.isEdit ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.doAction('delete_settle', item._id);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v("delete")])], 1), _c('v-list-item-title', [_vm._v(" 删除 ")])], 1) : _vm._e()], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref40) {
        var on = _ref40.on,
          attrs = _ref40.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "结算日期开始月份",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              return _vm.resetQuery('start_date');
            }
          },
          model: {
            value: _vm.query.start_date,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "start_date", $$v);
            },
            expression: "query.start_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "type": "month",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      },
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.start_date,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "start_date", $$v);
      },
      expression: "query.start_date"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref41) {
        var on = _ref41.on,
          attrs = _ref41.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "结算日期结束月份",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              return _vm.resetQuery('end_date');
            }
          },
          model: {
            value: _vm.query.end_date,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "end_date", $$v);
            },
            expression: "query.end_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "type": "month",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      },
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.end_date,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "end_date", $$v);
      },
      expression: "query.end_date"
    }
  })], 1), _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.area,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "area", $$v);
      },
      expression: "query.area"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "loading": _vm.agencyLoading,
      "items": _vm.agencyList,
      "search-input": _vm.searchAgency,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "代理商名称"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "change": _vm.searchList,
      "click:clear": _vm.resetAgencyList
    },
    model: {
      value: _vm.query.agency,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "agency", $$v);
      },
      expression: "query.agency"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "loading": _vm.customerLoading,
      "items": _vm.customerList,
      "search-input": _vm.searchCustomer,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "clearable": "",
      "dense": "",
      "label": "客户名称"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchCustomer = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchCustomer = $event;
      },
      "change": function change($event) {
        return _vm.searchList();
      }
    },
    model: {
      value: _vm.query.customer,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "customer", $$v);
      },
      expression: "query.customer"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "业务类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.searchList('changeType');
      }
    },
    model: {
      value: _vm.business_type,
      callback: function callback($$v) {
        _vm.business_type = $$v;
      },
      expression: "business_type"
    }
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载新增模版，填写信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_create_template', 'template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importCreateFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.batch.import_end
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_create_file');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.batch.import_failed.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.batch.import_failed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.batch.import_failed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.batch.import_result) + " 条记录。 ")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.imgDlg,
      callback: function callback($$v) {
        _vm.imgDlg = $$v;
      },
      expression: "imgDlg"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "600"
    }
  }, [_c('v-card-title', [_vm._v(" 客户营业执照 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.imgUrl
    }
  }), _c('v-overlay', {
    staticClass: "align-center justify-center",
    attrs: {
      "absolute": ""
    },
    model: {
      value: _vm.overlay,
      callback: function callback($$v) {
        _vm.overlay = $$v;
      },
      expression: "overlay"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除结算数据 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除此条结算数据吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteSettle
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.recordDlg,
      callback: function callback($$v) {
        _vm.recordDlg = $$v;
      },
      expression: "recordDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 变更记录 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_record');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('case-action-logs', {
    attrs: {
      "caseData": _vm.settleRecord
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }