<template>
  <v-container fluid>
    <span :class="$vuetify.theme.dark ? '' : 'bg'"></span>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" style="height: 300px;">
          <v-img
          src="/static/logofont.png"
          transition="scale-transition" origin="center center"
          max-width="350"
          lass="my-3"
          contain
          ></v-img>
        </v-row>
        <v-card max-width="550" class="mx-auto" outlined>
          <v-tabs v-model="login.tabLogin" fixed-tabs>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#emailTap">密码登陆</v-tab>
<!--            <v-tab href="#phoneTap">验证码登陆</v-tab>-->
          </v-tabs>
          <v-tabs-items v-model="login.tabLogin">
            <v-tab-item value="emailTap">
              <v-card-text>
                <v-form ref="frmMail" @submit.native.prevent>
                  <v-text-field
                    autofocus
                    :disabled="!login.editEmail"
                    v-model="login.txtEmail"
                    v-mask="'###########'"
                    label="请输入手机号码"
                    outlined
                    color="secondary"
                    :error-messages="login.errMail"
                    @keydown.enter="checkMail"
                    :rules="[rules.required, rules.phone]"
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="login.mailLoading"
                          :width="2"
                          size="24"
                          indeterminate
                        ></v-progress-circular>
                        <v-icon v-else @click="checkMail">navigate_next</v-icon>
                      </v-scale-transition>
                    </template>
                    <v-fade-transition slot="append" leave-absolute>
                      <v-progress-circular v-if="login.mailLoading" size="24" indeterminate></v-progress-circular>
                    </v-fade-transition>
                  </v-text-field>
                </v-form>
                <v-form ref="frmPass" v-if="login.editPass" @submit.native.prevent>
                  <v-text-field
                    type="password"
                    v-model="login.txtPassword"
                    :error-messages="login.errPass"
                    label="账号密码"
                    outlined
                    color="secondary"
                    :autofocus="login.editPass"
                    @keydown.enter="checkLogin"
                    :rules="[rules.required]"
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="login.passLoading"
                          :width="2"
                          size="24"
                          indeterminate
                        ></v-progress-circular>
                        <v-icon v-else @click="checkLogin">input</v-icon>
                      </v-scale-transition>
                    </template>
                    <v-fade-transition slot="append" leave-absolute>
                      <v-progress-circular v-if="login.passLoading" size="24" indeterminate></v-progress-circular>
                    </v-fade-transition>
                  </v-text-field>
                </v-form>
              </v-card-text>
            </v-tab-item>

<!--            <v-tab-item value="phoneTap">-->
<!--              <v-card-text>-->
<!--                <v-form ref="frmPhone" @submit.native.prevent>-->
<!--                  <v-text-field-->
<!--                    autofocus-->
<!--                    :disabled="!login.editPhone"-->
<!--                    v-model="login.txtPhone"-->
<!--                    label="账号关联的电话号码"-->
<!--                    outlined-->
<!--                    color="secondary"-->
<!--                    :error-messages="login.errPhone"-->
<!--                    v-mask="'###########'"-->
<!--                    @keydown.enter="checkPhone"-->
<!--                    :rules="[rules.required, rules.phone]"-->
<!--                  >-->
<!--                    <template v-slot:append>-->
<!--                      <v-scale-transition leave-absolute>-->
<!--                        <v-progress-circular-->
<!--                          v-if="login.phoneLoading"-->
<!--                          :width="2"-->
<!--                          size="24"-->
<!--                          indeterminate-->
<!--                        ></v-progress-circular>-->
<!--                        <v-icon v-else @click="checkPhone">navigate_next</v-icon>-->
<!--                      </v-scale-transition>-->
<!--                    </template>-->
<!--                    <v-fade-transition slot="append" leave-absolute>-->
<!--                      <v-progress-circular v-if="login.phoneLoading" size="24" indeterminate></v-progress-circular>-->
<!--                    </v-fade-transition>-->
<!--                  </v-text-field>-->
<!--                </v-form>-->
<!--                <v-form ref="frmCode" v-if="login.editCode" @submit.native.prevent>-->
<!--                  <v-text-field-->
<!--                    v-model="login.txtSMSCode"-->
<!--                    label="4位数字的短信验证码"-->
<!--                    outlined-->
<!--                    :autofocus="login.editCode"-->
<!--                    :error-messages="login.errCode"-->
<!--                    v-mask="'####'"-->
<!--                    color="secondary"-->
<!--                    @keydown.enter="checkLogin"-->
<!--                    :rules="[rules.required, rules.smscode]"-->
<!--                  >-->
<!--                    <template v-slot:append>-->
<!--                      <v-scale-transition leave-absolute>-->
<!--                        <v-progress-circular-->
<!--                          v-if="login.codeLoading"-->
<!--                          :width="2"-->
<!--                          size="24"-->
<!--                          indeterminate-->
<!--                        ></v-progress-circular>-->
<!--                        <v-icon v-else @click="checkLogin">input</v-icon>-->
<!--                      </v-scale-transition>-->
<!--                    </template>-->
<!--                    <v-fade-transition slot="append" leave-absolute>-->
<!--                      <v-progress-circular v-if="login.codeLoading" size="24" indeterminate></v-progress-circular>-->
<!--                    </v-fade-transition>-->
<!--                  </v-text-field>-->
<!--                </v-form>-->
<!--              </v-card-text>-->
<!--            </v-tab-item>-->
          </v-tabs-items>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="clear"
            >清空</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {LOGIN_PASS, LOGIN_CODE, CHECK_USERNAME, SEND_SMS_CODE} from "@/store/actions.type";
export default {
  data: () => ({
    login: {
      tabLogin: null,
      mailLoading: false,
      passLoading: false,
      phoneLoading: false,
      codeLoading: false,
      txtEmail: '',
      txtPassword: '',
      txtPhone: '',
      txtSMSCode: '',
      editEmail: true,
      editPass: false,
      editPhone: true,
      editCode: false,
      errMail: '',
      errPhone: '',
      errPass: '',
      errCode: '',
    },
    frmMail: false,
    frmPhone: false,
    frmPass: false,
    frmCode: false,
    rules: {
      required: value => !!value || '请输入内容.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '错误的电子邮件地址'
      },
      phone: value => value.length === 11 || '电话号码必须11位手机号码',
      smscode: value => value.length === 4 || '验证码必须4位数字',
    },
    redirect: undefined,
    otherQuery: {}
  }),
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  computed: {},

  methods: {
    async checkMail () {
      if (this.$refs.frmMail.validate()) {
        this.login.mailLoading = true;
        this.$store.dispatch(CHECK_USERNAME, this.login.txtEmail)
          .then(() => {
            this.login.errMail = '账号准备就绪请输入密码后登陆';
            this.login.editEmail = false;
            this.login.editPass = true;
          })
          .catch((err) => {
            this.login.errMail = err.message;
          });
        this.login.mailLoading = false;
      }
    },
    async checkPhone () {
      if (this.$refs.frmPhone.validate()) {
        this.login.phoneLoading = true;
        this.$store.dispatch(SEND_SMS_CODE, { phone: this.login.txtPhone, type: "login" })
          .then(() => {
            this.login.errPhone = '验证码已成功通过短信形式发送';
            this.login.editPhone = false;
            this.login.editCode = true;
          })
          .catch((err) => {
            this.login.errPhone = err.message;
          });
        this.login.phoneLoading = false;
      }
    },
    async checkLogin () {
      if (this.login.tabLogin === 'emailTap') {
        if (this.$refs.frmPass.validate()){
          this.login.passLoading = true;
          this.$store.dispatch(LOGIN_PASS, {
            username: this.login.txtEmail,
            password: this.login.txtPassword
          })
            .then(() => {
              this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            })
            .catch((err) => {
              this.login.errPass = err.message;
            });
          this.login.passLoading = false;
        }
      } else {
        if (this.$refs.frmCode.validate()){
          this.login.codeLoading = true;
          this.$store.dispatch(LOGIN_CODE, {
            username: this.login.txtPhone,
            password: this.login.txtSMSCode
          })
            .then(() => {
              this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            })
            .catch((err) => {
              this.login.errCode = err.message;
            });
          this.login.codeLoading = false;
        }
      }
    },
    clear (){
      this.login.mailLoading = false;
      this.login.passLoading = false;
      this.login.phoneLoading = false;
      this.login.codeLoading = false;
      this.login.txtEmail = '';
      this.login.txtPassword = '';
      this.login.txtPhone = '';
      this.login.txtSMSCode = '';
      this.login.editEmail = true;
      this.login.editPass = false;
      this.login.editPhone = true;
      this.login.editCode = false;
      this.login.errMail = '';
      this.login.errPhone = '';
      this.frmMail = false;
      this.frmPhone = false;
      this.frmPass = false;
      this.frmCode = false;
      location.reload();
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  },
}
</script>
<style scoped>
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url( '/static/banner_background.svg') no-repeat center center;
    background-size: cover;
    transform: scale(1.1);
  }
</style>

