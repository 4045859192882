<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.push('/distributor/report')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>代理商结算数据-汇总</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip v-if="reportSettleSummary && reportSettleSummary.length" label outlined color="success" small class="mr-2">已付</v-chip>
        <v-chip v-if="reportSettleSummary && reportSettleSummary.length" label outlined color="warning" small class="mr-4">未付</v-chip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-2" :disabled="!reportSettleSummary || !reportSettleSummary.length" @click="downloadReport">
              <v-icon>download</v-icon>
            </v-btn>
          </template>
          <span>下载数据</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click.stop="filter_item">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>过滤数据</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :options.sync="optionsItem"
          :headers="settleHeader"
          :items="reportSettleSummary"
          :loading="reportSettleSummaryLoading"
          :server-items-length="reportSettleSummaryCount"
          loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
          class="elevation-0 mt-4"
          transition
          fixed-header
          height="600"
        >
          <template v-slot:item.agencyName="{ item }">
            <td nowrap="true" v-if="item.agencyName">{{ item.agencyName }}</td>
          </template>
          <template v-slot:item.areaName="{ item }">
            <td nowrap="true" align="center" v-if="item.areaName">{{ item.areaName }}</td>
          </template>
          <template v-slot:item.January="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[0].total | formatMoney}}</div>
              <div class="my-2 warning--text">¥{{ item.list[0].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.February="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[1].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[1].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.March="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[2].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[2].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.April="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[3].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[3].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.May="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[4].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[4].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.June="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">{{ item.list[5].total | formatMoney }}</div>
              <div class="my-2 warning--text">{{ item.list[5].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.July="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[6].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[6].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.August="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[7].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[7].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.September="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[8].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[8].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.October="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[9].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[9].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.November="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[10].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[10].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.December="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.list[11].total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.list[11].other | formatMoney }}</div>
            </td>
          </template>
          <template v-slot:item.total="{ item }">
            <td nowrap="true" align="center">
              <div class="my-2 success--text">¥{{ item.total | formatMoney }}</div>
              <div class="my-2 warning--text">¥{{ item.otherTotal | formatMoney }}</div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filter_item"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="query.group"
              :items="userBusinessType"
              label="业务类型"
              item-text="name"
              item-value="code"
              outlined
              dense
              hide-details
              @change="searchList()"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="query.agency"
              :loading="agencyLoading"
              :items="agencyList"
              item-text="name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              clearable
              hide-details
              small-chips
              deletable-chips
              multiple
              label="代理商名称"
              @change="searchList()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="query.area"
              :items="areaList"
              label="所属区域"
              item-text="name"
              item-value="_id"
              outlined
              dense
              clearable
              hide-details
              multiple
              small-chips
              deletable-chips
              @change="searchList()"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="query.year"
              :items="yearList"
              label="时间"
              outlined
              dense
              clearable
              hide-details
              @change="searchList()"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filter_item">隐藏</v-btn>
          <v-btn color="secondary" text @click="clear_filter">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import ApiService from "@/common/http";
import store from "@/store";
import {mapGetters} from "vuex";
import {
  FETCH_ACCOUNT_LIST, FETCH_AGENCY_LIST,
  FETCH_AREA_LIST,
  FETCH_REPORT_SETTLE_SUMMARY,
} from "@/store/actions.type";
import {SET_AGENCY_LIST, SET_ERROR, SET_REPORT_SETTLE_SUMMARY} from "@/store/mutations.type";
export default {
  data() {
    return {
      optionsItem: {},
      query: {
        group: '',
        area: '',
        agency: '',
        year: '',
      },
      defaultQuery: {
        group: '',
        area: '',
        agency: '',
        year: '',
      },
      settleHeader: [
        { text: '代理商名称', value: 'agencyName', width: '200', align: 'left', sortable: false},
        { text: '区域', value: 'areaName', width: '120', align: 'center', sortable: false},
        { text: '1月', value: 'January', width: '100', align: 'center', sortable: false},
        { text: '2月', value: 'February', width: '100', align: 'center', sortable: false},
        { text: '3月', value: 'March', width: '100', align: 'center', sortable: false},
        { text: '4月', value: 'April', width: '100', align: 'center', sortable: false},
        { text: '5月', value: 'May', width: '100', align: 'center', sortable: false},
        { text: '6月', value: 'June', width: '100', align: 'center', sortable: false},
        { text: '7月', value: 'July', width: '100', align: 'center', sortable: false},
        { text: '8月', value: 'August', width: '100', align: 'center', sortable: false},
        { text: '9月', value: 'September', width: '100', align: 'center', sortable: false},
        { text: '10月', value: 'October', width: '100', align: 'center', sortable: false},
        { text: '11月', value: 'November', width: '100', align: 'center', sortable: false},
        { text: '12月', value: 'December', width: '100', align: 'center', sortable: false},
        { text: '合计', value: 'total', width: '120', align: 'center', sortable: false},
      ],
      searchAgency: null,
      filterDrawer: false,
      report: '',
      yearList: [],
      reportSettleList: [],
      dataCount: 0,
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_AGENCY_LIST, {
        query:
          "page=1&limit=-1"
      })
    ]).then(() => {
      next();
    });
  },
  created() {
    store.dispatch(FETCH_ACCOUNT_LIST)
    this.setYearList()
  },
  computed: {
    ...mapGetters([
      "currentUser", "userBusinessType", "reportSettleSummaryLoading", "reportSettleSummary", "reportSettleSummaryCount", "areaList", "agencyLoading", "agencyList", "agencyCount"
    ]),
  },
  mounted() {},
  methods: {
    filter_item() {
      this.filterDrawer = !this.filterDrawer
    },
    clear_filter() {
      this.query = Object.assign({}, this.defaultQuery)
      store.commit(SET_REPORT_SETTLE_SUMMARY, {
        list: {
          docs: []
        },
        query: {
          count: 0
        }
      })
    },

    setYearList() {
      let myDate= new Date();
      let startYear=myDate.getFullYear()-20
      let endYear=myDate.getFullYear()+20
      let arr = []
      for (let i=startYear; i<=endYear; i++) {
        arr.push(i)
      }
      this.yearList = arr
      this.query.year = myDate.getFullYear()
    },
    downloadReport() {
      let urlQuery = ''
      if (this.query.group) urlQuery += '&group=' + this.query.group
      if (this.query.area && this.query.area.length) urlQuery += '&area=' + this.query.area
      if (this.query.agency && this.query.agency.length) urlQuery += '&agency=' + this.query.agency
      if (this.query.year) urlQuery += '&year=' + this.query.year
      ApiService.download('/report/stats/exportSettleSummary?'+urlQuery, {
        responseType:'blob'
      })
        .then(resp => {
          let fileName = '代理商结算数据导出.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], {type: 'application/octet-stream'});
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.downloading = false
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        })
        .catch(err => { // 请求失败处理
          this.downloading = false
          store.commit(SET_ERROR, {msg: err});
        });
    },
    resetAgencyList() {
      store.commit(SET_AGENCY_LIST, {
        query: {},
        docs: []
      })
    },
    searchList() {
      this.getList(this.query)
    },
    getList(query = {}) {
      if (this.query.group && this.query.year) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
        let sort = "";
        if (sortBy && sortBy.length === 1) {
          if (sortDesc[0] === true) {
            sort = "-" + sortBy[0];
          } else {
            sort = sortBy[0];
          }
        }

        let urlQuery = ''
        if (query.group) urlQuery += '&group=' + query.group
        if (query.year) urlQuery += '&year=' + query.year
        if (query.area && query.area.length) urlQuery += '&area=' + query.area
        if (query.agency && query.agency.length) urlQuery += '&agency=' + query.agency

        if (Object.keys(this.optionsItem).length > 0) {
          store.dispatch(FETCH_REPORT_SETTLE_SUMMARY, {
            query:
              "page=" +
              page +
              "&limit=" +
              itemsPerPage +
              "&sort=" +
              sort +
              urlQuery,
          })
        }
      }
    },
  },
  directives: {}
}
</script>

<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
