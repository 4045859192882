var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("客户详情")])], 1), _c('v-divider'), _c('v-card-text', [_c('widgets-watermark', {
    attrs: {
      "watermarkText": _vm.wmText
    }
  }), _c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("基本信息")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "depressed": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_delete');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("delete")]), _vm._v("删除客户")], 1) : _vm._e()], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pl-8"
  }, [_vm._v(" " + _vm._s(_vm.customerInfo.name) + " "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('name', '客户名称');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 所属地区 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('area', '所属地区');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _vm.customerInfo.area ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.customerInfo.area.name) + " ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 用电类别 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('electric_type', '用电类别');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm.customerInfo.electric_type === 1 ? _c('span', [_vm._v("工业")]) : _vm.customerInfo.electric_type === 2 ? _c('span', [_vm._v("商业")]) : _vm._e()])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 内部负责人 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('confirm_settle', '内部负责人');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _vm.customerInfo.confirm_settle ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.customerInfo.confirm_settle.personal.name) + " ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 客户联系人 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('contact_name', '客户联系人');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.customerInfo.contact_name || '无') + " ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 客户联系方式 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('contact_phone', '联系电话');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.customerInfo.contact_phone || '无') + " ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 公司地址 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('address', '公司地址');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.customerInfo.address || '无') + " ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 客户营业执照 ")]), _c('v-list-item-subtitle', [_vm.customerInfo.license_image ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('check_img');
      }
    }
  }, [_vm._v("查看")]) : _vm._e(), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editCustomer('license_image', '客户营业执照');
      }
    }
  }, [_vm._v("上传")]) : _vm._e(), _vm.customerInfo.license_image ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_img');
      }
    }
  }, [_vm._v("删除")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("合同明细")]), _c('v-spacer'), _c('contract-edit', {
    attrs: {
      "getType": "customer",
      "getId": _vm.customerId,
      "isEdit": _vm.isEdit
    }
  }, [_vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v(" 新增合同 ")], 1) : _vm._e()], 1)], 1), _c('contract-list', {
    attrs: {
      "getType": "customer",
      "contractType": "1",
      "getId": _vm.customerId,
      "isEdit": _vm.isEdit,
      "reload": _vm.reload
    }
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.editValid,
      callback: function callback($$v) {
        _vm.editValid = $$v;
      },
      expression: "editValid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(this.editDlgTitle))]), _c('v-divider'), _c('v-card-text', [_vm.editDlgTitle === '客户名称' ? _c('v-text-field', {
    attrs: {
      "label": "客户名称",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "person"
    },
    model: {
      value: _vm.customerItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "name", $$v);
      },
      expression: "customerItem.name"
    }
  }) : _vm._e(), _vm.editDlgTitle === '所属地区' ? _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "prepend-icon": "map"
    },
    model: {
      value: _vm.customerItem.area,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "area", $$v);
      },
      expression: "customerItem.area"
    }
  }) : _vm._e(), _vm.editDlgTitle === '用电类别' ? _c('v-select', {
    attrs: {
      "items": _vm.electricType,
      "label": "用电类别",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "home_work"
    },
    model: {
      value: _vm.customerItem.electric_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "electric_type", $$v);
      },
      expression: "customerItem.electric_type"
    }
  }) : _vm._e(), _vm.editDlgTitle === '代理商' ? _c('v-autocomplete', {
    attrs: {
      "loading": _vm.agencyLoading,
      "items": _vm.agencyList,
      "search-input": _vm.searchAgency,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "代理商名称",
      "prepend-icon": "group"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      }
    },
    model: {
      value: _vm.customerItem.agency,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "agency", $$v);
      },
      expression: "customerItem.agency"
    }
  }) : _vm._e(), _vm.editDlgTitle === '内部负责人' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "公司内部负责人",
      "hint": "请查找并选择公司内部负责人",
      "prepend-icon": "emoji_people"
    },
    model: {
      value: _vm.customerItem.confirm_settle,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "confirm_settle", $$v);
      },
      expression: "customerItem.confirm_settle"
    }
  }) : _vm._e(), _vm.editDlgTitle === '客户联系人' ? _c('v-text-field', {
    attrs: {
      "label": "客户联系人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "connect_without_contact"
    },
    model: {
      value: _vm.customerItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contact_name", $$v);
      },
      expression: "customerItem.contact_name"
    }
  }) : _vm._e(), _vm.editDlgTitle === '联系电话' ? _c('v-text-field', {
    attrs: {
      "label": "联系电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone"
    },
    model: {
      value: _vm.customerItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contact_phone", $$v);
      },
      expression: "customerItem.contact_phone"
    }
  }) : _vm._e(), _vm.editDlgTitle === '公司地址' ? _c('v-text-field', {
    attrs: {
      "label": "公司地址",
      "outlined": "",
      "dense": "",
      "prepend-icon": "place"
    },
    model: {
      value: _vm.customerItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "address", $$v);
      },
      expression: "customerItem.address"
    }
  }) : _vm._e(), _vm.editDlgTitle === '客户营业执照' ? _c('v-file-input', {
    attrs: {
      "label": "单击此处选择文件上传",
      "outlined": "",
      "dense": "",
      "small-chips": "",
      "multiple": "",
      "show-size": "",
      "counter": ""
    },
    model: {
      value: _vm.editedAttach.files,
      callback: function callback($$v) {
        _vm.$set(_vm.editedAttach, "files", $$v);
      },
      expression: "editedAttach.files"
    }
  }) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.editValid
    },
    on: {
      "click": _vm.submitEdit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "800px"
    },
    model: {
      value: _vm.imgDlg,
      callback: function callback($$v) {
        _vm.imgDlg = $$v;
      },
      expression: "imgDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 客户营业执照 "), _vm.imgUrl ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_img');
      }
    }
  }, [_c('v-icon', [_vm._v("download")])], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.imgUrl,
      "contain": ""
    }
  }), _c('v-overlay', {
    staticClass: "align-center justify-center",
    attrs: {
      "absolute": ""
    },
    model: {
      value: _vm.overlay,
      callback: function callback($$v) {
        _vm.overlay = $$v;
      },
      expression: "overlay"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除客户 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除此客户吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm_delete');
      }
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlgImg,
      callback: function callback($$v) {
        _vm.warnDlgImg = $$v;
      },
      expression: "warnDlgImg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除客户营业执照 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除客户营业执照吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm_delete_img');
      }
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }