<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{userBusinessType.find(item => item.code === Number(query.group)).name}}工单列表</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
          @keydown.enter="setFilter()"
          label="编号、标题搜索（按回车）"
          prepend-inner-icon="search"
          solo
          flat
          dense
          hide-details
          clearable
        ></v-text-field>
      </v-toolbar>
      <widgets-watermark :watermarkText="wmText"></widgets-watermark>
<!--
          预研II期表格三种事件；
          待解决问题：单击代理商链接时触发单击事件，工单编号和标题则不触发。
          以上问题预期结果是：如果时单击行内链接则不触发单击事件。
          @click:row="singleClick" 批量处理时选择N个工单
          @dblclick:row="dblClick" 双击打开工单
          @contextmenu:row="rightClick" 右键快捷操作工单
-->
      <v-data-table
        v-model="selectedItem"
        :headers="computedHeaders"
        :items="caseList"
        :options.sync="optionsItem"
        :server-items-length="caseCount"
        :loading="caseLoading"
        :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
        height="600"
        fixed-header
        transition
        loading-text="数据读取中... 请稍后"
        item-key="_id"
        return-object="false"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-tabs align-with-title v-model="query.onlylist" class="mt-2 mb-4" @change="searchList">
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab>全部工单</v-tab>
              <v-tab>我发起的</v-tab>
              <v-tab>
                <v-badge v-if="remainCount" color="primary" :content="remainCount">
                  我的待办
                </v-badge>
                <span v-else>我的待办</span>
              </v-tab>
              <v-tab>
<!--                <v-badge v-if="editedCount" color="primary" :content="editedCount">-->
                  我处理的
<!--                </v-badge>-->
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
<!--            <v-divider class="mx-4" inset vertical></v-divider>-->
            <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=2 tableName="caseHeader" v-on:Cancel="cancelHeader()" v-on:Default="restoreHeader()" v-on:Save="setHeader"></widgets-custom-header>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="filterItem">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="reloadItem">
                  <v-icon small>autorenew</v-icon>
                </v-btn>
              </template>
              <span>刷新列表</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.case_number="{ item }">
          <td nowrap="true">
            <router-link :to="'/distributor/workbench/case/detail/' + item._id">{{ item.case_number }}</router-link>
          </td>
        </template>
        <template v-slot:item.settleTitle="{ item }">
          <td nowrap="true">
            <router-link :to="'/distributor/workbench/case/detail/' + item._id">{{ item.settleTitle ? item.settleTitle.substring(item.settleTitle.indexOf('20'), item.settleTitle.indexOf('代理商')) : '-'}}</router-link>
          </td>
        </template>
        <template v-slot:item.agency="{ item }">
          <td nowrap="true" v-if="item.agency">
            <router-link :to="'/distributor/agency/detail/' + item.agency?._id">{{ item.agency?.name }}</router-link>
          </td>
        </template>
        <template v-slot:item.area="{ item }">
          <td v-if="item.area" nowrap="true">{{ item.area.name }}</td>
        </template>
        <template v-slot:item.current_operator="{ item }">
          <td v-if="item.current_operator && item.current_operator.length" nowrap="true">
            {{ item.current_operator.map(o => o?.personal?.name) }}
          </td>
          <td v-else nowrap="true"></td>
        </template>
        <template v-slot:item.group="{ item }">
          <td v-if="item.group === 1">能源</td>
          <td v-else-if="item.group === 2">调峰</td>
          <td v-else-if="item.group === 3">售电</td>
          <td v-else-if="item.group === 4">智科</td>
        </template>
        <template v-slot:item.settle_price_total="{ item }">
          <td v-if="item.settle_price_total || item.settle_price_total === 0" nowrap="true" align="end">{{ item.settle_price_total | formatMoney('', '') }}</td>
        </template>
        <template v-slot:item.current_task="{ item }">
          <td v-if="item.current_task" nowrap="true">{{ item.current_task.name }}</td>
        </template>
        <template v-slot:item.print_receipt_count="{ item }">
          <td nowrap="true" v-if="item.receiptPrint && item.receiptPrint.length">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                {{ item?.receiptPrint?.length }}
                </span>
                <v-btn x-small icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(log, index) in item.receiptPrint" :key="item._id+'_print_'+index">
                  <v-list-item-content>
                  <v-list-item-title>{{log?.optAccount?.personal?.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{log.optTime | formatTime}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td nowrap="true" v-else>0</td>
        </template>
        <template v-slot:item.download_receipt_count="{ item }">
          <td nowrap="true" v-if="item.receiptDownload && item.receiptDownload.length">

            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                {{ item?.receiptDownload?.length }}
                </span>
                <v-btn x-small icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(log, index) in item.receiptDownload" :key="item._id+'_print_'+index">
                  <v-list-item-content>
                    <v-list-item-title>{{log?.optAccount?.personal?.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{log.optTime | formatTime}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td nowrap="true" v-else>0</td>
        </template>
        <template v-slot:item.download_summary_count="{ item }">
          <td nowrap="true" v-if="item.summaryDownload && item.summaryDownload.length">

            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                {{ item?.summaryDownload?.length }}
                </span>
                <v-btn x-small icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(log, index) in item.summaryDownload" :key="item._id+'_print_'+index">
                  <v-list-item-content>
                    <v-list-item-title>{{log?.optAccount?.personal?.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{log.optTime | formatTime}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td nowrap="true" v-else>0</td>
        </template>
        <template v-slot:item.creator="{ item }">
          <td v-if="item.creator">{{ item.creator.personal.name }}</td>
        </template>
        <template v-slot:item.status="{ item }">
          <td nowrap="true">
            <v-chip label small v-if="item.status === 1" color="primary">进行中</v-chip>
            <v-chip label small v-else-if="item.status === 2" color="error">已取消</v-chip>
            <v-chip label small v-else-if="item.status === 4" color="success">已完成</v-chip>
            <v-chip label small v-else>已完成</v-chip>
          </td>
        </template>
        <template v-slot:item.updateTime="{ item }">
          <td nowrap="true">{{ item.updateTime | formatTime}}</td>
        </template>
        <template v-slot:item.createTime="{ item }">
          <td nowrap="true">{{ item.createTime | formatTime}}</td>
        </template>
        <template v-slot:item.actions="{ item }">
          <td>
            <v-icon small @click="$router.push('/distributor/workbench/case/detail/' + item._id)" color="primary">content_paste_search</v-icon>
          </td>
        </template>
      </v-data-table>
      <v-navigation-drawer v-model="filterDrawer" app right>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              过滤条件
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
          <v-select
            v-model.number="query.group"
            :items="userBusinessType"
            label="业务类型"
            item-text="name"
            item-value="code"
            outlined
            dense
            @change="setFilter"
          ></v-select>
          <v-select
            v-model="query.area"
            :items="areaList"
            label="所属区域"
            item-text="name"
            item-value="_id"
            outlined
            dense
            multiple
            small-chips
            deletable-chips
            clearable
            @change="setFilter"
          ></v-select>
          <v-autocomplete
            v-model="query.producedBy"
            :loading="accountLoading"
            :items="accountList"
            :search-input.sync="searchAccount"
            item-text="personal.name"
            item-value="_id"
            autocomplete="off"
            outlined
            dense
            clearable
            label="申请人"
            @change="setFilter"
          ></v-autocomplete>
          <v-autocomplete
            v-model="query.agency"
            :loading="agencyLoading"
            :items="agencyList"
            :search-input.sync="searchAgency"
            item-text="name"
            item-value="_id"
            autocomplete="off"
            outlined
            dense
            clearable
            label="代理商名称"
            @change="setFilter"
          ></v-autocomplete>
          <h2 class="subtitle-2 mt-2">工单状态</h2>
          <v-chip-group v-if="caseStatus.length" v-model="query.status" column multiple @change="setFilter">
            <v-chip v-for="item in caseStatus" :key="item.code" :value="item.code" filter label outlined>{{item.name}}</v-chip>
          </v-chip-group>
          <h2 class="subtitle-2 mt-2">当前任务</h2>
          <v-chip-group v-if="taskList.length" v-model="query.task" column multiple @change="setFilter">
            <v-chip v-for="item in taskList" :key="item._id" :value="item._id" filter label outlined>{{item.name}}</v-chip>
          </v-chip-group>
        </v-card-text>
        <template v-slot:append>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
            <v-btn color="secondary" text @click="clearFilter">清空</v-btn>
          </v-card-actions>
        </template>
      </v-navigation-drawer>
    </v-card>
    <v-dialog v-model="dlgInfo" persistent scrollable width="600px">
      <v-card>
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="6">
              <p>1. 下载新增模版，填写客户信息.</p>
              <v-btn text @click="doAction('download_create_template')">下载模版<v-icon>file_download</v-icon></v-btn>
            </v-col>
            <v-col cols="6">
              <input
                id="import_file"
                type="file"
                name="import_file"
                ref="uploadImport"
                accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :multiple="false"
                @change="importCreateFiles($event)"/>
              <p>2. 上传填好的文件.</p>
              <v-btn text @click="doAction('upload_create_file')" :loading="!batch.import_end"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="batch.import_failed.length || batch.import_result.length">
            <v-col cols="12">
              <v-divider></v-divider>
              <v-alert
                outlined
                type="error"
                text
              >
                导入失败：<span v-if="batch.import_failed.length === 0">无</span><ul v-else><li v-for="(fail,index) in batch.import_failed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
              </v-alert>
              <v-alert
                outlined
                type="success"
                text
              >
                成功创建：{{ batch.import_result.length }} 条记录；
                <v-row v-if="batch.import_result.length">
                  <v-col class="grow">
                    <p class="font-weight-black" v-for="item in batch.import_result" :key="item._id">{{ item.name }}</p>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imgDlg" persistent scrollable width="800px">
      <v-card>
        <v-card-title>
          客户营业执照
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_img')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img :src="imgUrl" contain></v-img>
          <v-overlay
            v-model="overlay"
            absolute
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除客户
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除此客户吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="deleteCustomer">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu v-model="rightMenu" :position-x="mouseX" :position-y="mouseY" absolute offset-y>
      <v-list>
        <v-list-item>
          <v-list-item-title>工作愉快！</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>
<script>
import * as XLSX from 'xlsx';
import ApiService from "@/common/http";
import store from "@/store";
import {mapGetters} from "vuex";
import {
  FETCH_ACCOUNT_LIST, FETCH_AGENCY_LIST,
  FETCH_AREA_LIST, FETCH_CASE_LIST, FETCH_TASK_LIST,
  FETCH_URL,
  REMOVE_CUSTOMER
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";
export default {
  data() {
    return {
      wmText: '',
      optionsItem: {},
      query: {
        key: '',
        area: [],
        group: 0,
        producedBy: '',
        agency: '',
        status: [],
        task: [],
        onlylist: 0,
      },
      defaultQuery: {
        key: '',
        area: [],
        group: 0,
        producedBy: '',
        agency: '',
        status: [],
        task: [],
        onlylist: 0,
      },
      headerItem: [],
      defaultHeader: [
        { text: '工单编号', value: 'case_number', width: '120'},
        { text: '工单标题', value: 'settleTitle', width: '120'},
        { text: '代理商', value: 'agency', width: '180'},
        { text: '业务类型', value: 'group', width: '100', align: " d-none"},
        { text: '区域', value: 'area', width: '100'},
        { text: '总金额(元)', value: 'settle_price_total', width: '125'},
        { text: '当前任务', value: 'current_task', width: '150'},
        { text: '当前处理人', value: 'current_operator', width: '150'},
        { text: '申请人', value: 'creator', width: '100'},
        { text: '付款单打印', value: 'print_receipt_count', width: '115'},
        { text: '付款单下载', value: 'download_receipt_count', width: '115'},
        { text: '汇总单下载', value: 'download_summary_count', width: '115'},
        { text: '工单状态', value: 'status', width: '100'},
        { text: '更新时间', value: 'updateTime', width: '120'},
        { text: '创建时间', value: 'createTime', width: '120'},
        { text: '操作', value: 'actions', sortable: false},
      ],
      batch: {
        create_header: [],
        import_result: [],
        import_failed: [],
        import_end: true,
      },
      defaultBatch: {
        create_header: [],
        import_result: [],
        import_failed: [],
        import_end: true,
      },
      dlgInfo: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      warnDlg: false,
      deleteId: '',
      searchAccount: null,
      searchAgency: null,
      filterDrawer: false,
      clickTimer: null,
      selectedItem: [],
      rightMenu: false,
      mouseX: 0,
      mouseY: 0
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    searchAccount (val) {
      val && val !== this.query.producedBy && this.searchEmployee(val)
    },
    searchAgency (val) {
      val && val !== this.query.agency && this.getAgencyList(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true')
    ]).then(() => {
      next();
    });
  },
  created() {
    store.dispatch(FETCH_ACCOUNT_LIST)
    store.dispatch(FETCH_AGENCY_LIST, {query: ''})
    this.setWatermark()
    this.getHeader('caseHeader', 2)
    if (this.userBusinessType && this.userBusinessType.length) {
      this.query.group = this.userBusinessType[0].code
      this.defaultQuery.group = this.userBusinessType[0].code
      this.getTask(this.userBusinessType[0].code)
    }
  },
  computed: {
    ...mapGetters([
      "currentUser", "userBusinessType", "accountLoading", "accountList", "areaList", "taskList", "agencyList", "agencyCount", "agencyLoading", "caseList", "caseCount", "remainCount", "editedCount", "caseLoading"
    ]),
    caseStatus () {
      return Util.categories('caseStatus')
    },
    computedHeaders() {
      let arrHeaderItem = this.headerItem
      return arrHeaderItem.filter(item => !item.align)
    },
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/workbench'});
    },
    setWatermark() {
      this.wmText = this.currentUser.personal.name + this.currentUser.phone.substr(-4)
    },
    setFilter() {
      this.getTask(this.query.group)
      this.$router.push({ query: this.query });
      this.getList()
    },
    filterItem() {
      this.filterDrawer = !this.filterDrawer
    },
    clearFilter() {
      // window.localStorage.removeItem('filter_case');
      this.query = Object.assign({group: this.query.group}, this.defaultQuery)
      this.$router.push({ query: {} });
      this.getList()
    },
    reloadItem() {
      this.optionsItem.page = 1
      this.getList()
    },
    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content))
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
      }
    },
    cancelHeader() {
      this.getHeader('caseHeader', 2)
      this.$forceUpdate()
    },
    restoreHeader() {
      this.getHeader('caseHeader', 2)
      this.$forceUpdate()
    },
    setHeader() {
      this.getHeader('caseHeader', 2)
      this.$forceUpdate()
    },
    getTask(group) {
      if (group) {
        store.dispatch(FETCH_TASK_LIST, 'limit=-1&isEnable=true&group='+ group)
      }
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    getAgencyList (key) {
      store.dispatch(FETCH_AGENCY_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    resetFilter() {
      this.query = Object.assign({}, this.defaultQuery)
      this.getList()
    },
    searchList() {
      this.$router.push({ query: {} })
      this.getList(this.query)
    },
    deleteCustomer() {
      this.$store.dispatch(REMOVE_CUSTOMER, this.deleteId).then(() => {
        this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
        this.warnDlg = false
        this.deleteId = ''
        this.getList()
      }).catch((err) => {
        this.warnDlg = false
        this.deleteId = ''
        this.$store.commit(SET_ERROR, {msg: err.response.data.message})
      })
    },
    getList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }

      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.area && !Array.isArray(this.query.area)) this.query.area = this.query.area.split(',')
        if (this.query.task && !Array.isArray(this.query.task)) this.query.task = this.query.task.split(',')
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.onlylist) this.query.onlylist = Number(this.query.onlylist)
        if (this.query.group) this.query.group = Number(this.query.group)
        this.query.status = this.query.status.map(i => Number(i))
      }
      let urlQuery = ''
      urlQuery += this.query.key ? '&key=' + this.query.key : ''
      urlQuery += this.query.area.length ? '&area=' + this.query.area : ''
      urlQuery += this.query.task.length ? '&task=' + this.query.task : ''
      urlQuery += this.query.status.length ? '&status=' + this.query.status : ''
      urlQuery += this.query.group ? '&group=' + this.query.group : 0
      urlQuery += this.query.producedBy ? '&produced=' + this.query.producedBy : ''
      urlQuery += this.query.agency ? '&agency=' + this.query.agency : ''

      if (this.query.onlylist) {
        if (this.query.onlylist === 1) {
          urlQuery += '&onlylist=create'
        } else if (this.query.onlylist === 2) {
          urlQuery += '&onlylist=remain'
        } else if (this.query.onlylist === 3) {
          urlQuery += '&onlylist=edited'
        }
      }

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CASE_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    doAction(action, item = {}) {
      switch(action) {
        case 'batch_create': {
          this.dlgInfo = true
          break
        }
        case 'input_create': {
          this.$router.push('/distributor/customer/edit')
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, item).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'download_create_template': {
          let ws = XLSX.utils.aoa_to_sheet([['批量创建客户模版。']])
          XLSX.utils.sheet_add_json(ws, [
            {}
          ], {header: this.batch.create_header, origin: 'A2'})
          const merge = [
            {s: {r:0, c:0}, e: {r:0, c: this.batch.create_header.length - 1}}
          ]
          ws['!merges'] = merge
          ws.A1.s = {alignment:{ wrapText: true }};
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '批量创建客户')
          XLSX.writeFile(wb, '批量创建客户模版.xlsx')
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'delete_customer': {
          this.deleteId = item
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.deleteId = ''
          this.warnDlg = false
          break
        }
      }
    },
    importCreateFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importCase(fileList[x]);
      })
    },
    importCase(file) {
      this.batch.import_end = false
      let param = new FormData()
      param.append('file', file)
      ApiService.post('/customer', param)
        .then((data) => {
          this.batch.import_result = data.data.Success
          this.batch.import_failed = data.data.Failed
          this.$refs.uploadImport.value = ''
          this.batch.import_end = true
          this.getList()
        })
        .catch((err) => {
          this.$refs.uploadImport.value = ''
          this.batch.import_result = { updateSum: 0, insertSum: 0 }
          this.batch.import_failed = [{line: 2, error: err.response.data.message}]
          this.batch.import_end = true
        })
    },
    goToDetail(id) {
      this.$router.push('/distributor/customer/detail/' + id)
    },
    singleClick(v) {
      if (this.clickTimer === null) {
        this.clickTimer = setTimeout(() => {
          this.clickTimer = null
          let item = Object.assign({}, v)
          let index = this.selectedItem.findIndex((t) => t._id === item._id)
          if (index > -1) {
            this.selectedItem.splice(index, 1)
          } else {
            this.selectedItem.push(item)
          }
          console.log('select item count =>'+ this.selectedItem.length)
        }, 300)
      } else {
        clearTimeout(this.clickTimer)
        this.clickTimer = null
      }

    },
    dblClick(e, v) {
      e.stopPropagation()
      console.log('open item =>', v.item._id)
    },
    rightClick(e) {
      e.preventDefault()
      this.rightMenu = false
      this.mouseX = e.clientX
      this.mouseY = e.clientY
      this.$nextTick(() => {
        this.rightMenu = true
      })
    }
  },
  directives: {}
}
</script>

<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
