<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.push('/distributor/report')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>区域汇总数据</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-2" :disabled="!editDataId" @click="openEditDlg">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>修改数据</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-2" :disabled="!reportAreaList || !reportAreaList.length" @click="downloadReport">
              <v-icon>download</v-icon>
            </v-btn>
          </template>
          <span>下载数据</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click.stop="filterDrawer = !filterDrawer">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>过滤数据</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-if="business_type === 3">
        <v-row dense justify="space-between">
          <v-col>
            <report-card-count icon="location_city" :count="$options.filters['formatMoney'](report.xt_service_fee)" label="国网开票金额(元)(含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="real_estate_agent" :count="$options.filters['formatMoney'](report.xt_service_fee_no_tax)" label="国网开票金额(元)(不含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="bolt" :count="report.settle_elec_total" label="结算电量(兆瓦时)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="attach_money" :count="$options.filters['formatMoney'](report.agency_service_fee)" label="代理商服务费(含税)(元)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="price_check" :count="$options.filters['formatMoney'](report.agency_service_fee_no_tax)" label="代理商服务费(不含税)(元)"></report-card-count>
          </v-col>
        </v-row>
        <v-row dense justify="space-between">
          <v-col>
            <report-card-count icon="sync_alt" :count="$options.filters['formatMoney'](report.diff_assess_fee)" label="偏差考核费用(元)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="add_business" :count="$options.filters['formatMoney'](report.market_share_fee)" label="市场补偿和分摊费用(元)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="balance" :count="$options.filters['formatMoney'](report.unbalance_money)" label="不平衡资金(元)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="percent" :count="report.agency_service_fee_ratio + '%'" label="代理商服务费占比"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="payments" :count="$options.filters['formatMoney'](report.net_profit)" label="毛利(元)"></report-card-count>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="business_type === 1">
        <v-row dense justify="space-between">
          <v-col>
            <report-card-count icon="apartment" :count="$options.filters['formatMoney'](report.xt_service_fee)" label="鑫泰开票金额(元)(含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="apartment" :count="$options.filters['formatMoney'](report.xt_service_fee_no_tax)" label="鑫泰开票金额(元)(不含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="factory" :count="$options.filters['formatMoney'](report.industry_xt_service_fee)" label="工业开票金额(元)(含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="factory" :count="$options.filters['formatMoney'](report.industry_xt_service_fee_no_tax)" label="工业开票金额(元)(不含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="receipt_long" :count="$options.filters['formatMoney'](report.business_xt_service_fee)" label="商业开票金额(元)(含税)"></report-card-count>
          </v-col>
        </v-row>
        <v-row dense justify="space-between">
          <v-col>
            <report-card-count icon="receipt_long" :count="$options.filters['formatMoney'](report.business_xt_service_fee_no_tax)" label="商业开票金额(元)(不含税)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="attach_money" :count="$options.filters['formatMoney'](report.agency_service_fee)" label="代理商服务费(含税)(元)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="price_check" :count="$options.filters['formatMoney'](report.agency_service_fee_no_tax)" label="代理商服务费(不含税)(元)"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="percent" :count="report.agency_service_fee_ratio + '%'" label="代理商服务费占比"></report-card-count>
          </v-col>
          <v-col>
            <report-card-count icon="payments" :count="$options.filters['formatMoney'](report.gross_profit)" label="税后毛利(元)"></report-card-count>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :options.sync="optionsItem"
          :headers="defaultHeader"
          :items="reportAreaList"
          :server-items-length="reportAreaListCount"
          :loading="reportAreaListLoading"
          loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
          class="elevation-0 mt-4"
          transition
          fixed-header
        >
          <template v-slot:item.agency_name="{ item }">
            <td nowrap="true" v-if="item.agency_name">{{ item.agency_name }}</td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="business_type"
              :items="typeItems"
              label="业务类型"
              item-text="name"
              item-value="code"
              outlined
              dense
              hide-details
              @change="searchList('business_type')"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="business_type === 1">
            <v-menu ref="report_date" v-model="start_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.start_date"
                  label="开始时间"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="query.start_date" no-title scrollable type="month" width="225" @input="start_date_menu = false" @change="searchList">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="business_type === 1">
            <v-menu ref="report_date" v-model="end_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.end_date"
                  label="结束时间"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="query.end_date" no-title scrollable type="month" width="225" @input="end_date_menu = false" @change="searchList">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="business_type === 3">
            <v-menu ref="report_date" v-model="report_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="query.date"
                  label="时间"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="query.date" no-title scrollable type="month" width="225" @input="report_date_menu = false" @change="searchList">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="query.area"
              :items="areaList"
              label="所属区域"
              item-text="name"
              item-value="_id"
              outlined
              dense
              clearable
              hide-details
              @change="searchList"
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="business_type === 3">
            <v-autocomplete
              v-model="query.agency"
              :loading="agencyLoading"
              :items="agencyList"
              :search-input.sync="searchAgency"
              item-text="name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              clearable
              hide-details
              label="代理商名称"
              @change="searchList"
            ></v-autocomplete>
          </v-col>
          <!--          <v-col>-->
          <!--            <v-btn color="secondary" depressed class="mr-2" @click="resetFilter">重置</v-btn>-->
          <!--            <v-btn color="primary" depressed @click="searchList">搜索</v-btn>-->
          <!--          </v-col>-->
        </v-row>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="clear_filter">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="editDlg" scrollable persistent width="550">
      <v-card width="600">
        <v-card-title>
          <span>编辑结算数据</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEditDlg">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-6">
          <v-form v-model="valid" @submit.prevent="saveSubmit">
            <v-text-field
              v-model="editItem.xt_service_fee"
              label="国网开票金额(元)(含税)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.xt_service_fee_no_tax"
              label="国网开票金额(元)(不含税)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.settle_elec_total"
              label="结算电量(兆瓦时)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_service_fee"
              label="代理商服务费(含税)(元)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_service_fee_no_tax"
              label="代理商服务费(不含税)(元)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.diff_assess_fee"
              label="偏差考核费用(元)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.market_share_fee"
              label="市场补偿和分摊费用(元)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.unbalance_money"
              label="不平衡资金(元)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="editItem.net_profit"
              label="税后净利润(元)"
              outlined
              :rules="[rules.required]"
              dense
              type="number"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="closeEditDlg">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="saveSubmit" :disabled="!valid">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  FETCH_AGENCY_LIST,
  FETCH_AREA_LIST,
  FETCH_REPORT_AREA_LIST,
  FETCH_REPORT_AREA_SUMMARY,
  EDIT_REPORT_AREA_SUMMARY
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import {SET_ERROR, SET_REPORT_AREA_LIST} from "@/store/mutations.type";
import ApiService from "@/common/http";

export default {
  data() {
    return {
      report:{
        xt_service_fee: 0,
        xt_service_fee_no_tax: 0,
        settle_elec_total: 0,
        agency_service_fee: 0,
        agency_service_fee_no_tax: 0,
        diff_assess_fee: 0,
        market_share_fee: 0,
        unbalance_money: 0,
        agency_service_fee_ratio: 0,
        net_profit: 0,
        industry_xt_service_fee: 0,
        industry_xt_service_fee_no_tax: 0,
        business_xt_service_fee: 0,
        business_xt_service_fee_no_tax: 0,
        gross_profit: 0,
      },
      defaultReport:{
        xt_service_fee: 0,
        xt_service_fee_no_tax: 0,
        settle_elec_total: 0,
        agency_service_fee: 0,
        agency_service_fee_no_tax: 0,
        diff_assess_fee: 0,
        market_share_fee: 0,
        unbalance_money: 0,
        agency_service_fee_ratio: 0,
        net_profit: 0,
        industry_xt_service_fee: 0,
        industry_xt_service_fee_no_tax: 0,
        business_xt_service_fee: 0,
        business_xt_service_fee_no_tax: 0,
        gross_profit: 0,
      },
      optionsItem: {},
      business_type: '',
      query: {
        area: '',
        agency: '',
        date: '',
        start_date: '',
        end_date: '',
      },
      defaultQuery: {
        area: '',
        agency: '',
        date: '',
        start_date: '',
        end_date: '',
      },
      report_date_menu: false,
      start_date_menu: false,
      end_date_menu: false,
      searchAgency: null,
      defaultHeader: [],
      sellHeader: [
        { text: '代理商名称', value: 'agency_name', width: '160', align: 'center', sortable: false},
        { text: '申报电量(兆瓦时)', value: 'report_elec_total', width: '120', align: 'center', sortable: false},
        { text: '结算电量(兆瓦时)', value: 'settle_elec_total', width: '120', align: 'center', sortable: false},
        { text: '偏差电量(兆瓦时)', value: 'diff_elec_total', width: '120', align: 'center', sortable: false},
        { text: '偏差率(%)', value: 'diff_ratio_total', width: '120', align: 'center', sortable: false},
        { text: '购电均价(元/兆瓦时)', value: 'buy_avg_price', width: '120', align: 'center', sortable: false},
        { text: '零售均价(元/兆瓦时)', value: 'retail_avg_price', width: '120', align: 'center', sortable: false},
        { text: '度电收益(元/兆瓦时)', value: 'income_per_kwh', width: '120', align: 'center', sortable: false},
        { text: '收益占比', value: 'profit_ratio', width: '120', align: 'center', sortable: false},
        { text: '代理商服务费(元)', value: 'agency_service_fee', width: '120', align: 'center', sortable: false},
        { text: '实际付款金额(含税)(元)', value: 'agency_fee_done', width: '120', align: 'center', sortable: false},
      ],
      energyHeader: [
        { text: '历史用户数量', value: 'historyCustomerCount', width: '160', align: 'center', sortable: false},
        { text: '本年度用户数量', value: 'yearCustomerCount', width: '120', align: 'center', sortable: false},
        { text: '历史用户收入(不含税)', value: 'historyCustomerServiceFeeNoTax', width: '120', align: 'center', sortable: false},
        { text: '本年度用户收入(不含税)', value: 'yearCustomerServiceFeeNoTax', width: '120', align: 'center', sortable: false},
        { text: '历史用户收入占比', value: 'historyCustomerServiceFeeNoTaxRatio', width: '120', align: 'center', sortable: false},
        { text: '本年度用户收入占比', value: 'yearCustomerServiceFeeNoTaxRatio', width: '120', align: 'center', sortable: false},
        { text: '工业收入占比', value: 'industry_xt_service_fee_no_tax_ratio', width: '120', align: 'center', sortable: false},
        { text: '商业收入占比', value: 'business_xt_service_fee_no_tax_ratio', width: '120', align: 'center', sortable: false},
      ],
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
      },
      valid: true,
      editDlg: false,
      editItem: {
        xt_service_fee: '',
        xt_service_fee_no_tax: '',
        settle_elec_total: '',
        agency_service_fee: '',
        agency_service_fee_no_tax: '',
        diff_assess_fee: '',
        market_share_fee: '',
        unbalance_money: '',
        net_profit: '',
      },
      defaultItem: {
        xt_service_fee: '',
        xt_service_fee_no_tax: '',
        settle_elec_total: '',
        agency_service_fee: '',
        agency_service_fee_no_tax: '',
        diff_assess_fee: '',
        market_share_fee: '',
        unbalance_money: '',
        net_profit: '',
      },
      editDataId: '',
      filterDrawer: false,
      typeItems: [],
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    business_type(val) {
      if (val === 1) {
        this.defaultHeader = this.energyHeader
      } else if (val === 3) {
        this.defaultHeader = this.sellHeader
      }
    },
    searchAgency (val) {
      val && val !== this.query.agency && this.getAgencyList(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.resetBusinessType()
    store.commit(SET_REPORT_AREA_LIST, {
      docs: [],
      query: {
        count: 0
      }
    })
  },
  computed: {
    ...mapGetters([
      "currentUser", "userBusinessType", "agencyList", "agencyLoading", "areaList", "reportAreaListLoading", "reportAreaList", "reportAreaListCount"
    ]),
  },
  mounted() {},
  methods: {
    resetBusinessType() {
      this.userBusinessType.map(item => {
        if (item.code === 1 || item.code === 3) this.typeItems.push(item)
      })
      if (this.typeItems && this.typeItems.length) {
        this.business_type = this.typeItems[0].code
        // this.getList(this.query)
      }
    },
    clear_filter() {
      this.editDataId = ''
      this.query = Object.assign({}, this.defaultQuery)
      this.report = Object.assign({}, this.defaultReport)
      store.commit(SET_REPORT_AREA_LIST, {
        docs: [],
        query: {
          count: 0
        }
      })
    },
    openEditDlg() {
      this.editItem = Object.assign({}, this.report)
      this.editDlg = true
    },
    closeEditDlg() {
      this.editDlg = false
      this.editItem = Object.assign({}, this.defaultItem)
    },
    saveSubmit() {
      let json = {}
      json.id = this.editDataId
      Object.keys(this.editItem).map(item => this.editItem[item] = Number(this.editItem[item]))
      json.item = this.editItem
      delete json.item.id
      delete json.item._id
      store.dispatch(EDIT_REPORT_AREA_SUMMARY, json).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.getSummary(this.query)
        this.closeEditDlg()
      }).catch(err => {
        store.commit(SET_ERROR, {msg: err.data})
      })
    },
    searchList(type) {
      // if (type === 'list') {
      //   this.getList(this.query)
      // } else {
      //   this.getSummary(this.query)
      //   this.getList(this.query)
      // }
      if (type === 'business_type') this.clear_filter()
      this.getSummary(this.query)
      this.getList(this.query)
    },
    getSummary(query = {}) {
      if (this.business_type && (this.query.date || (this.query.start_date && this.query.end_date))) {
        this.editDataId = ''
        // this.report = Object.assign({}, this.defaultReport)
        let urlQuery = ''
        if (this.business_type) urlQuery += '&business_type=' + this.business_type
        if (this.business_type === 3) {
          if (query.date) urlQuery += '&date=' + query.date
        } else {
          if (query.start_date) urlQuery += '&start_date=' + query.start_date
          if (query.end_date) urlQuery += '&end_date=' + query.end_date
        }
        if (query.area) urlQuery += '&area=' + query.area
        store.dispatch(FETCH_REPORT_AREA_SUMMARY, urlQuery).then(res => {
          // this.report = res
          Object.keys(res).map(item => {
            if (item === 'id' || item === '_id') {
              this.report[item] = res[item]
            } else if (item === 'settle_elec_total') {
              this.report[item] = Number(res[item]).toFixed(3)
            } else {
              this.report[item] = Number(res[item]).toFixed(2)
            }
          })
          if (query.area && res.id) this.editDataId = res.id
        }).catch(err => {
          store.commit(SET_ERROR, {msg: err.data})
        })
      }
    },
    getList(query={}) {
      if (this.business_type && (this.query.date || (this.query.start_date && this.query.end_date))) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
        let sort = "";
        if (sortBy && sortBy.length === 1) {
          if (sortDesc[0] === true) {
            sort = "-" + sortBy[0];
          } else {
            sort = sortBy[0];
          }
        }

        let urlQuery = ''
        if (this.business_type) urlQuery += '&business_type=' + this.business_type
        if (this.business_type === 3) {
          if (query.date) urlQuery += '&date=' + query.date
          if (query.agency) urlQuery += '&agency=' + query.agency
        } else {
          if (query.start_date) urlQuery += '&start_date=' + query.start_date
          if (query.end_date) urlQuery += '&end_date=' + query.end_date
        }
        if (query.area) urlQuery += '&area=' + query.area
        if (Object.keys(this.optionsItem).length > 0) {
          store.dispatch(FETCH_REPORT_AREA_LIST, {
            query:
              "page=" +
              page +
              "&limit=" +
              itemsPerPage +
              "&sort=" +
              sort +
              urlQuery,
          })
        }
      }
    },
    getReportSummary() {
      store.dispatch(FETCH_REPORT_AREA_SUMMARY).then( res => {
        this.report = res
      }).catch( err => {
        store.commit(SET_ERROR, { msg: err.data })
      })
    },
    getAgencyList (key) {
      store.dispatch(FETCH_AGENCY_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    downloadReport() {
      let urlQuery = ''
      if (this.business_type) urlQuery += '&business_type=' + this.business_type
      if (this.business_type === 3) {
        if (this.query.date) urlQuery += '&date=' + this.query.date
        if (this.query.area) urlQuery += '&area=' + this.query.area
        if (this.query.agency) urlQuery += '&agency=' + this.query.agency
      } else {
        if (this.query.start_date) urlQuery += '&start_date=' + this.query.start_date
        if (this.query.end_date) urlQuery += '&end_date=' + this.query.end_date
      }
      ApiService.download('/report/stats/exportAreaData?'+urlQuery, {
        responseType:'blob'
      })
        .then(resp => {
          let fileName = (this.business_type === 3?'售电':'能源') + '-区域汇总数据数据导出.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], {type: 'application/octet-stream'});
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.downloading = false
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        })
        .catch(err => { // 请求失败处理
          this.downloading = false
          store.commit(SET_ERROR, {msg: err});
        });
    },
  },
  directives: {}
}
</script>
