<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>新增代理商</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="saveAgency">
          <v-row>
            <v-col cols="6">
              <v-subheader>代理商信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-text-field
                v-model="agencyItem.name"
                label="代理商名称*"
                outlined
                :rules="[rules.required]"
                dense
                prepend-icon="group"
              ></v-text-field>
              <v-select
                v-model="agencyItem.area"
                :items="areaList"
                label="所属区域*"
                item-text="name"
                item-value="_id"
                outlined
                :rules="[rules.selected]"
                dense
                prepend-icon="map"
              ></v-select>
              <v-autocomplete
                v-model="agencyItem.person_in_charge"
                :loading="accountLoading"
                :items="accountList"
                :search-input.sync="searchAccount"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                :rules="[rules.required]"
                dense
                chips
                small-chips
                deletable-chips
                label="内部负责人*"
                hint="请查找并选择公司内部负责人"
                multiple
                prepend-icon="emoji_people"
              ></v-autocomplete>
              <v-text-field
                v-model="agencyItem.contact_name"
                label="代理商联系人"
                outlined
                dense
                prepend-icon="connect_without_contact"
              ></v-text-field>
              <v-text-field
                v-model="agencyItem.contact_phone"
                label="联系电话"
                outlined
                dense
                prepend-icon="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-subheader>发票信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-select
                v-model="agencyItem.invoice_type"
                :items="invoiceType"
                label="发票类型*"
                item-text="name"
                item-value="code"
                outlined
                :rules="[rules.selected]"
                dense
                prepend-icon="format_list_numbered_rtl"
              ></v-select>
              <v-text-field
                v-model="agencyItem.invoice_rate"
                label="发票费率*"
                outlined
                dense
                :rules="[rules.required]"
                type="number"
                prepend-icon="percent"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.title"
                label="抬头名称"
                outlined
                dense
                prepend-icon="apartment"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.license_code"
                label="统一社会信用代码"
                outlined
                dense
                prepend-icon="pin"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.bank_name"
                label="开户行*"
                outlined
                dense
                :rules="[rules.required]"
                prepend-icon="account_balance"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.bank_account"
                label="银行账号*"
                outlined
                dense
                :rules="[rules.required]"
                prepend-icon="account_balance_wallet"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.license_address"
                label="企业地址"
                outlined
                dense
                prepend-icon="location_on"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.license_telephone"
                label="企业电话"
                outlined
                dense
                prepend-icon="ring_volume"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.delivery_name"
                label="发票接收人"
                outlined
                dense
                prepend-icon="person"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.delivery_phone"
                label="接收人电话"
                outlined
                dense
                prepend-icon="phone_in_talk"
              >
              </v-text-field>
              <v-text-field
                v-model="agencyItem.invoice_info.delivery_address"
                label="发票邮寄地址"
                outlined
                dense
                prepend-icon="home"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-subheader>合同信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-select
                v-model="agencyItem.contracts[0].business_type"
                :items="userBusinessType"
                label="合同类型*"
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                outlined
                dense
                prepend-icon="list"
              ></v-select>
              <v-text-field
                v-model="agencyItem.contracts[0].file_no"
                label="档案号"
                outlined
                dense
                prepend-icon="pin"
              ></v-text-field>
              <v-menu ref="sign_date" v-model="sign_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="agencyItem.contracts[0].sign_date"
                    label="签约时间*"
                    readonly
                    outlined
                    :rules="[rules.required]"
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="fact_check"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="agencyItem.contracts[0].sign_date" no-title scrollable @input="sign_date_menu = false">
                </v-date-picker>
              </v-menu>
              <v-menu ref="sign_date" v-model="contract_start_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="agencyItem.contracts[0].contract_start"
                    label="合同开始日期*"
                    readonly
                    outlined
                    :rules="[rules.required]"
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="event_available"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="agencyItem.contracts[0].contract_start" no-title scrollable @input="contract_start_menu = false">
                </v-date-picker>
              </v-menu>
              <v-menu ref="sign_date" v-model="contract_end_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="agencyItem.contracts[0].contract_end"
                    label="合同结束日期*"
                    readonly
                    outlined
                    :rules="[rules.required]"
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="event_busy"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="agencyItem.contracts[0].contract_end" no-title scrollable @input="contract_end_menu = false">
                </v-date-picker>
              </v-menu>
              <v-textarea
                v-model="agencyItem.contracts[0].remark"
                label="备注"
                autocomplete="off"
                clearable
                outlined
                dense
                no-resize
                prepend-icon="comment"
              ></v-textarea>
              <v-list subheader dense>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12">
                    <v-subheader>项目确认单</v-subheader>
                    <v-row align="center" justify="center">
                      <v-card v-for="(n, i) in this.preUrl" :key="i" class="ma-2" outlined tile>
                        <v-hover>
                          <template v-slot:default="{ hover }">
                            <v-img v-if="n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'jpg' || n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'png'" :src="n" class="ma-2" :max-width="125">
                              <v-fade-transition>
                                <v-overlay v-if="hover" absolute class="align-center" color="white lighten-5">
<!--                                  <v-btn icon color="primary" @click="downloadFile(n)"><v-icon>get_app</v-icon></v-btn>-->
                                  <v-btn icon color="primary" @click="clickUploadInput('confirm_img', i)"><v-icon>backup</v-icon></v-btn>
                                  <v-btn icon color="primary" @click="removeImg('confirm_img', i)"><v-icon>delete</v-icon></v-btn>
                                </v-overlay>
                              </v-fade-transition>
                            </v-img>
                            <v-sheet v-else color="white" height="50" width="200" class="d-flex align-center px-2">
                              {{agencyItem.contracts[0].confirm_image[i].substring(agencyItem.contracts[0].confirm_image[i].lastIndexOf('/') + 1, agencyItem.contracts[0].confirm_image[i].length)}}
                              <v-fade-transition>
                                <v-overlay v-if="hover" absolute class="align-center" color="white lighten-5">
<!--                                  <v-btn icon color="primary" @click="downloadFile(n)"><v-icon>get_app</v-icon></v-btn>-->
                                  <v-btn icon color="primary" @click="clickUploadInput('confirm_img', i)"><v-icon>backup</v-icon></v-btn>
                                  <v-btn icon color="primary" @click="removeImg('confirm_img', i)"><v-icon>delete</v-icon></v-btn>
                                </v-overlay>
                              </v-fade-transition>
                            </v-sheet>
                          </template>
                        </v-hover>
                      </v-card>
                      <v-card class="ma-3 pa-6" outlined tile hover @click="clickUploadInput('confirm_img', -1)"><v-icon>add</v-icon></v-card>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="mt-6"></v-divider>
                <v-list-item class="px-0">
                  <v-list-item-avatar rounded="0" size="80">
                    <v-icon x-large>description</v-icon>
<!--                    <v-icon v-if="!licenseUrl" x-large>image</v-icon>-->
<!--                    <v-img v-else :src="licenseUrl?licenseUrl:''" width="80" height="80"></v-img>-->
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">
                      <div>合同文件，支持图片、PDF、Word格式</div>
                      <div v-if="agencyItem.contracts[0].file_name">当前文件：<span class="primary--text">{{ agencyItem.contracts[0].file_name }}</span></div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                      <v-col>
                        <v-btn v-if="agencyItem.contracts[0].file_name" icon small @click="removeImg('href')">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn icon @click="clickUploadInput('href')" small>
                          <v-icon>upload</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <input id="files" type="file" name="file" ref="uploadInput" accept=".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream" :multiple="false"
                     @change="detectFiles($event)"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="saveAgency" :disabled="!valid">
          保存
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="Upload.uploading" persistent max-width="550px">
      <v-card color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-progress-linear
                color="white"
                :value="Upload.progressUpload"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import {FETCH_UPLOAD, CREATE_AGENCY, CREATE_CONTRACT, FETCH_URL, FETCH_AREA_LIST, FETCH_ACCOUNT_LIST} from "@/store/actions.type";
import Util from "@/common/util";

export default {
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      agencyItem: {
        name: '',
        area: '',
        person_in_charge: [],
        contact_name: '',
        contact_phone: '',
        invoice_type: '',
        invoice_rate: '',
        invoice_info: {
          title: '', //发票抬头
          license_code: '', //统一社会信用代码
          bank_name: '', //开户银行名称
          bank_account: '', //基本开户账号
          license_address: '', //注册场所地址
          license_telephone: '', //注册固定电话
          delivery_name: '', //收件人姓名
          delivery_phone: '', //收件人电话
          delivery_address: '', //收件地址
        },
        contracts: [
          {
            business_type: '',
            file_no: '',
            sign_date: '',
            contract_start: '',
            contract_end: '',
            href: '',
            file_name: '',
            confirm_image: [],
            remark: '',
          }
        ]
      },
      sign_date_menu: false,
      contract_start_menu: false,
      contract_end_menu: false,
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fileName: '请上传文件',
      preUrl: [],
      searchAccount: null,
      uploadType: '',
      uploadIndex: -1,
      licenseUrl: '',
    }
  },
  watch: {
    searchAccount (val) {
      val && val !== this.agencyItem.person_in_charge && this.searchEmployee(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true')
    ]).then(() => {
      next();
    });
  },
  created() {
    store.dispatch(FETCH_ACCOUNT_LIST)
  },
  computed: {
    ...mapGetters(["currentUser", "userBusinessType", "distributor", "uploadSign", "areaList", "accountLoading", "accountList"]),
    businessType () {
      return Util.categories('businessType')
    },
    signType () {
      return Util.categories('signType')
    },
    invoiceType () {
      return Util.categories('invoiceType')
    },
  },
  mounted() {},
  methods: {
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    resetData() {
      this.agencyItem.invoice_type = Number(this.agencyItem.invoice_type)
      this.agencyItem.invoice_rate = Number(this.agencyItem.invoice_rate)
      this.agencyItem.contracts[0].contract_type = 2
      return this.agencyItem
    },
    saveAgency() {
      store.dispatch(CREATE_AGENCY, this.resetData())
        .then((res) => {
          this.agencyItem.contracts[0].agency = res._id
          store.dispatch(CREATE_CONTRACT, this.agencyItem.contracts[0]).then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.$router.push({path: '/distributor/agency/detail/' + res._id});
          }).catch((err) => {
            store.commit(SET_ERROR, {msg: '代理商创建成功，合同创建失败；' + err.data});
            this.$router.push({path: '/distributor/agency/detail/' + res._id});
          })
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.data});
        });
    },
    clickUploadInput(type, index) {
      this.uploadType = type
      this.uploadIndex = index
      this.$store.dispatch(FETCH_UPLOAD, 'private')
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name.replace(/[%#&]/g,"");
      this.Upload.uploading = true;
      let param = new FormData();
      let menu = ''
      if (this.uploadType === 'confirm_img') {
        menu = 'contract/agency/confirm/'
      } else if (this.uploadType === 'href') {
        menu = 'contract/agency/agreement/'
      }
      param.append('name', file.name);
      param.append('key',  menu + this.uploadSign.key + '/' + this.fileName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          // this.agencyItem.contracts[0][imgKey] = param.get('key')
          store.dispatch(FETCH_URL, param.get('key')).then((fileUrl) => {
            if (this.uploadType === 'confirm_img') {
              if (this.uploadIndex > -1) {
                this.preUrl.splice(this.uploadIndex, 1, fileUrl)
                this.agencyItem.contracts[0].confirm_image.splice(this.uploadIndex, 1, param.get('key'));
              } else {
                this.preUrl.push(fileUrl);
                this.agencyItem.contracts[0].confirm_image.push(param.get('key'));
              }
              console.log(this.agencyItem.contracts[0].confirm_image)
            } else if (this.uploadType === 'href') {
              this.agencyItem.contracts[0].href = param.get('key')
              this.agencyItem.contracts[0].file_name = param.get('name')
              this.licenseUrl = fileUrl;
            }
            this.$forceUpdate();
          });
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    removeImg(type, index) {
      if (type === 'confirm_img') {
        this.preUrl.splice(index, 1);
        this.agencyItem.contracts[0].confirm_image.splice(index, 1);
      } else if (type === 'href') {
        this.licenseUrl = ''
        this.agencyItem.contracts[0].file_name = ''
        this.agencyItem.contracts[0].href = ''
      }
    },
    downloadFile(fileLink) {
      // this.$store.dispatch(FETCH_URL, fileLink)
      //   .then((fileurl) => {
      window.location.href = fileLink;
      // })
      // .catch((err) => {
      //   store.commit(SET_ERROR, { msg: err });
      // });
    },
  },
  directives: {}
}
</script>

<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.hover-btn {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}
</style>
