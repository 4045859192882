<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.push('/distributor/report')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>公司数据统计</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
        <v-card-text>
          <v-row dense justify="space-between">
            <v-col>
              <report-card-count icon="location_city" :count="report.customerCount" label="客户总数" path="/dealer/enterprise"></report-card-count>
            </v-col>
            <v-col>
              <report-card-count icon="real_estate_agent" :count="report.agencyCount" label="代理商总数" path="/dealer/asset/list"></report-card-count>
            </v-col>
            <v-col>
              <report-card-count icon="bolt" :count="$options.filters['formatMoneyUnitInt'](report.totalElec)" label="累计电量(兆瓦时)" path="/dealer/workbench/order/list"></report-card-count>
            </v-col>
            <v-col>
              <report-card-count icon="attach_money" :count="$options.filters['formatMoneyUnitInt'](report.totalXtServiceFee)" label="累计鑫泰服务费(元)" path="/dealer/workbench"></report-card-count>
            </v-col>
            <v-col>
              <report-card-count icon="price_check" :count="$options.filters['formatMoneyUnitInt'](report.totalNetProfit)" label="累计净利润(元)" path="/dealer/workbench"></report-card-count>
            </v-col>
          </v-row>
          <report-elec-analysis></report-elec-analysis>
          <report-service-analysis></report-service-analysis>
          <report-income-analysis></report-income-analysis>
        </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import store from "@/store";
import {FETCH_ACCOUNT_LIST, FETCH_AREA_LIST, FETCH_REPORT_SUMMARY} from "@/store/actions.type";
import { mapGetters } from "vuex";
import {SET_ERROR, SET_REPORT_DRAWER} from "@/store/mutations.type";

export default {
  data() {
    return {
      report:{},
      query: {
        person_in_charge: '',
        area: '',
        agency: '',
        settle_start: '',
        settle_end: '',
      },
      settle_start_menu: false,
      settle_end_menu: false,
    }
  },
  watch: {

  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_ACCOUNT_LIST, 'limit=-1')
    ]).then(() => {
      next();
    });
  },
  beforeRouteLeave(to, from, next) {
    store.commit(SET_REPORT_DRAWER)
    next()
  },
  created() {
    this.getReportSummary()
  },
  computed: {
    ...mapGetters([
      "currentUser", "agencyList", "agencyLoading", "areaList", "accountLoading", "accountList"
    ]),
  },
  mounted() {},
  methods: {
    getReportSummary() {
      store.dispatch(FETCH_REPORT_SUMMARY).then( res => {
        this.report = res
      }).catch( err => {
        store.commit(SET_ERROR, { msg: err.data })
      })
    },
  },
  directives: {}
}
</script>
