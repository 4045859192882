var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("新增代理商")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveAgency.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("代理商信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商名称*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "group"
    },
    model: {
      value: _vm.agencyItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "name", $$v);
      },
      expression: "agencyItem.name"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域*",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "rules": [_vm.rules.selected],
      "dense": "",
      "prepend-icon": "map"
    },
    model: {
      value: _vm.agencyItem.area,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "area", $$v);
      },
      expression: "agencyItem.area"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "内部负责人*",
      "hint": "请查找并选择公司内部负责人",
      "multiple": "",
      "prepend-icon": "emoji_people"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      }
    },
    model: {
      value: _vm.agencyItem.person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "person_in_charge", $$v);
      },
      expression: "agencyItem.person_in_charge"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商联系人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "connect_without_contact"
    },
    model: {
      value: _vm.agencyItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "contact_name", $$v);
      },
      expression: "agencyItem.contact_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "联系电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone"
    },
    model: {
      value: _vm.agencyItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "contact_phone", $$v);
      },
      expression: "agencyItem.contact_phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("发票信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-select', {
    attrs: {
      "items": _vm.invoiceType,
      "label": "发票类型*",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "rules": [_vm.rules.selected],
      "dense": "",
      "prepend-icon": "format_list_numbered_rtl"
    },
    model: {
      value: _vm.agencyItem.invoice_type,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "invoice_type", $$v);
      },
      expression: "agencyItem.invoice_type"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "发票费率*",
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "type": "number",
      "prepend-icon": "percent",
      "suffix": "%"
    },
    model: {
      value: _vm.agencyItem.invoice_rate,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "invoice_rate", $$v);
      },
      expression: "agencyItem.invoice_rate"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "抬头名称",
      "outlined": "",
      "dense": "",
      "prepend-icon": "apartment"
    },
    model: {
      value: _vm.agencyItem.invoice_info.title,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "title", $$v);
      },
      expression: "agencyItem.invoice_info.title"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "统一社会信用代码",
      "outlined": "",
      "dense": "",
      "prepend-icon": "pin"
    },
    model: {
      value: _vm.agencyItem.invoice_info.license_code,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "license_code", $$v);
      },
      expression: "agencyItem.invoice_info.license_code"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "开户行*",
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "prepend-icon": "account_balance"
    },
    model: {
      value: _vm.agencyItem.invoice_info.bank_name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "bank_name", $$v);
      },
      expression: "agencyItem.invoice_info.bank_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "银行账号*",
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "prepend-icon": "account_balance_wallet"
    },
    model: {
      value: _vm.agencyItem.invoice_info.bank_account,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "bank_account", $$v);
      },
      expression: "agencyItem.invoice_info.bank_account"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "企业地址",
      "outlined": "",
      "dense": "",
      "prepend-icon": "location_on"
    },
    model: {
      value: _vm.agencyItem.invoice_info.license_address,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "license_address", $$v);
      },
      expression: "agencyItem.invoice_info.license_address"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "企业电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "ring_volume"
    },
    model: {
      value: _vm.agencyItem.invoice_info.license_telephone,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "license_telephone", $$v);
      },
      expression: "agencyItem.invoice_info.license_telephone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "发票接收人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "person"
    },
    model: {
      value: _vm.agencyItem.invoice_info.delivery_name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "delivery_name", $$v);
      },
      expression: "agencyItem.invoice_info.delivery_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "接收人电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone_in_talk"
    },
    model: {
      value: _vm.agencyItem.invoice_info.delivery_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "delivery_phone", $$v);
      },
      expression: "agencyItem.invoice_info.delivery_phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "发票邮寄地址",
      "outlined": "",
      "dense": "",
      "prepend-icon": "home"
    },
    model: {
      value: _vm.agencyItem.invoice_info.delivery_address,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "delivery_address", $$v);
      },
      expression: "agencyItem.invoice_info.delivery_address"
    }
  })], 1), _c('v-col', [_c('v-subheader', [_vm._v("合同信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "合同类型*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "list"
    },
    model: {
      value: _vm.agencyItem.contracts[0].business_type,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.contracts[0], "business_type", $$v);
      },
      expression: "agencyItem.contracts[0].business_type"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "档案号",
      "outlined": "",
      "dense": "",
      "prepend-icon": "pin"
    },
    model: {
      value: _vm.agencyItem.contracts[0].file_no,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.contracts[0], "file_no", $$v);
      },
      expression: "agencyItem.contracts[0].file_no"
    }
  }), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "签约时间*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "fact_check"
          },
          model: {
            value: _vm.agencyItem.contracts[0].sign_date,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyItem.contracts[0], "sign_date", $$v);
            },
            expression: "agencyItem.contracts[0].sign_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.sign_date_menu,
      callback: function callback($$v) {
        _vm.sign_date_menu = $$v;
      },
      expression: "sign_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.sign_date_menu = false;
      }
    },
    model: {
      value: _vm.agencyItem.contracts[0].sign_date,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.contracts[0], "sign_date", $$v);
      },
      expression: "agencyItem.contracts[0].sign_date"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同开始日期*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available"
          },
          model: {
            value: _vm.agencyItem.contracts[0].contract_start,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyItem.contracts[0], "contract_start", $$v);
            },
            expression: "agencyItem.contracts[0].contract_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      }
    },
    model: {
      value: _vm.agencyItem.contracts[0].contract_start,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.contracts[0], "contract_start", $$v);
      },
      expression: "agencyItem.contracts[0].contract_start"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同结束日期*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_busy"
          },
          model: {
            value: _vm.agencyItem.contracts[0].contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyItem.contracts[0], "contract_end", $$v);
            },
            expression: "agencyItem.contracts[0].contract_end"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      }
    },
    model: {
      value: _vm.agencyItem.contracts[0].contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.contracts[0], "contract_end", $$v);
      },
      expression: "agencyItem.contracts[0].contract_end"
    }
  })], 1), _c('v-textarea', {
    attrs: {
      "label": "备注",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "no-resize": "",
      "prepend-icon": "comment"
    },
    model: {
      value: _vm.agencyItem.contracts[0].remark,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.contracts[0], "remark", $$v);
      },
      expression: "agencyItem.contracts[0].remark"
    }
  }), _c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-divider'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', [_vm._v("项目确认单")]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm._l(this.preUrl, function (n, i) {
    return _c('v-card', {
      key: i,
      staticClass: "ma-2",
      attrs: {
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var hover = _ref4.hover;
          return [n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'jpg' || n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'png' ? _c('v-img', {
            staticClass: "ma-2",
            attrs: {
              "src": n,
              "max-width": 125
            }
          }, [_c('v-fade-transition', [hover ? _c('v-overlay', {
            staticClass: "align-center",
            attrs: {
              "absolute": "",
              "color": "white lighten-5"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.clickUploadInput('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("backup")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImg('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("delete")])], 1)], 1) : _vm._e()], 1)], 1) : _c('v-sheet', {
            staticClass: "d-flex align-center px-2",
            attrs: {
              "color": "white",
              "height": "50",
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s(_vm.agencyItem.contracts[0].confirm_image[i].substring(_vm.agencyItem.contracts[0].confirm_image[i].lastIndexOf('/') + 1, _vm.agencyItem.contracts[0].confirm_image[i].length)) + " "), _c('v-fade-transition', [hover ? _c('v-overlay', {
            staticClass: "align-center",
            attrs: {
              "absolute": "",
              "color": "white lighten-5"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.clickUploadInput('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("backup")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImg('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("delete")])], 1)], 1) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('v-card', {
    staticClass: "ma-3 pa-6",
    attrs: {
      "outlined": "",
      "tile": "",
      "hover": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('confirm_img', -1);
      }
    }
  }, [_c('v-icon', [_vm._v("add")])], 1)], 2)], 1)], 1), _c('v-divider', {
    staticClass: "mt-6"
  }), _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-avatar', {
    attrs: {
      "rounded": "0",
      "size": "80"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("description")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "caption"
  }, [_c('div', [_vm._v("合同文件，支持图片、PDF、Word格式")]), _vm.agencyItem.contracts[0].file_name ? _c('div', [_vm._v("当前文件："), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.agencyItem.contracts[0].file_name))])]) : _vm._e()])], 1), _c('v-list-item-action', [_c('v-row', [_c('v-col', [_vm.agencyItem.contracts[0].file_name ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.removeImg('href');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1) : _vm._e()], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('href');
      }
    }
  }, [_c('v-icon', [_vm._v("upload")])], 1)], 1)], 1)], 1)], 1)], 1), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": ".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveAgency
    }
  }, [_vm._v(" 保存 ")]), _c('v-spacer')], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.Upload.uploading,
      callback: function callback($$v) {
        _vm.$set(_vm.Upload, "uploading", $$v);
      },
      expression: "Upload.uploading"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-card-title', [_vm._v("文件上传中...")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "color": "white",
      "value": _vm.Upload.progressUpload
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }