<template>
  <v-container>
    <workbench-shortcuts></workbench-shortcuts>
    <v-row class="pa-4">
      <v-col md="6" sm="12" v-for="layouts in cardOrder" :key="layouts.index">
        <v-col cols="12" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Case_Edit')">
          <v-card outlined>
            <div>
              <v-row align="center" justify="space-between">
                <v-col cols="12" md="6">
                  <v-card-title>工单管理</v-card-title>
                  <v-card-text>协同工作信息汇总</v-card-text>
                </v-col>
                <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mobile">
                  <v-img src="/static/card/workflow.svg" max-width="450px" height="135px" contain class="ma-2"></v-img>
                </v-col>
              </v-row>
              <template>
                <v-divider></v-divider>
                <v-list-item link @click="$router.push('/distributor/workbench/case?onlylist=1')">
                  <v-list-item-content>
                    <v-list-item-title>我发起的</v-list-item-title>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <v-list-item-content>
                    <v-list-item-title>{{ caseQuery.createCount }}</v-list-item-title>
                  </v-list-item-content>
                  <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link @click="$router.push('/distributor/workbench/case?onlylist=2')">
                  <v-list-item-content>
                    <v-list-item-title>我的待办</v-list-item-title>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <v-list-item-content>
                    <v-list-item-title>{{ caseQuery.remainCount }}</v-list-item-title>
                  </v-list-item-content>
                  <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                </v-list-item>
                <v-divider></v-divider>
              </template>
              <v-list-item>
<!--                <v-icon class="mr-2" small>schedule</v-icon>-->
<!--                <span class="caption font-weight-light">更新：1小时前</span>-->
<!--                <v-icon class="ml-2" small @click="">sync</v-icon>-->
                <v-spacer></v-spacer>
                <v-btn text plain @click="$router.push('/distributor/workbench/case')">查看全部({{ caseQuery.count }})<v-icon right>keyboard_arrow_right</v-icon></v-btn>
              </v-list-item>
            </div>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {
  FETCH_CASE_LIST,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
export default {
  data () {
    return {
      caseQuery: '',
      cardOrder: [
        { index: 'left', items: []},
        // { index: 'right', items: []},
      ],
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  created() {
    this.getCaseNum()
  },
  methods: {
    getCaseNum() {
      store.dispatch(FETCH_CASE_LIST, {query: "page=1&limit=1"}).then( res => {
        this.caseQuery = res.query
      }).catch( err => {
        store.commit(SET_ERROR, {msg: err.data})
      })
    }
  },
}
</script>
