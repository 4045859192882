<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>新增客户</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="saveCustomer">
          <v-row>
            <v-col cols="6">
              <v-subheader>客户信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-text-field
                v-model="customerItem.name"
                label="客户名称*"
                outlined
                :rules="[rules.required]"
                dense
                prepend-icon="person"
              ></v-text-field>
              <v-select
                v-model="customerItem.area"
                :items="areaList"
                label="所属区域*"
                :rules="[rules.selected]"
                item-text="name"
                item-value="_id"
                outlined
                dense
                prepend-icon="map"
              ></v-select>
              <v-select
                v-model="customerItem.electric_type"
                :items="electricType"
                label="用电类别*"
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                outlined
                dense
                prepend-icon="home_work"
              ></v-select>
<!--              <v-autocomplete-->
<!--                v-model="customerItem.agency"-->
<!--                :loading="agencyLoading"-->
<!--                :items="agencyList"-->
<!--                :search-input.sync="searchAgency"-->
<!--                item-text="name"-->
<!--                item-value="_id"-->
<!--                autocomplete="off"-->
<!--                outlined-->
<!--                dense-->
<!--                clearable-->
<!--                label="代理商名称"-->
<!--                prepend-icon="group"-->
<!--              ></v-autocomplete>-->
              <v-autocomplete
                v-model="customerItem.confirm_settle"
                :loading="accountLoading"
                :items="accountList"
                :search-input.sync="searchAccount"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                dense
                label="公司内部负责人*"
                :rules="[rules.selected]"
                hint="请查找并选择公司内部负责人"
                prepend-icon="emoji_people"
              ></v-autocomplete>
<!--              <v-select-->
<!--                v-model="customerItem.customer_managers"-->
<!--                :items="customer_managers"-->
<!--                label="公司内部负责人"-->
<!--                item-text="name"-->
<!--                item-value="code"-->
<!--                outlined-->
<!--                dense-->
<!--                prepend-icon="emoji_people"-->
<!--              ></v-select>-->
              <v-text-field
                v-model="customerItem.contact_name"
                label="客户联系人"
                outlined
                dense
                prepend-icon="connect_without_contact"
              ></v-text-field>
              <v-text-field
                v-model="customerItem.contact_phone"
                label="联系电话"
                outlined
                dense
                prepend-icon="phone"
              ></v-text-field>
              <v-text-field
                v-model="customerItem.address"
                label="公司地址"
                outlined
                dense
                prepend-icon="place"
              ></v-text-field>
              <v-text-field
                v-model="customerItem.total_capacity"
                type="number"
                label="设备总容量"
                outlined
                dense
                prepend-icon="settings_applications"
                suffix="兆瓦"
              ></v-text-field>
              <v-text-field
                v-model="customerItem.adjustable_capacity"
                type="number"
                label="设备可调容量"
                outlined
                dense
                prepend-icon="manage_history"
                suffix="兆瓦"
              ></v-text-field>
              <v-list subheader dense>
                <v-divider></v-divider>
                <v-list-item class="px-0">
                  <v-list-item-avatar rounded="0" size="80">
                    <v-icon v-if="!preUrl" x-large>image</v-icon>
                    <v-img v-else :src="preUrl?preUrl:''" width="80" height="80"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">
                      客户营业执照，支持JPG、PNG图片文件
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                      <v-col>
                        <v-btn v-if="preUrl" icon small @click="removeImg('license_image')">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn icon @click="clickUploadInput('license_image')" small>
                          <v-icon>upload</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
              <input id="files" type="file" name="file" ref="uploadInput" accept=".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream" :multiple="false"
                     @change="detectFiles($event)"/>
  <!--            <v-file-input-->
  <!--              v-model="customerItem.license_image"-->
  <!--              accept="image/*"-->
  <!--              label="客户营业执照"-->
  <!--              hint="支持JPG、PNG图片文件，图片大小不要超过1000KB"-->
  <!--              persistent-hint-->
  <!--              outlined-->
  <!--              dense-->
  <!--              prepend-icon="image"-->
  <!--              :append-icon="customerItem.license_image?'visibility':''"-->
  <!--            ></v-file-input>-->
            </v-col>
            <v-col cols="6">
              <v-subheader>合同信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-select
                v-model="customerItem.contracts[0].business_type"
                :items="userBusinessType"
                label="合同类型"
                item-text="name"
                item-value="code"
                outlined
                dense
                clearable
                prepend-icon="list"
              ></v-select>
              <v-text-field
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].contract_name"
                label="合同名称*"
                outlined
                :rules="[rules.required]"
                dense
                prepend-icon="gavel"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].file_no"
                label="档案号"
                outlined
                dense
                prepend-icon="pin"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].electric_use"
                label="年用电量*"
                outlined
                :rules="[rules.required]"
                dense
                type="number"
                suffix="兆瓦时"
                prepend-icon="bolt"
              ></v-text-field>
              <v-menu ref="sign_date" v-model="sign_date_menu"
                      v-if="customerItem.contracts[0].business_type"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field

                    v-model="customerItem.contracts[0].sign_date"
                    label="签约时间*"
                    readonly
                    outlined
                    :rules="[rules.required]"
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="fact_check"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="customerItem.contracts[0].sign_date" no-title scrollable @input="sign_date_menu = false">
                </v-date-picker>
              </v-menu>
              <v-menu ref="sign_date" v-model="contract_start_menu"
                      v-if="customerItem.contracts[0].business_type"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-if="customerItem.contracts[0].business_type"
                    v-model="customerItem.contracts[0].contract_start"
                    label="合同开始日期*"
                    readonly
                    outlined
                    :rules="[rules.required]"
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="event_available"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="customerItem.contracts[0].contract_start" no-title scrollable @input="contract_start_menu = false">
                </v-date-picker>
              </v-menu>
              <v-menu ref="sign_date" v-model="contract_end_menu"
                      v-if="customerItem.contracts[0].business_type"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-if="customerItem.contracts[0].business_type"
                    v-model="customerItem.contracts[0].contract_end"
                    label="合同结束日期*"
                    readonly
                    outlined
                    :rules="[rules.required]"
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="event_busy"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="customerItem.contracts[0].contract_end" no-title scrollable @input="contract_end_menu = false">
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].price"
                label="签约电价*"
                outlined
                :rules="[rules.required]"
                dense
                type="number"
                suffix="元/兆瓦时"
                prepend-icon="currency_yen"
              ></v-text-field>
              <v-select
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].sign_type"
                :items="signType"
                label="签约方式*"
                item-text="name"
                item-value="code"
                outlined
                :rules="[rules.selected]"
                dense
                prepend-icon="checklist"
              ></v-select>
              <v-text-field
                v-if="customerItem.contracts[0].business_type && customerItem.contracts[0].sign_type === 5"
                v-model="customerItem.contracts[0].sign_other"
                label="签约方式说明"
                outlined
                dense
                prepend-icon="assignment"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type && customerItem.contracts[0].sign_type === 1"
                v-model="customerItem.contracts[0].xt_ratio"
                label="鑫泰比例"
                outlined
                dense
                type="number"
                suffix="%"
                prepend-icon="group_add"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type && customerItem.contracts[0].sign_type === 1"
                v-model="customerItem.contracts[0].customer_ratio"
                label="客户比例"
                outlined
                dense
                type="number"
                suffix="%"
                prepend-icon="group_remove"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type && customerItem.contracts[0].sign_type === 3"
                v-model="customerItem.contracts[0].fixed_price"
                label="固定单价金额"
                outlined
                :rules="[rules.negative]"
                dense
                type="number"
                suffix="元/兆瓦时"
                prepend-icon="currency_yen"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type && customerItem.contracts[0].sign_type === 2"
                v-model="customerItem.contracts[0].fixed_fee"
                label="固定服务费金额"
                outlined
                :rules="[rules.negative]"
                dense
                type="number"
                suffix="元"
                prepend-icon="currency_yen"
              ></v-text-field>
              <v-text-field
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].bear_diff"
                label="承担偏差"
                outlined
                dense
                prepend-icon="running_with_errors"
              ></v-text-field>
              <v-textarea
                v-if="customerItem.contracts[0].business_type"
                v-model="customerItem.contracts[0].remark"
                label="备注"
                autocomplete="off"
                clearable
                outlined
                dense
                no-resize
                prepend-icon="comment"
              ></v-textarea>
              <v-list subheader dense v-if="customerItem.contracts[0].business_type">
                <v-divider></v-divider>
                <v-list-item class="px-0" bordered>
                  <v-list-item-avatar rounded="0" size="80">
                    <v-icon x-large>description</v-icon>
<!--                    <v-icon v-if="!imgUrl" x-large>image</v-icon>-->
<!--                    <v-img v-else :src="imgUrl?imgUrl:''" width="80" height="80"></v-img>-->
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="caption">
                      <div>合同文件，支持图片、PDF、Word格式及压缩包</div>
                      <div v-if="customerItem.contracts[0].file_name">当前文件：<span class="primary--text">{{ customerItem.contracts[0].file_name }}</span></div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row>
                      <v-col>
                        <v-btn v-if="customerItem.contracts[0].file_name" icon small @click="removeImg('href')">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn icon @click="clickUploadInput('href')" small>
                          <v-icon>upload</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="saveCustomer" :disabled="!valid">
          保存
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="Upload.uploading" persistent max-width="550px">
      <v-card color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-progress-linear
                color="white"
                :value="Upload.progressUpload"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import {
  FETCH_UPLOAD,
  CREATE_CUSTOMER,
  CREATE_CONTRACT,
  FETCH_URL,
  FETCH_AREA_LIST,
  FETCH_ACCOUNT_LIST,
} from "@/store/actions.type";
import Util from "@/common/util";

export default {
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        below100: (val) => val <= 100 || "不能大于100",
        negative: (val) => val >= 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      customerItem: {
        name: '',
        area: '',
        electric_type: '',
        confirm_settle: '',
        contact_name: '',
        contact_phone: '',
        address: '',
        total_capacity: '',
        adjustable_capacity: '',
        license_image: '',
        contracts: [
          {
            business_type: '',
            contract_name: '',
            file_no: '',
            electric_use: '',
            sign_date: '',
            contract_start: '',
            contract_end: '',
            price: '',
            sign_type: '',
            sign_other: '',
            xt_ratio: '',
            customer_ratio:'',
            fixed_price: '',
            fixed_fee: '',
            bear_diff: '',
            href: '',
            file_name: '',
            remark: '',
            attachment_image:[],
          }
        ]
      },
      sign_date_menu: false,
      contract_start_menu: false,
      contract_end_menu: false,
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fileName: '请上传文件',
      preUrl: '',
      imgUrl: '',
      searchAccount: null,
      uploadType: '',
    }
  },
  watch: {
    searchAccount (val) {
      val && val !== this.customerItem.confirm_settle && this.searchEmployee(val)
    },
    // searchAgency (val) {
    //   val && val !== this.customerItem.agency && this.getAgencyList(val)
    // },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true')
    ]).then(() => {
      next();
    });
  },
  created() {
    store.dispatch(FETCH_ACCOUNT_LIST)
    // store.dispatch(FETCH_AGENCY_LIST, {query: ''})
  },
  computed: {
    ...mapGetters(["currentUser", "userBusinessType", "distributor", "uploadSign", "areaList", "accountLoading", "accountList"]),
    electricType () {
      return Util.categories('electricType')
    },
    businessType () {
      return Util.categories('businessType')
    },
    signType () {
      return Util.categories('signType')
    },
  },
  mounted() {},
  methods: {
    setRatio() {
      if(this.customerItem.contracts[0].xt_ratio === '') {
        this.customerItem.contracts[0].customer_ratio = ''
      }else {
        let num = 100 - this.customerItem.contracts[0].xt_ratio
        if (num < 0 || this.customerItem.contracts[0].xt_ratio < 0) {
          this.customerItem.contracts[0].customer_ratio = 0
        } else {
          this.customerItem.contracts[0].customer_ratio = num
        }
      }
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    // getAgencyList (key) {
    //   store.dispatch(FETCH_AGENCY_LIST, {
    //     query:
    //       "page=1" +
    //       "&limit=50" +
    //       "&key=" + key
    //   })
    // },
    resetData() {
      this.customerItem.total_capacity = Number(this.customerItem.total_capacity)
      this.customerItem.adjustable_capacity = Number(this.customerItem.adjustable_capacity)
      this.customerItem.contracts[0].customer_ratio = Number(this.customerItem.contracts[0].customer_ratio)
      this.customerItem.contracts[0].xt_ratio = Number(this.customerItem.contracts[0].xt_ratio)
      this.customerItem.contracts[0].fixed_price = Number(this.customerItem.contracts[0].fixed_price)
      this.customerItem.contracts[0].fixed_fee = Number(this.customerItem.contracts[0].fixed_fee)
      this.customerItem.contracts[0].electric_use = Number(this.customerItem.contracts[0].electric_use)
      this.customerItem.contracts[0].price = Number(this.customerItem.contracts[0].price)
      this.customerItem.contracts[0].contract_type = 1
      return this.customerItem
    },
    saveCustomer() {
      store.dispatch(CREATE_CUSTOMER, this.resetData())
        .then((res) => {
          if (this.customerItem.contracts[0].business_type) {
            this.customerItem.contracts[0].customer = res._id
            store.dispatch(CREATE_CONTRACT, this.customerItem.contracts[0]).then(() => {
              store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.$router.push({path: '/distributor/customer/detail/' + res._id});
            }).catch((err) => {
              store.commit(SET_ERROR, {msg: '客户创建成功，合同创建失败；' + err.data, color: 'warning'});
              this.$router.push({path: '/distributor/customer/detail/' + res._id});
            })
          }else {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.$router.push({path: '/distributor/customer/detail/' + res._id});
          }
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.data});
        });
    },
    clickUploadInput(type) {
      this.uploadType = type
      store.dispatch(FETCH_UPLOAD, 'private')
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name.replace(/[%#&]/g,"");
      this.Upload.uploading = true;
      let param = new FormData();
      let menu = ''
      if (this.uploadType === 'license_image') {
        menu = 'customer/license/'
      } else if (this.uploadType === 'href') {
        menu = 'contract/customer/agreement/'
      }
      param.append('name', file.name);
      param.append('key',  menu + this.uploadSign.key + '/' + this.fileName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          if (this.uploadType === 'license_image') {
            this.customerItem.license_image = param.get('key')
            store.dispatch(FETCH_URL, this.customerItem.license_image).then((fileUrl) => {
              this.preUrl = fileUrl;
              this.$forceUpdate();
            });
          } else if (this.uploadType === 'href') {
            this.customerItem.contracts[0].href = param.get('key')
            this.customerItem.contracts[0].file_name = param.get('name')
            // 20240111先能保证一个上传
            // this.customerItem.contracts[0].attachment_image.push(param.get('key'))
            this.customerItem.contracts[0].attachment_image =[param.get('key')]
            console.log(this.customerItem.contracts)
            store.dispatch(FETCH_URL, this.customerItem.contracts[0].href).then((fileUrl) => {
              this.imgUrl = fileUrl;
              this.$forceUpdate();
            });
          }
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    removeImg(type) {
      if (type === 'license_image') {
        this.preUrl = ''
        this.customerItem.license_image = ''
      } else if (type === 'href') {
        this.imgUrl = ''
        this.customerItem.contracts[0].href = ''
        this.customerItem.contracts[0].file_name = ''
        this.customerItem.contracts[0].attachment_image = []
      }
    },
  },
  directives: {}
}
</script>

<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.hover-btn {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}
</style>
