var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.userBusinessType.find(function (item) {
    return item.code === Number(_vm.query.group);
  }).name) + "工单列表")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
      "label": "编号、标题搜索（按回车）",
      "prepend-inner-icon": "search",
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.setFilter();
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('widgets-watermark', {
    attrs: {
      "watermarkText": _vm.wmText
    }
  }), _c('v-data-table', {
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.caseList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.caseCount,
      "loading": _vm.caseLoading,
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "height": "600",
      "fixed-header": "",
      "transition": "",
      "loading-text": "数据读取中... 请稍后",
      "item-key": "_id",
      "return-object": "false"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-tabs', {
          staticClass: "mt-2 mb-4",
          attrs: {
            "align-with-title": ""
          },
          on: {
            "change": _vm.searchList
          },
          model: {
            value: _vm.query.onlylist,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "onlylist", $$v);
            },
            expression: "query.onlylist"
          }
        }, [_c('v-tabs-slider', {
          attrs: {
            "color": "secondary"
          }
        }), _c('v-tab', [_vm._v("全部工单")]), _c('v-tab', [_vm._v("我发起的")]), _c('v-tab', [_vm.remainCount ? _c('v-badge', {
          attrs: {
            "color": "primary",
            "content": _vm.remainCount
          }
        }, [_vm._v(" 我的待办 ")]) : _c('span', [_vm._v("我的待办")])], 1), _c('v-tab', [_vm._v(" 我处理的 ")])], 1), _c('v-spacer'), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 2,
            "tableName": "caseHeader"
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.cancelHeader();
            },
            "Default": function Default($event) {
              return _vm.restoreHeader();
            },
            "Save": _vm.setHeader
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.filterItem.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.reloadItem.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("autorenew")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("刷新列表")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.case_number",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('router-link', {
          attrs: {
            "to": '/distributor/workbench/case/detail/' + item._id
          }
        }, [_vm._v(_vm._s(item.case_number))])], 1)];
      }
    }, {
      key: "item.settleTitle",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('router-link', {
          attrs: {
            "to": '/distributor/workbench/case/detail/' + item._id
          }
        }, [_vm._v(_vm._s(item.settleTitle ? item.settleTitle.substring(item.settleTitle.indexOf('20'), item.settleTitle.indexOf('代理商')) : '-'))])], 1)];
      }
    }, {
      key: "item.agency",
      fn: function fn(_ref5) {
        var _item$agency, _item$agency2;
        var item = _ref5.item;
        return [item.agency ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('router-link', {
          attrs: {
            "to": '/distributor/agency/detail/' + ((_item$agency = item.agency) === null || _item$agency === void 0 ? void 0 : _item$agency._id)
          }
        }, [_vm._v(_vm._s((_item$agency2 = item.agency) === null || _item$agency2 === void 0 ? void 0 : _item$agency2.name))])], 1) : _vm._e()];
      }
    }, {
      key: "item.area",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.area ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.area.name))]) : _vm._e()];
      }
    }, {
      key: "item.current_operator",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.current_operator && item.current_operator.length ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" " + _vm._s(item.current_operator.map(function (o) {
          var _o$personal;
          return o === null || o === void 0 ? void 0 : (_o$personal = o.personal) === null || _o$personal === void 0 ? void 0 : _o$personal.name;
        })) + " ")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        })];
      }
    }, {
      key: "item.group",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.group === 1 ? _c('td', [_vm._v("能源")]) : item.group === 2 ? _c('td', [_vm._v("调峰")]) : item.group === 3 ? _c('td', [_vm._v("售电")]) : item.group === 4 ? _c('td', [_vm._v("智科")]) : _vm._e()];
      }
    }, {
      key: "item.settle_price_total",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.settle_price_total || item.settle_price_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "end"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.settle_price_total, '', '')))]) : _vm._e()];
      }
    }, {
      key: "item.current_task",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.current_task ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.current_task.name))]) : _vm._e()];
      }
    }, {
      key: "item.print_receipt_count",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.receiptPrint && item.receiptPrint.length ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-menu', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref12) {
              var _item$receiptPrint;
              var on = _ref12.on,
                attrs = _ref12.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$receiptPrint = item.receiptPrint) === null || _item$receiptPrint === void 0 ? void 0 : _item$receiptPrint.length) + " ")]), _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "x-small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(item.receiptPrint, function (log, index) {
          var _log$optAccount, _log$optAccount$perso;
          return _c('v-list-item', {
            key: item._id + '_print_' + index
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(log === null || log === void 0 ? void 0 : (_log$optAccount = log.optAccount) === null || _log$optAccount === void 0 ? void 0 : (_log$optAccount$perso = _log$optAccount.personal) === null || _log$optAccount$perso === void 0 ? void 0 : _log$optAccount$perso.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm._f("formatTime")(log.optTime)))])], 1)], 1);
        }), 1)], 1)], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("0")])];
      }
    }, {
      key: "item.download_receipt_count",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.receiptDownload && item.receiptDownload.length ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-menu', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref14) {
              var _item$receiptDownload;
              var on = _ref14.on,
                attrs = _ref14.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$receiptDownload = item.receiptDownload) === null || _item$receiptDownload === void 0 ? void 0 : _item$receiptDownload.length) + " ")]), _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "x-small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(item.receiptDownload, function (log, index) {
          var _log$optAccount2, _log$optAccount2$pers;
          return _c('v-list-item', {
            key: item._id + '_print_' + index
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(log === null || log === void 0 ? void 0 : (_log$optAccount2 = log.optAccount) === null || _log$optAccount2 === void 0 ? void 0 : (_log$optAccount2$pers = _log$optAccount2.personal) === null || _log$optAccount2$pers === void 0 ? void 0 : _log$optAccount2$pers.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm._f("formatTime")(log.optTime)))])], 1)], 1);
        }), 1)], 1)], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("0")])];
      }
    }, {
      key: "item.download_summary_count",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.summaryDownload && item.summaryDownload.length ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-menu', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref16) {
              var _item$summaryDownload;
              var on = _ref16.on,
                attrs = _ref16.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$summaryDownload = item.summaryDownload) === null || _item$summaryDownload === void 0 ? void 0 : _item$summaryDownload.length) + " ")]), _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "x-small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(item.summaryDownload, function (log, index) {
          var _log$optAccount3, _log$optAccount3$pers;
          return _c('v-list-item', {
            key: item._id + '_print_' + index
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(log === null || log === void 0 ? void 0 : (_log$optAccount3 = log.optAccount) === null || _log$optAccount3 === void 0 ? void 0 : (_log$optAccount3$pers = _log$optAccount3.personal) === null || _log$optAccount3$pers === void 0 ? void 0 : _log$optAccount3$pers.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm._f("formatTime")(log.optTime)))])], 1)], 1);
        }), 1)], 1)], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("0")])];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.creator ? _c('td', [_vm._v(_vm._s(item.creator.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("进行中")]) : item.status === 2 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "error"
          }
        }, [_vm._v("已取消")]) : item.status === 4 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "success"
          }
        }, [_vm._v("已完成")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")])], 1)];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('td', [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/distributor/workbench/case/detail/' + item._id);
            }
          }
        }, [_vm._v("content_paste_search")])], 1)];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": _vm.clearFilter
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "业务类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.group,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "group", _vm._n($$v));
      },
      expression: "query.group"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": "",
      "clearable": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.area,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "area", $$v);
      },
      expression: "query.area"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "申请人"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.producedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "producedBy", $$v);
      },
      expression: "query.producedBy"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "loading": _vm.agencyLoading,
      "items": _vm.agencyList,
      "search-input": _vm.searchAgency,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "代理商名称"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.agency,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "agency", $$v);
      },
      expression: "query.agency"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("工单状态")]), _vm.caseStatus.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.caseStatus, function (item) {
    return _c('v-chip', {
      key: item.code,
      attrs: {
        "value": item.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("当前任务")]), _vm.taskList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.task,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "task", $$v);
      },
      expression: "query.task"
    }
  }, _vm._l(_vm.taskList, function (item) {
    return _c('v-chip', {
      key: item._id,
      attrs: {
        "value": item._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载新增模版，填写客户信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_create_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importCreateFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.batch.import_end
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_create_file');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.batch.import_failed.length || _vm.batch.import_result.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.batch.import_failed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.batch.import_failed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.batch.import_result.length) + " 条记录； "), _vm.batch.import_result.length ? _c('v-row', [_c('v-col', {
    staticClass: "grow"
  }, _vm._l(_vm.batch.import_result, function (item) {
    return _c('p', {
      key: item._id,
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "800px"
    },
    model: {
      value: _vm.imgDlg,
      callback: function callback($$v) {
        _vm.imgDlg = $$v;
      },
      expression: "imgDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 客户营业执照 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.imgUrl,
      "contain": ""
    }
  }), _c('v-overlay', {
    staticClass: "align-center justify-center",
    attrs: {
      "absolute": ""
    },
    model: {
      value: _vm.overlay,
      callback: function callback($$v) {
        _vm.overlay = $$v;
      },
      expression: "overlay"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除客户 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除此客户吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteCustomer
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1), _c('v-menu', {
    attrs: {
      "position-x": _vm.mouseX,
      "position-y": _vm.mouseY,
      "absolute": "",
      "offset-y": ""
    },
    model: {
      value: _vm.rightMenu,
      callback: function callback($$v) {
        _vm.rightMenu = $$v;
      },
      expression: "rightMenu"
    }
  }, [_c('v-list', [_c('v-list-item', [_c('v-list-item-title', [_vm._v("工作愉快！")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }