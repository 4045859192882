var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('workbench-shortcuts'), _c('v-row', {
    staticClass: "pa-4"
  }, _vm._l(_vm.cardOrder, function (layouts) {
    return _c('v-col', {
      key: layouts.index,
      attrs: {
        "md": "6",
        "sm": "12"
      }
    }, [_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Case_Edit') ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": ""
      }
    }, [_c('div', [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card-title', [_vm._v("工单管理")]), _c('v-card-text', [_vm._v("协同工作信息汇总")])], 1), !_vm.$vuetify.breakpoint.mobile ? _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-img', {
      staticClass: "ma-2",
      attrs: {
        "src": "/static/card/workflow.svg",
        "max-width": "450px",
        "height": "135px",
        "contain": ""
      }
    })], 1) : _vm._e()], 1), [_c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push('/distributor/workbench/case?onlylist=1');
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("我发起的")])], 1), _c('v-spacer'), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.caseQuery.createCount))])], 1), _c('v-icon', {
      staticClass: "subheading font-weight-light"
    }, [_vm._v("arrow_forward")])], 1), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push('/distributor/workbench/case?onlylist=2');
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("我的待办")])], 1), _c('v-spacer'), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.caseQuery.remainCount))])], 1), _c('v-icon', {
      staticClass: "subheading font-weight-light"
    }, [_vm._v("arrow_forward")])], 1), _c('v-divider')], _c('v-list-item', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "plain": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push('/distributor/workbench/case');
        }
      }
    }, [_vm._v("查看全部(" + _vm._s(_vm.caseQuery.count) + ")"), _c('v-icon', {
      attrs: {
        "right": ""
      }
    }, [_vm._v("keyboard_arrow_right")])], 1)], 1)], 2)])], 1) : _vm._e()], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }