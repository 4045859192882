<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.push('/distributor/customer/detail')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>新增合同</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="customerItem.contract_type"
              :items="contractType"
              label="合同类型"
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              outlined
              dense
              prepend-icon="list"
            ></v-select>
            <v-text-field
              v-model="customerItem.contract_name"
              label="合同名称"
              outlined
              :rules="[rules.required]"
              dense
              prepend-icon="gavel"
            ></v-text-field>
            <v-text-field
              v-model="customerItem.electric_use"
              label="年用电量"
              outlined
              :rules="[rules.required]"
              dense
              suffix="兆瓦/时"
              prepend-icon="bolt"
            ></v-text-field>
            <v-menu ref="sign_date" v-model="sign_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customerItem.sign_date"
                  label="签约时间"
                  readonly
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="fact_check"
                ></v-text-field>
              </template>
              <v-date-picker v-model="customerItem.sign_date" no-title scrollable @input="sign_date_menu = false">
              </v-date-picker>
            </v-menu>
            <v-menu ref="sign_date" v-model="contract_start_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customerItem.contract_start"
                  label="合同开始日期"
                  readonly
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="event_available"
                ></v-text-field>
              </template>
              <v-date-picker v-model="customerItem.contract_start" no-title scrollable @input="contract_start_menu = false">
              </v-date-picker>
            </v-menu>
            <v-menu ref="sign_date" v-model="contract_end_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customerItem.contract_end"
                  label="合同结束日期"
                  readonly
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="event_busy"
                ></v-text-field>
              </template>
              <v-date-picker v-model="customerItem.contract_end" no-title scrollable @input="contract_end_menu = false">
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="customerItem.price"
              label="签约电价"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
              prepend-icon="currency_yen"
            ></v-text-field>
            <v-select
              v-model="customerItem.sign_type"
              :items="signType"
              label="签约方式"
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              outlined
              dense
              prepend-icon="checklist"
            ></v-select>
            <v-text-field
              v-if="customerItem.sign_type === 1"
              v-model="customerItem.xt_ratio"
              label="鑫泰比例"
              outlined
              :rules="[rules.required]"
              dense
              suffix="%"
              prepend-icon="group_add"
            ></v-text-field>
            <v-text-field
              v-if="customerItem.sign_type === 1"
              v-model="customerItem.supplier_ratio"
              label="客户比例"
              outlined
              :rules="[rules.required]"
              dense
              suffix="%"
              prepend-icon="group_remove"
            ></v-text-field>
            <v-text-field
              v-if="customerItem.sign_type === 2"
              v-model="customerItem.fix_price"
              label="固定单价"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元/兆瓦/时"
              prepend-icon="currency_yen"
            ></v-text-field>
            <v-text-field
              v-if="customerItem.sign_type === 3"
              v-model="customerItem.fix_service"
              label="固定服务费"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
              prepend-icon="currency_yen"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed>
          保存
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      customerItem: {
        contract_type: '',
        contract_name: '',
        electric_use: '',
        sign_date: '',
        contract_start: '',
        contract_end: '',
        price: '',
        sign_type: '',
        xt_ratio: '',
        supplier_ratio:'',
        fix_price: '',
        fix_service: '',
      },
      contractType: [
        {
          name: '售电',
          code: 1,
        },
        {
          name: '能源',
          code: 2,
        },
        {
          name: '调峰',
          code: 3,
        },
      ],
      signType: [
        {
          name: '比例分成',
          code: 1,
        },
        {
          name: '固定单价',
          code: 2,
        },
        {
          name: '固定服务费',
          code: 3,
        },
      ],
      sign_date_menu: false,
      contract_start_menu: false,
      contract_end_menu: false,
    }
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
  directives: {}
}
</script>

<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
