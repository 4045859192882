import { render, staticRenderFns } from "./agency.vue?vue&type=template&id=70093f5d&scoped=true&"
import script from "./agency.vue?vue&type=script&lang=js&"
export * from "./agency.vue?vue&type=script&lang=js&"
import style0 from "./agency.vue?vue&type=style&index=0&id=70093f5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70093f5d",
  null
  
)

export default component.exports