<template>
  <v-container fluid class="d-print-none">
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>{{ caseInfo.case_number }} 工单详情</span>
          <v-chip
            class="ml-2"
            color="primary"
            label
            outlined
            small
          >
            {{ caseInfo.group === 1 ? '能源' : caseInfo.group === 2 ? '调峰' : caseInfo.group === 3 ? '售电' : '智科' }}
          </v-chip>
          <v-chip
            class="ml-2"
            :color="caseInfo.status === 1?'success':'grey'"
            label
            outlined
            small
          >
            {{ caseInfo.status === 1 ? '进行中' : caseInfo.status === 2 ? '已取消' : '已完成' }}
          </v-chip>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">流程信息</span>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row>
          <v-col align="center">
            <v-stepper
              v-if="steps"
              v-model="steps"
              alt-labels
              class="mt-12 elevation-0 phaseStepper"
              style="max-width: 980px"
            >
              <div class="step-con">
                <v-stepper-header>
                  <template v-for="(phase, i) in tasks_list">
                    <v-stepper-step
                      :step="phase.order + 1"
                      :key="i"
                      :color="phase.isActive && phase.status === 1 ? 'primary' : phase.status === 2 ? 'success' : phase.status === 3 ? 'error' : 'grey'"
                      :complete="phase.status > 1"
                      :complete-icon="phase.status === 2 ? 'done' : 'close'"
                    >
                      <span v-if="phase.isActive">
                        <span v-if="phase.status === 0">{{ phase.task.name }}</span>
                        <span v-if="phase.status === 1">{{ phase.task.doingName || phase.task.name }}</span>
                        <span v-if="phase.status === 2">{{ phase.task.doneName || phase.task.name }}</span>
                        <span v-if="phase.status === 3">{{ phase.task.cancelName || phase.task.name }}</span>
                      </span>
                      <span v-if="!phase.isActive">
                        <span v-if="phase.status === 0">{{ phase.task.name }}</span>
                        <span v-if="phase.status === 1">{{ phase.task.doingName }}</span>
                        <span v-if="phase.status === 2">{{ phase.task.doneName || phase.task.name }}</span>
                        <span v-if="phase.status === 3">{{ phase.task.cancelName || phase.task.name }}</span>
                      </span>
                    </v-stepper-step>
                    <v-divider
                      v-if="i < tasks_list.length - 1"
                      class="mt-13 mx-n14"
                      :key="i + 999"
                    ></v-divider>
                  </template>
                </v-stepper-header>
              </div>
            </v-stepper>
          </v-col>
        </v-row>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">结算信息</span>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="pl-8 my-6">
          <v-col cols="3">
            <span class="text-subtitle-2">代理商名称：</span>
            <span>{{ caseInfo.agency?.name }}</span>
          </v-col>
          <v-col cols="3">
            <span class="text-subtitle-2">创建日期：</span>
            <span v-if="caseInfo.createTime">{{ caseInfo.createTime | formatTime }}</span>
          </v-col>
          <v-col cols="3">
            <span class="text-subtitle-2">结算客户数量：</span>
            <span>{{ customers.length }}个</span>
          </v-col>
          <v-col cols="3">
            <span class="text-subtitle-2">所属区域：</span>
            <span v-if="caseInfo.settleList">{{ caseInfo.settleList[0]?.area?.name }}</span>
          </v-col>
          <v-col cols="3" v-if="caseInfo.group === 1 || caseInfo.group === 3 || caseInfo.group === 4">
            <span class="text-subtitle-2">结算明细单：</span>
            <span>
              <case-receipt-detailed :getType="caseInfo.group" :settleData="caseInfo">
                <v-btn text color="primary" small>查看</v-btn>
              </case-receipt-detailed>
            </span>
          </v-col>
          <v-col cols="3" v-if="caseInfo.group !== 4">
            <span class="text-subtitle-2">结算汇总单：</span>
            <span>
              <case-receipt-summary :getType="caseInfo.group" :settleData="caseInfo" :getId="caseInfo._id" @reloadCase="reloadCase">
                <v-btn text color="primary" small>查看</v-btn>
              </case-receipt-summary>
            </span>
          </v-col>
          <v-col cols="3" v-if="steps > 3 && (currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Case_Edit_Receipt'))">
            <span class="text-subtitle-2">付款审批单：</span>
            <span>
              <case-receipt-approve :getType="caseInfo.group" :settleData="caseInfo" :getId="caseInfo._id">
                <v-btn text color="primary" small>查看</v-btn>
              </case-receipt-approve>
            </span>
          </v-col>
        </v-row>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">附加信息</span>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card flat>
          <v-card-text class="px-4">
            <v-tabs>
              <v-tab v-if="caseInfo.group === 1 || caseInfo.group === 3">数据</v-tab>
              <v-tab>附件</v-tab>
              <v-tab>备注</v-tab>
              <v-tab-item v-if="caseInfo.group === 1 || caseInfo.group === 3">
                <case-settle-list :caseInfo="caseInfo"></case-settle-list>
              </v-tab-item>
              <v-tab-item>
                <widgets-files-list entityType="CaseSettle" :entityId="caseId" :type=1></widgets-files-list>
              </v-tab-item>
              <v-tab-item>
                <widgets-remarks-list entityType="CaseSettle" :entityId="caseId" :type=0></widgets-remarks-list>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">历史记录</span>
          <v-spacer></v-spacer>
        </v-toolbar>
        <case-action-logs v-if="caseInfo.count_tasks" :caseData="caseInfo"></case-action-logs>
      </v-card-text>
      <v-divider v-if="caseInfo.status === 1 && (caseInfo.isSelf || caseInfo.isEdit)"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed v-if="caseInfo.status === 1 && (caseInfo.isSelf || caseInfo.isEdit || currentUser.hasAdmin.includes('distributor'))" @click="doAction('reject_case')">终止工单</v-btn>
        <v-btn color="primary" depressed v-if="caseInfo.status === 1 && caseInfo.isEdit" @click="doAction('submit_case')">{{steps === 4 ? '付款完成': '通过'}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          {{ warnTitle }}
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_reject')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          {{ warnText }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_reject')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="actionType === 'reject_case'" text color="primary" @click="doAction('confirm_reject')">确认</v-btn>
          <v-btn v-if="actionType === 'submit_case'" text color="primary" @click="doAction('confirm_submit')">确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { FETCH_CASE, REJECT_CASE, SUBMIT_CASE } from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      caseId: '',
      caseInfo: {},
      warnDlg: false,
      warnTitle: '',
      warnText: '',
      actionType: '',
      steps: '',
      intCurrentStep: 33.33,
      tasks_list: [],
      customers: [],
    };
  },
  created() {},
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  mounted() {
    if (this.$route.params.id) {
      this.caseId = this.$route.params.id
      this.getCase(this.caseId)
    }
  },
  methods: {
    isEdge() {
      return isEdge
    },
    reloadCase() {
      this.getCase(this.caseId)
    },
    getCase(id) {
      this.$store.dispatch(FETCH_CASE, {query: id}).then((res) => {
        this.caseInfo = res
        this.tasks_list = this.caseInfo.tasks_list
        if (this.caseInfo.status === 4) {
          this.steps = 4
        } else {
          this.steps = this.tasks_list.find( i => i.isActive).order + 1
        }
        let arr = []
        this.caseInfo.settleList.map(item => {
          arr.push(item.customer.name)
        })
        this.customers = [...new Set(arr)]
        if (this.caseInfo.group === 3 && this.caseInfo.settleSumData) {
          Object.keys(this.caseInfo.settleSumData).map(item => {
            if (this.caseInfo.settleSumData[item].$numberDecimal) {
              if (item === 'settle_elec_total') {
                this.caseInfo.settleSumData[item] = Number(this.caseInfo.settleSumData[item].$numberDecimal).toFixed(3)
              } else {
                this.caseInfo.settleSumData[item] = Number(this.caseInfo.settleSumData[item].$numberDecimal).toFixed(2)
              }
            }
          })
        }
        this.$forceUpdate()
      })
    },
    doAction(action) {
      switch (action) {
        case 'close_reject': {
          this.warnDlg = false
          this.warnTitle = ''
          this.warnText = ''
          this.actionType = ''
          break
        }
        case 'reject_case': {
          this.actionType = 'reject_case'
          this.warnTitle = '终止工单'
          this.warnText = '确定要终止当前工单吗？'
          this.warnDlg = true
          break
        }
        case 'confirm_reject': {
          this.$store.dispatch(REJECT_CASE, this.caseId).then(() => {
            this.getCase(this.caseId)
            this.warnDlg = false
            this.warnTitle = ''
            this.warnText = ''
            this.$store.commit(SET_ERROR, { msg: "取消工单成功！", color: "primary" })
            this.$forceUpdate()
          }).catch((err) => {
            this.warnDlg = false
            this.warnTitle = ''
            this.warnText = ''
            this.$store.commit(SET_ERROR, { msg: err.message })
          })
          break
        }
        case 'submit_case': {
          this.actionType = 'submit_case'
          this.warnTitle = this.caseInfo.current_task?.doingName
          this.warnText = '确定要审批通过当前任务吗？'
          this.warnDlg = true
          break
        }
        case 'confirm_submit': {
          this.$store.dispatch(SUBMIT_CASE, this.caseId).then(() => {
            this.getCase(this.caseId)
            this.warnDlg = false
            this.warnTitle = ''
            this.warnText = ''
            this.actionType = ''
            this.$store.commit(SET_ERROR, { msg: "审批通过成功！", color: "primary" })
            this.$forceUpdate()
          }).catch((err) => {
            this.warnDlg = false
            this.warnTitle = ''
            this.warnText = ''
            this.actionType = ''
            this.$store.commit(SET_ERROR, { msg: err.message })
          })
          break
        }
      }
    },
  },
  directives: {},
};
</script>

<style scoped>
.phaseStepper ::v-deep .v-stepper__step__step {
  width: 52px;
  height: 52px;
}
.phaseStepper ::v-deep .v-stepper__step--editable:hover {
  background-color: transparent;
}
.step-con {
  position: relative;
}
.panelTitle {
  padding-left: 14px;
  border-left: 2px solid #1976D2;
}
</style>
