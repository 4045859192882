var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("其他合同管理")]), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "depressed": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("add")]), _vm._v(" 新增合同 ")], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.doAction('input_create');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1), _c('v-list-item-title', [_vm._v("手动填写")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.doAction('batch_create');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("批量导入")])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "合同名称",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchList
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "公司内部负责人",
      "multiple": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "person_in_charge", $$v);
      },
      expression: "query.person_in_charge"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.otherBusinessType,
      "label": "合同类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.business_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "business_type", $$v);
      },
      expression: "query.business_type"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('widgets-watermark', {
    attrs: {
      "watermarkText": _vm.wmText
    }
  }), _c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.defaultHeader,
      "items": _vm.contractList,
      "server-items-length": _vm.contractCount,
      "loading": _vm.contractLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": "",
      "height": "600"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.no",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          staticClass: "text-center",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.no))])];
      }
    }, {
      key: "item.file_no",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.file_no || ''))])];
      }
    }, {
      key: "item.area",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.area ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.area.name))]) : _vm._e()];
      }
    }, {
      key: "item.contract_name",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.contract_name))])];
      }
    }, {
      key: "item.business_type",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [item.business_type === 3 ? _c('span', [_vm._v("售电业务")]) : _vm._e(), item.business_type === 4 ? _c('span', [_vm._v("配电室合同（智科）")]) : _vm._e(), item.business_type === 8 ? _c('span', [_vm._v("入市协议")]) : _vm._e(), item.business_type === 9 ? _c('span', [_vm._v("电厂合同")]) : _vm._e(), item.business_type === 5 ? _c('span', [_vm._v("采购合同")]) : _vm._e(), item.business_type === 2 ? _c('span', [_vm._v("辅助服务（调峰）")]) : _vm._e(), item.business_type === 10 ? _c('span', [_vm._v("煤炭业务")]) : _vm._e()])];
      }
    }, {
      key: "item.dept",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.dept === 'YY' ? _c('span', [_vm._v("发展运营部")]) : _vm._e(), item.dept === 'CW' ? _c('span', [_vm._v("财务部")]) : _vm._e(), item.dept === 'PD' ? _c('span', [_vm._v("鑫泰智科")]) : _vm._e(), item.dept === 'NY' ? _c('span', [_vm._v("能源管理部")]) : _vm._e(), item.dept === 'YX' ? _c('span', [_vm._v("营销部")]) : _vm._e(), item.dept === 'SC' ? _c('span', [_vm._v("市场部")]) : _vm._e()];
      }
    }, {
      key: "item.contract_object",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.contract_object ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.contract_object))]) : _vm._e()];
      }
    }, {
      key: "item.contract_amount",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.contract_amount)))])];
      }
    }, {
      key: "item.person_in_charge",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          staticClass: "text-center",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.person_in_charge.map(function (item) {
          return item.personal.name;
        })))])];
      }
    }, {
      key: "item.contract_start",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatDate")(item.contract_start)))])];
      }
    }, {
      key: "item.contract_end",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatDate")(item.contract_end)))])];
      }
    }, {
      key: "item.href",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.href ? _c('v-btn', {
          attrs: {
            "text": "",
            "x-small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('downloadHref', item.href);
            }
          }
        }, [_vm._v("下载")]) : _c('span', [_vm._v("无")])];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('span', [_vm._v(_vm._s(item.creator && item.creator.personal ? item.creator.personal.name : '-'))])];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.edit_time",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.creator._id === _vm.currentUser._id || item.person_in_charge.find(function (p) {
          return p._id === _vm.currentUser._id;
        }) || _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Contract_Edit_Detail') ? _c('td', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_contract', item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" 编辑 ")], 1), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "error",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('delete_contract', item._id);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("delete")]), _vm._v(" 删除 ")], 1)], 1) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载新增模版，填写合同信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_create_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importCreateFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.batch.import_end
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_create_file');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.batch.import_failed.length || _vm.batch.import_result.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.batch.import_failed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.batch.import_failed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.batch.import_result.length) + " 条记录； "), _vm.batch.import_result.length ? _c('v-row', [_c('v-col', {
    staticClass: "grow"
  }, _vm._l(_vm.batch.import_result, function (item) {
    return _c('p', {
      key: item._id,
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "800px"
    },
    model: {
      value: _vm.imgDlg,
      callback: function callback($$v) {
        _vm.imgDlg = $$v;
      },
      expression: "imgDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 客户营业执照 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.imgUrl,
      "contain": ""
    }
  }), _c('v-overlay', {
    staticClass: "align-center justify-center",
    attrs: {
      "absolute": ""
    },
    model: {
      value: _vm.overlay,
      callback: function callback($$v) {
        _vm.overlay = $$v;
      },
      expression: "overlay"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除合同 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除此合同吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteContract
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600"
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 编辑合同 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_edit');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center pt-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "档案号",
      "outlined": "",
      "dense": "",
      "prepend-icon": "pin"
    },
    model: {
      value: _vm.contractItem.file_no,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "file_no", $$v);
      },
      expression: "contractItem.file_no"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "合同名称*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "gavel"
    },
    model: {
      value: _vm.contractItem.contract_name,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_name", $$v);
      },
      expression: "contractItem.contract_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "合同对象*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "drive_file_move"
    },
    model: {
      value: _vm.contractItem.contract_object,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_object", $$v);
      },
      expression: "contractItem.contract_object"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "合同金额*",
      "type": "number",
      "outlined": "",
      "dense": "",
      "suffix": "元",
      "prepend-icon": "money"
    },
    model: {
      value: _vm.contractItem.contract_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_amount", $$v);
      },
      expression: "contractItem.contract_amount"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.otherBusinessType,
      "label": "合同类型*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "list"
    },
    model: {
      value: _vm.contractItem.business_type,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "business_type", $$v);
      },
      expression: "contractItem.business_type"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "prepend-icon": "map"
    },
    model: {
      value: _vm.contractItem.area,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "area", $$v);
      },
      expression: "contractItem.area"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.otherDept,
      "label": "部门*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "store"
    },
    model: {
      value: _vm.contractItem.dept,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "dept", $$v);
      },
      expression: "contractItem.dept"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "rules": [_vm.rules.selected],
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "公司内部负责人",
      "multiple": "",
      "clearable": "",
      "prepend-icon": "people"
    },
    model: {
      value: _vm.contractItem.person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "person_in_charge", $$v);
      },
      expression: "contractItem.person_in_charge"
    }
  }), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref18) {
        var on = _ref18.on,
          attrs = _ref18.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同开始日期*",
            "rules": [_vm.rules.required],
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available"
          },
          model: {
            value: _vm.contractItem.contract_start,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "contract_start", $$v);
            },
            expression: "contractItem.contract_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.contract_start,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_start", $$v);
      },
      expression: "contractItem.contract_start"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref19) {
        var on = _ref19.on,
          attrs = _ref19.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同结束日期*",
            "rules": [_vm.rules.required],
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_busy"
          },
          model: {
            value: _vm.contractItem.contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "contract_end", $$v);
            },
            expression: "contractItem.contract_end"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_end", $$v);
      },
      expression: "contractItem.contract_end"
    }
  })], 1), _c('v-text-field', {
    attrs: {
      "label": "客户联系人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "connect_without_contact"
    },
    model: {
      value: _vm.contractItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contact_name", $$v);
      },
      expression: "contractItem.contact_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "联系电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone"
    },
    model: {
      value: _vm.contractItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contact_phone", $$v);
      },
      expression: "contractItem.contact_phone"
    }
  }), _c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-avatar', {
    attrs: {
      "rounded": "0",
      "size": "80"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("description")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "caption"
  }, [_c('div', {
    staticClass: "text-left"
  }, [_vm._v("合同文件，支持图片、PDF、Word格式")]), _vm.contractItem.file_name ? _c('div', {
    staticClass: "text-left"
  }, [_vm._v("当前文件："), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.contractItem.file_name))])]) : _vm._e()])], 1), _c('v-list-item-action', [_c('v-row', [_c('v-col', [_vm.contractItem.file_name ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.removeImg
    }
  }, [_c('v-icon', [_vm._v("close")])], 1) : _vm._e()], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.clickUploadInput
    }
  }, [_c('v-icon', [_vm._v("upload")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_edit');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveSubmit
    }
  }, [_vm._v("保存")])], 1)], 1), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": ".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.Upload.uploading,
      callback: function callback($$v) {
        _vm.$set(_vm.Upload, "uploading", $$v);
      },
      expression: "Upload.uploading"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-card-title', [_vm._v("文件上传中...")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "color": "white",
      "value": _vm.Upload.progressUpload
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }