<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row class="pa-4" justify="space-between">
          <v-col cols="12">
            <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false"
                   @change="detectFiles($event)"/>
            <v-progress-linear color="secondary" v-if="Upload.uploading"
                               :value="Upload.progressUpload"></v-progress-linear>
            <v-img
              :src="editItemData.profileImage ? ossURL +'/'+ editItemData.profileImage : '/static/error/empty_banner.png'"
              class="white--text align-end"
              :aspect-ratio="24/9"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              dark>
              <v-card-title class="text-center justify-center">
                <v-hover v-slot:default="{ hover }">
                  <v-img
                    :src="editItemData.logoImage ? ossURL +'/'+ editItemData.logoImage : '/static/error/empty_icon.png'"
                    aspect-ratio="1" contain max-width="125">
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out white hover-btn"
                      >
                        <v-btn color="primary" icon class="mr-4" @click="editItem('logo_image', distributor)">
                          <v-icon>add_photo_alternate</v-icon>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-hover>
              </v-card-title>
              <v-card-title class="text-center justify-center">
                {{ editItemData.sub_title }}
              </v-card-title>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn dark icon class="mr-4" @click="editItem('profile_image', distributor)">
                  <v-icon>add_photo_alternate</v-icon>
                </v-btn>
              </v-card-title>
            </v-img>
          </v-col>
          <v-col cols="6">
            <v-list subheader two-line>
              <v-subheader>管理账号</v-subheader>
              <v-list-item v-for="(item, i) in editItemData.admin_account" :key="i">
                <v-list-item-icon>
                  <v-icon>assignment_ind</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.personal.name }}</v-list-item-title>
                  <v-list-item-subtitle>状态：{{ item.isBlock ? '停用' : item.status === 1 ? '正常' : '停用' }} | 最后更新于 {{ item.updateTime | formatTime}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon @click="editItem('remove_admin', dealer, item)">remove_circle</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-btn block outlined @click="editItem('add_admin', distributor)"><v-icon left>add</v-icon>增加管理人员</v-btn>
            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list subheader two-line>
              <v-subheader>主体信息</v-subheader>
              <v-list-item @click="editItem('name', editItemData)">
                <v-list-item-content>
                  <v-list-item-title>{{ editItemData.name }}</v-list-item-title>
                  <v-list-item-subtitle>状态：{{ editItemData.isBlock ? '暂停运营' : editItemData.status === 0 ? '正常' : '待审批'
                    }} | 最后更新于 {{ editItemData.updateTime | formatTime }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>vpn_key</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ editItemData.inviteCode }}</v-list-item-title>
                  <v-list-item-subtitle>邀请码作为员工注册时填写依据，不能修改。</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="editItem('sub_title', editItemData)">
                <v-list-item-icon>
                  <v-icon>subtitles</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ editItemData.sub_title }}</v-list-item-title>
                  <v-list-item-subtitle>主体理念：企业宣传口号，文化理念</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="editItem('contact', editItemData)">
                <v-list-item-icon>
                  <v-icon>contact_phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ editItemData.contactName }}</v-list-item-title>
                  <v-list-item-subtitle>主体联系人及电话 {{ editItemData.contactPhone }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="editItem('address', editItemData)">
                <v-list-item-icon>
                  <v-icon>room</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ editItemData.address }}</v-list-item-title>
                  <v-list-item-subtitle>主体所在地址 {{ editItemData.province }} {{ editItemData.city }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="editItem('limit', editItemData)">
                <v-list-item-icon>
                  <v-icon>vertical_align_top</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>该主体可以服务管理的数量上限</v-list-item-title>
                  <v-list-item-subtitle>代理商：{{ editItemData.limit && editItemData.limit.agency }} | 工单：{{
                      editItemData.limit && editItemData.limit.cases }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="editItem('block', editItemData)">
                <v-list-item-icon>
                  <v-icon>block</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>是否阻止主体使用本平台服务？</v-list-item-title>
                  <v-list-item-subtitle>当前主体运营状态：{{ editItemData.isBlock ? '暂停' : '正常' }}，暂停运营同时将暂停该主体员工登陆。</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch v-model="editItemData.isBlock"></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="editItemData.status === 1" text @click="editItem('auth', editItemData)">审核</v-btn>
        <v-btn text @click="$router.push({path:'/admin/distributor/'})">关闭</v-btn>
        <v-btn text @click="editItem('delete', editItemData)">删除</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text v-if="dlgTitle === '主体名称'">
            <v-text-field
              v-model="editItemData.name"
              :rules="[rules.required]"
              type="text"
              label="企业名称"
              hint="请输入主体的名称，该名称为全局唯一命名"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '主体理念'">
            <v-text-field
              v-model="editItemData.sub_title"
              type="text"
              label="主体理念"
              hint="请输入主体宣传口号，文化理念"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '主体联系人'">
            <v-text-field
              v-model="editItemData.contactName"
              :rules="[rules.required]"
              type="text"
              label="联系人"
              hint="联系人作为主体和平台的主要沟通接口人"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="editItemData.contactPhone"
              :rules="[rules.phonenum]"
              type="number"
              label="联系电话"
              hint="电话号码作为主体和平台的主要沟通方式"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '主体地址'">
            <v-text-field
              v-model="editItemData.address"
              :rules="[rules.required]"
              type="text"
              label="联系地址"
              hint="请填写主体实际地址"
              outlined
            >
            </v-text-field>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '服务上限'">
            <v-text-field
              v-model="editItemData.limit.agency"
              :rules="[rules.required]"
              v-mask="'####'"
              label="代理商上限"
              hint="主体可以服务企业的数量上限"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="editItemData.limit.cases"
              :rules="[rules.required]"
              v-mask="'#######'"
              label="工单上限"
              hint="主体可以创建工单的数量上限"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '增加管理人员'">
            <v-text-field
              v-model="register.username"
              :rules="[rules.required]"
              :error-messages="register.msgName"
              :clearable="register.msgName !== ''"
              type="text"
              label="账号名称"
              hint="账号名称默认为手机号码，将作为登陆名称使用"
              outlined
              required
            >
              <template v-slot:append>
                <v-scale-transition leave-absolute>
                  <v-progress-circular
                    v-if="register.chkName"
                    :width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-scale-transition>
              </template>
            </v-text-field>
            <v-text-field
              v-model="register.password"
              :rules="[rules.required]"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'visibility_off' : 'visibility'"
              label="账号密码"
              hint="请牢记此密码，密码将作为登陆凭证"
              outlined
              required
              @click:append="showPass = !showPass"
            >
            </v-text-field>
            <v-text-field
              v-model="register.phone"
              :rules="[rules.required, rules.phonenum]"
              :error-messages="register.msgPhone"
              :clearable="register.msgPhone !== ''"
              type="number"
              label="手机号码"
              hint="手机号码可作为登陆名称使用"
              outlined
              required
            >
              <template v-slot:append>
                <v-scale-transition leave-absolute>
                  <v-progress-circular
                    v-if="register.chkPhone"
                    :width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-scale-transition>
              </template>
            </v-text-field>
            <v-text-field
              v-model="register.personal.name"
              type="text"
              label="员工姓名"
              hint="员工姓名为用户的真实姓名"
              outlined
            >
            </v-text-field>
            <v-text-field
              v-model="register.employeeNo"
              type="text"
              label="员工编号"
              hint="员工编号为企业内部的员工编号"
              outlined
            >
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dlgEdit = false" v-if="$route.params.id !== '0'">取消</v-btn>
            <v-btn color="primary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from "lodash";
import axios from "axios";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_DISTRIBUTOR,
  PUBLISH_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  AUTH_DISTRIBUTOR,
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CREATE_ADMIN,
  REMOVE_ADMIN
} from "@/store/actions.type";

export default {
  data() {
    const defaultRegister = Object.freeze({
      username: '',
      password: '',
      phone: '',
      chkName: false,
      chkPhone: false,
      msgName: '',
      msgPhone: '',
      personal: {
        name: ''
      },
      employeeNo: ''
    });
    return {
      title_page: function () {
        return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '编辑主体资料' : '注册主体信息';
      },
      editItemData: {},
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      Update: {
        errmsgAddress: '',
        addrLoading: false,
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        }
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      register: Object.assign({}, defaultRegister),
      regValid: true,
      showPass: false,
      defaultRegister
    }
  },
  watch: {
    dlgEdit(val) {
      val || this.close()
    },
    'register.username'() {
      this.checkName();
    },
    'register.phone'() {
      this.checkPhone();
    },
  },
  created() {
  },
  computed: {
    ...mapGetters(["currentUser", "distributor", "uploadSign"]),

  },
  mounted() {
    if (this.$route.params.id !== '0') {
      if (this.$route.params.id === '1') {
        this.editItemData = Object.assign({}, this.$route.params);
      } else {
        this.getDealer(this.$route.params.id);
      }
    } else {
      this.editItem('name', {})
    }
  },
  methods: {
    goBack() {
      this.editItemData = Object.assign({}, {});
      this.$router.push({path: '/admin/distributor'});
    },
    getDealer(id) {
      this.$store.dispatch(FETCH_DISTRIBUTOR, id)
        .then(data => {
          this.editItemData = Object.assign({}, data);
          this.editItemData._id = data._id;
          this.register.inviteCode = data.inviteCode;
        });
    },
    close() {
      this.dlgTitle = '';
      this.dlgEdit = false;
    },
    editItem(type, item, subitem) {
      this.editItemData = Object.assign({}, item);
      if (type === 'name') {
        this.dlgTitle = '主体名称';
        this.dlgEdit = true;
      }
      if (type === 'profile_image') {
        this.dlgTitle = '编辑横幅';
        this.$store.dispatch(FETCH_UPLOAD)
          .then(this.$refs.uploadInput.click())
          .catch(err => {
            this.$store.commit(SET_ERROR, {msg: err});
          });
      }
      if (type === 'logo_image') {
        this.dlgTitle = '编辑标示';
        this.$store.dispatch(FETCH_UPLOAD)
          .then(this.$refs.uploadInput.click())
          .catch(err => {
            this.$store.commit(SET_ERROR, {msg: err});
          });
      }
      if (type === 'sub_title') {
        this.dlgTitle = '主体理念';
        this.dlgEdit = true;
      }
      if (type === 'contact') {
        this.dlgTitle = '主体联系人';
        this.dlgEdit = true;
      }
      if (type === 'address') {
        this.dlgTitle = '主体地址';
        this.dlgEdit = true;
      }
      if (type === 'limit') {
        this.dlgTitle = '服务上限';
        this.dlgEdit = true;
      }
      if (type === 'block') {
        this.submit();
      }
      if (type === 'auth') {
        if (confirm('确定要审核 '+ item.name +' 审核通过后该主体即可提供企业服务。')) {
          this.$store.dispatch(AUTH_DISTRIBUTOR, item)
            .then(() => {
              this.getDealer(item._id);
              this.$store.commit(SET_ERROR, {msg: '审核成功', color: 'primary'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
        }
      }
      if (type === 'delete') {
        this.submit();
      }
      if (type === 'add_admin') {
        this.dlgTitle = '增加管理人员';
        this.dlgEdit = true;
      }
      if (type === 'remove_admin') {
        if (confirm('确定要移除 '+ subitem.username +' 管理权限，移除后该账号将失去对主体的管理功能。')) {
          this.$store.dispatch(REMOVE_ADMIN, { ownerid: item._id, userid: subitem._id, type: 'Dealer' })
            .then(() => {
              this.getDealer(item._id);
              this.$store.commit(SET_ERROR, {msg: '移除成功。', color: 'primary'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
        }
      }
      if (type === 'edit_admin') {
        console.log('subitem =>'+ JSON.stringify(subitem))
      }
    },
    submit() {
      if (this.editItemData._id) {
        if (this.dlgTitle === '增加管理人员') {
          this.register.ownerType = 'Distributor';
          this.register.ownerId = this.editItemData._id;
          this.$store.dispatch(CREATE_ADMIN, this.register)
            .then((data) => {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.register = Object.assign({}, this.defaultRegister);
              this.register['personal'] = {name: ''};
              this.getDealer(data.employer.ownerId);
              this.dlgEdit = false;
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err.response.data.message});
            });

        } else {
          this.$store.dispatch(EDIT_DISTRIBUTOR, this.editItemData)
            .then((data) => {
              this.dlgEdit = false;
              this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
              this.getDealer(data._id);
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err.response.data.message});
            });
        }
      } else {
        this.$store.dispatch(PUBLISH_DISTRIBUTOR, this.editItemData)
          .then((data) => {
            this.dlgEdit = false;
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDealer(data._id);
            this.$router.push({path: '/admin/distributor/edit/' + data._id});
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.editItemData._id + '/' + this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          if (this.dlgTitle === '编辑横幅') {
            this.editItemData.profileImage = this.editItemData._id + '/' + this.uploadSign.key;
          }
          if (this.dlgTitle === '编辑标示') {
            this.editItemData.logoImage = this.editItemData._id + '/' + this.uploadSign.key;
          }
          this.submit();
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    checkName: _.debounce(function () {
      if (this.register.username) {
        this.register.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.register.username)
          .then(() => {
            this.register.msgName = '账号已被注册，请更换其它名称';
            this.register.chkName = false;
          })
          .catch(() => {
            this.register.msgName = '';
            this.register.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.register.phone) {
        this.register.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.register.phone)
          .then(() => {
            this.register.msgPhone = '手机号码已被注册，请更换其它名称';
            this.register.chkPhone = false;
          })
          .catch(() => {
            this.register.msgPhone = '';
            this.register.chkPhone = false;
          })
      }
    }, 1000),
  },
  directives: {}
}
</script>
<style>
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  .hover-btn {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .7;
    position: absolute;
    width: 100%;
  }
</style>
