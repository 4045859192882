var render = function render(){
  var _vm$caseInfo$agency, _vm$caseInfo$settleLi, _vm$caseInfo$settleLi2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "d-print-none",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_c('span', [_vm._v(_vm._s(_vm.caseInfo.case_number) + " 工单详情")]), _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "color": "primary",
      "label": "",
      "outlined": "",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.caseInfo.group === 1 ? '能源' : _vm.caseInfo.group === 2 ? '调峰' : _vm.caseInfo.group === 3 ? '售电' : '智科') + " ")]), _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "color": _vm.caseInfo.status === 1 ? 'success' : 'grey',
      "label": "",
      "outlined": "",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.caseInfo.status === 1 ? '进行中' : _vm.caseInfo.status === 2 ? '已取消' : '已完成') + " ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("流程信息")]), _c('v-spacer')], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_vm.steps ? _c('v-stepper', {
    staticClass: "mt-12 elevation-0 phaseStepper",
    staticStyle: {
      "max-width": "980px"
    },
    attrs: {
      "alt-labels": ""
    },
    model: {
      value: _vm.steps,
      callback: function callback($$v) {
        _vm.steps = $$v;
      },
      expression: "steps"
    }
  }, [_c('div', {
    staticClass: "step-con"
  }, [_c('v-stepper-header', [_vm._l(_vm.tasks_list, function (phase, i) {
    return [_c('v-stepper-step', {
      key: i,
      attrs: {
        "step": phase.order + 1,
        "color": phase.isActive && phase.status === 1 ? 'primary' : phase.status === 2 ? 'success' : phase.status === 3 ? 'error' : 'grey',
        "complete": phase.status > 1,
        "complete-icon": phase.status === 2 ? 'done' : 'close'
      }
    }, [phase.isActive ? _c('span', [phase.status === 0 ? _c('span', [_vm._v(_vm._s(phase.task.name))]) : _vm._e(), phase.status === 1 ? _c('span', [_vm._v(_vm._s(phase.task.doingName || phase.task.name))]) : _vm._e(), phase.status === 2 ? _c('span', [_vm._v(_vm._s(phase.task.doneName || phase.task.name))]) : _vm._e(), phase.status === 3 ? _c('span', [_vm._v(_vm._s(phase.task.cancelName || phase.task.name))]) : _vm._e()]) : _vm._e(), !phase.isActive ? _c('span', [phase.status === 0 ? _c('span', [_vm._v(_vm._s(phase.task.name))]) : _vm._e(), phase.status === 1 ? _c('span', [_vm._v(_vm._s(phase.task.doingName))]) : _vm._e(), phase.status === 2 ? _c('span', [_vm._v(_vm._s(phase.task.doneName || phase.task.name))]) : _vm._e(), phase.status === 3 ? _c('span', [_vm._v(_vm._s(phase.task.cancelName || phase.task.name))]) : _vm._e()]) : _vm._e()]), i < _vm.tasks_list.length - 1 ? _c('v-divider', {
      key: i + 999,
      staticClass: "mt-13 mx-n14"
    }) : _vm._e()];
  })], 2)], 1)]) : _vm._e()], 1)], 1), _c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("结算信息")]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "pl-8 my-6"
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("代理商名称：")]), _c('span', [_vm._v(_vm._s((_vm$caseInfo$agency = _vm.caseInfo.agency) === null || _vm$caseInfo$agency === void 0 ? void 0 : _vm$caseInfo$agency.name))])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("创建日期：")]), _vm.caseInfo.createTime ? _c('span', [_vm._v(_vm._s(_vm._f("formatTime")(_vm.caseInfo.createTime)))]) : _vm._e()]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("结算客户数量：")]), _c('span', [_vm._v(_vm._s(_vm.customers.length) + "个")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("所属区域：")]), _vm.caseInfo.settleList ? _c('span', [_vm._v(_vm._s((_vm$caseInfo$settleLi = _vm.caseInfo.settleList[0]) === null || _vm$caseInfo$settleLi === void 0 ? void 0 : (_vm$caseInfo$settleLi2 = _vm$caseInfo$settleLi.area) === null || _vm$caseInfo$settleLi2 === void 0 ? void 0 : _vm$caseInfo$settleLi2.name))]) : _vm._e()]), _vm.caseInfo.group === 1 || _vm.caseInfo.group === 3 || _vm.caseInfo.group === 4 ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("结算明细单：")]), _c('span', [_c('case-receipt-detailed', {
    attrs: {
      "getType": _vm.caseInfo.group,
      "settleData": _vm.caseInfo
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "small": ""
    }
  }, [_vm._v("查看")])], 1)], 1)]) : _vm._e(), _vm.caseInfo.group !== 4 ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("结算汇总单：")]), _c('span', [_c('case-receipt-summary', {
    attrs: {
      "getType": _vm.caseInfo.group,
      "settleData": _vm.caseInfo,
      "getId": _vm.caseInfo._id
    },
    on: {
      "reloadCase": _vm.reloadCase
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "small": ""
    }
  }, [_vm._v("查看")])], 1)], 1)]) : _vm._e(), _vm.steps > 3 && (_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Case_Edit_Receipt')) ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("付款审批单：")]), _c('span', [_c('case-receipt-approve', {
    attrs: {
      "getType": _vm.caseInfo.group,
      "settleData": _vm.caseInfo,
      "getId": _vm.caseInfo._id
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "small": ""
    }
  }, [_vm._v("查看")])], 1)], 1)]) : _vm._e()], 1), _c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("附加信息")]), _c('v-spacer')], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "px-4"
  }, [_c('v-tabs', [_vm.caseInfo.group === 1 || _vm.caseInfo.group === 3 ? _c('v-tab', [_vm._v("数据")]) : _vm._e(), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _vm.caseInfo.group === 1 || _vm.caseInfo.group === 3 ? _c('v-tab-item', [_c('case-settle-list', {
    attrs: {
      "caseInfo": _vm.caseInfo
    }
  })], 1) : _vm._e(), _c('v-tab-item', [_c('widgets-files-list', {
    attrs: {
      "entityType": "CaseSettle",
      "entityId": _vm.caseId,
      "type": 1
    }
  })], 1), _c('v-tab-item', [_c('widgets-remarks-list', {
    attrs: {
      "entityType": "CaseSettle",
      "entityId": _vm.caseId,
      "type": 0
    }
  })], 1)], 1)], 1)], 1), _c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("历史记录")]), _c('v-spacer')], 1), _vm.caseInfo.count_tasks ? _c('case-action-logs', {
    attrs: {
      "caseData": _vm.caseInfo
    }
  }) : _vm._e()], 1), _vm.caseInfo.status === 1 && (_vm.caseInfo.isSelf || _vm.caseInfo.isEdit) ? _c('v-divider') : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _vm.caseInfo.status === 1 && (_vm.caseInfo.isSelf || _vm.caseInfo.isEdit || _vm.currentUser.hasAdmin.includes('distributor')) ? _c('v-btn', {
    attrs: {
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('reject_case');
      }
    }
  }, [_vm._v("终止工单")]) : _vm._e(), _vm.caseInfo.status === 1 && _vm.caseInfo.isEdit ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('submit_case');
      }
    }
  }, [_vm._v(_vm._s(_vm.steps === 4 ? '付款完成' : '通过'))]) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.warnTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_reject');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" " + _vm._s(_vm.warnText) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_reject');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _vm.actionType === 'reject_case' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm_reject');
      }
    }
  }, [_vm._v("确认")]) : _vm._e(), _vm.actionType === 'submit_case' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm_submit');
      }
    }
  }, [_vm._v("确认")]) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }