<template>
  <v-container>
    <v-row class="fill-height overflow-y-auto">
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Report_Company')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/report_company.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                公司数据
              </v-card-title>
              <v-card-text>鑫泰能源公司内部数据统计</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/report/company'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Report_Area')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/report_area.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                区域汇总数据
              </v-card-title>
              <v-card-text>各区域汇总数据统计</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/report/area'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Report_Agency')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/report_agency_1.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                代理商明细数据
              </v-card-title>
              <v-card-text>鑫泰能源公司代理商明细数据统计</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/report/agency'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Report_Agency_Summary')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/card/report_agency.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                代理商汇总数据
              </v-card-title>
              <v-card-text>鑫泰能源公司代理商汇总数据统计</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/distributor/report/agencySummary'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  created() {
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {},
  mounted() {},
  methods: {}
}
</script>
