<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>其他合同管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" depressed>
              <v-icon left>add</v-icon>
              新增合同
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="doAction('input_create')">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>手动填写</v-list-item-title>
            </v-list-item>
            <v-list-item @click="doAction('batch_create')">
              <v-list-item-icon>
                <v-icon>library_add</v-icon>
              </v-list-item-icon>
              <v-list-item-title>批量导入</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="query.key"
              label="合同名称"
              outlined
              dense
              clearable
              hide-details
              @input="searchList"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="query.person_in_charge"
              :items="accountList"
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="公司内部负责人"
              multiple
              clearable
              hide-details
              @change="searchList"
            ></v-autocomplete>
          </v-col><v-col>
          <v-select
            v-model="query.business_type"
            :items="otherBusinessType"
            label="合同类型"
            item-text="name"
            item-value="code"
            outlined
            dense
            hide-details
            clearable
            @change="searchList"
          ></v-select>
        </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <widgets-watermark :watermarkText="wmText"></widgets-watermark>
        <v-data-table
          :options.sync="optionsItem"
          :headers="defaultHeader"
          :items="contractList"
          :server-items-length="contractCount"
          :loading="contractLoading"
          loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
          class="elevation-0 mt-4"
          transition
          fixed-header
          height="600"
        >
          <template v-slot:item.no="{ item }">
            <td nowrap="true" class="text-center">{{ item.no }}</td>
          </template>
          <template v-slot:item.file_no="{ item }">
            <td nowrap="true" align="center">{{ item.file_no || '' }}</td>
          </template>
          <template v-slot:item.area="{ item }">
            <td nowrap="true" align="center" v-if="item.area">{{ item.area.name }}</td>
          </template>
          <template v-slot:item.contract_name="{ item }">
            <td nowrap="true" align="center">{{ item.contract_name }}</td>
          </template>
          <template v-slot:item.business_type="{ item }">
            <td nowrap="true" align="center">
              <span v-if="item.business_type === 3">售电业务</span>
              <span v-if="item.business_type === 4">配电室合同（智科）</span>
              <span v-if="item.business_type === 8">入市协议</span>
              <span v-if="item.business_type === 9">电厂合同</span>
              <span v-if="item.business_type === 5">采购合同</span>
              <span v-if="item.business_type === 2">辅助服务（调峰）</span>
              <span v-if="item.business_type === 10">煤炭业务</span>
            </td>
          </template>
          <template v-slot:item.dept="{ item }">
            <span v-if="item.dept === 'YY'">发展运营部</span>
            <span v-if="item.dept === 'CW'">财务部</span>
            <span v-if="item.dept === 'PD'">鑫泰智科</span>
            <span v-if="item.dept === 'NY'">能源管理部</span>
            <span v-if="item.dept === 'YX'">营销部</span>
            <span v-if="item.dept === 'SC'">市场部</span>
          </template>
          <template v-slot:item.contract_object="{ item }">
            <td nowrap="true" align="center" v-if="item.contract_object">{{ item.contract_object }}</td>
          </template>
          <template v-slot:item.contract_amount="{ item }">
            <td nowrap="true" align="center">{{ item.contract_amount | formatMoney}}</td>
          </template>
          <template v-slot:item.person_in_charge="{ item }">
            <td nowrap="true" class="text-center">{{ item.person_in_charge.map( item => item.personal.name ) }}</td>
          </template>
          <template v-slot:item.contract_start="{ item }">
            <span>{{ item.contract_start | formatDate}}</span>
          </template>
          <template v-slot:item.contract_end="{ item }">
            <span>{{ item.contract_end | formatDate}}</span>
          </template>
          <template v-slot:item.href="{ item }">
            <v-btn v-if="item.href" text x-small color="primary" @click="doAction('downloadHref', item.href)">下载</v-btn>
            <span v-else>无</span>
          </template>
          <template v-slot:item.creator="{ item }">
            <span>{{ item.creator && item.creator.personal ? item.creator.personal.name : '-' }}</span>
          </template>
          <template v-slot:item.createTime="{ item }">
            <span>{{ item.createTime | formatTime}}</span>
          </template>
          <template v-slot:item.edit_time="{ item }">
            <span>{{ item.updateTime | formatTime}}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <td align="center" v-if="item.creator._id === currentUser._id || item.person_in_charge.find(p => p._id === currentUser._id) || currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Contract_Edit_Detail')">
              <v-btn text color="primary" small @click="doAction('edit_contract', item)">
                <v-icon x-small>edit</v-icon>
                编辑
              </v-btn>
              <v-btn text color="error" small @click="doAction('delete_contract', item._id)">
                <v-icon x-small>delete</v-icon>
                删除
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dlgInfo" persistent scrollable width="600px">
      <v-card>
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="6">
              <p>1. 下载新增模版，填写合同信息.</p>
              <v-btn text @click="doAction('download_create_template')">下载模版<v-icon>file_download</v-icon></v-btn>
            </v-col>
            <v-col cols="6">
              <input
                id="import_file"
                type="file"
                name="import_file"
                ref="uploadImport"
                accept=".zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed"
                :multiple="false"
                @change="importCreateFiles($event)"/>
              <p>2. 上传填好的文件.</p>
              <v-btn text @click="doAction('upload_create_file')" :loading="!batch.import_end"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="batch.import_failed.length || batch.import_result.length">
            <v-col cols="12">
              <v-divider></v-divider>
              <v-alert
                outlined
                type="error"
                text
              >
                导入失败：<span v-if="batch.import_failed.length === 0">无</span><ul v-else><li v-for="(fail,index) in batch.import_failed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
              </v-alert>
              <v-alert
                outlined
                type="success"
                text
              >
                成功创建：{{ batch.import_result.length }} 条记录；
                <v-row v-if="batch.import_result.length">
                  <v-col class="grow">
                    <p class="font-weight-black" v-for="item in batch.import_result" :key="item._id">{{ item.name }}</p>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imgDlg" persistent scrollable width="800px">
      <v-card>
        <v-card-title>
          客户营业执照
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_img')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img :src="imgUrl" contain></v-img>
          <v-overlay
            v-model="overlay"
            absolute
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除合同
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除此合同吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="deleteContract">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDlg" persistent scrollable width="600">
      <v-card>
        <v-card-title>
          编辑合同
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_edit')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-6">
          <v-form v-model="valid" @submit.prevent="saveSubmit">
            <v-text-field
              v-model="contractItem.file_no"
              label="档案号"
              outlined
              dense
              prepend-icon="pin"
            ></v-text-field>
            <v-text-field
              v-model="contractItem.contract_name"
              label="合同名称*"
              outlined
              :rules="[rules.required]"
              dense
              prepend-icon="gavel"
            ></v-text-field>
            <v-text-field
              v-model="contractItem.contract_object"
              label="合同对象*"
              outlined
              :rules="[rules.required]"
              dense
              prepend-icon="drive_file_move"
            ></v-text-field>
            <v-text-field
              v-model="contractItem.contract_amount"
              label="合同金额*"
              type="number"
              outlined
              dense
              suffix="元"
              prepend-icon="money"
            ></v-text-field>
            <v-select
              v-model="contractItem.business_type"
              :items="otherBusinessType"
              label="合同类型*"
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              outlined
              dense
              prepend-icon="list"
            ></v-select>
            <v-select
              v-model="contractItem.area"
              :items="areaList"
              label="所属区域*"
              :rules="[rules.selected]"
              item-text="name"
              item-value="_id"
              outlined
              dense
              prepend-icon="map"
            ></v-select>
            <v-select
              v-model="contractItem.dept"
              :items="otherDept"
              label="部门*"
              :rules="[rules.selected]"
              item-text="name"
              item-value="code"
              outlined
              dense
              prepend-icon="store"
            ></v-select>
            <v-autocomplete
              v-model="contractItem.person_in_charge"
              :items="accountList"
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              :rules="[rules.selected]"
              dense
              chips
              small-chips
              deletable-chips
              label="公司内部负责人"
              multiple
              clearable
              prepend-icon="people"
            ></v-autocomplete>
            <v-menu ref="sign_date" v-model="contract_start_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="contractItem.contract_start"
                  label="合同开始日期*"
                  :rules="[rules.required]"
                  readonly
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="event_available"
                ></v-text-field>
              </template>
              <v-date-picker v-model="contractItem.contract_start" no-title scrollable @input="contract_start_menu = false">
              </v-date-picker>
            </v-menu>
            <v-menu ref="sign_date" v-model="contract_end_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="contractItem.contract_end"
                  label="合同结束日期*"
                  :rules="[rules.required]"
                  readonly
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="event_busy"
                ></v-text-field>
              </template>
              <v-date-picker v-model="contractItem.contract_end" no-title scrollable @input="contract_end_menu = false">
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="contractItem.contact_name"
              label="客户联系人"
              outlined
              dense
              prepend-icon="connect_without_contact"
            ></v-text-field>
            <v-text-field
              v-model="contractItem.contact_phone"
              label="联系电话"
              outlined
              dense
              prepend-icon="phone"
            ></v-text-field>
            <v-list subheader dense>
              <v-list-item class="px-0">
                <v-list-item-avatar rounded="0" size="80">
                  <v-icon x-large>description</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle class="caption">
                    <div class="text-left">合同文件，支持图片、PDF、Word格式</div>
                    <div v-if="contractItem.file_name" class="text-left">当前文件：<span class="primary--text">{{ contractItem.file_name }}</span></div>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-col>
                      <v-btn v-if="contractItem.file_name" icon small @click="removeImg">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn icon @click="clickUploadInput" small>
                        <v-icon>upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_edit')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="saveSubmit" :disabled="!valid">保存</v-btn>
        </v-card-actions>
      </v-card>
      <input id="files" type="file" name="file" ref="uploadInput" accept=".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream" :multiple="false"
             @change="detectFiles($event)"/>
    </v-dialog>
    <v-dialog v-model="Upload.uploading" persistent max-width="550px">
      <v-card color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-progress-linear
                color="white"
                :value="Upload.progressUpload"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ApiService from "@/common/http";
import store from "@/store";
import { mapGetters } from "vuex";
import {
  CREATE_CONTRACT,
  EDIT_CONTRACT,
  FETCH_ACCOUNT_LIST,
  FETCH_AREA_LIST, FETCH_CONTRACT_LIST, FETCH_UPLOAD,
  FETCH_URL,
  REMOVE_CONTRACT
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import axios from "axios";
import Util from "@/common/util";
export default {
  data() {
    return {
      wmText: '',
      optionsItem: {},
      query: {
        key: '',
        person_in_charge: '',
        business_type: ''
      },
      defaultQuery: {
        key: '',
        person_in_charge: '',
        business_type: ''
      },
      defaultHeader: [
        { text: '合同编号', value: 'no', width: '120', align: 'center', sortable: false},
        { text: '档案号', value: 'file_no', width: '120', align: 'center', sortable: false},
        { text: '合同名称', value: 'contract_name', width: '120', align: 'center', sortable: false},
        { text: '合同对象', value: 'contract_object', width: '120', align: 'center', sortable: false},
        { text: '合同金额(元)', value: 'contract_amount', width: '120', align: 'center', sortable: false},
        { text: '合同类型', value: 'business_type', width: '120', align: 'center', sortable: false},
        { text: '区域', value: 'area', width: '120', align: 'center', sortable: false},
        { text: '部门', value: 'dept', width: '120', align: 'center', sortable: false},
        { text: '公司内部负责人', value: 'person_in_charge', width: '140', align: 'center', sortable: false},
        { text: '合同开始日期', value: 'contract_start', width: '140', align: 'center', sortable: false},
        { text: '合同结束日期', value: 'contract_end', width: '140', align: 'center', sortable: false},
        { text: '联系人', value: 'contact_name', width: '140', align: 'center', sortable: false},
        { text: '联系电话', value: 'contact_phone', width: '140', align: 'center', sortable: false},
        { text: '合同附件', value: 'href', width: '100', align: 'center', sortable: false},
        { text: '创建人', value: 'creator', width: '140', align: 'center', sortable: false},
        { text: '创建时间', value: 'createTime', width: '120', align: 'center', sortable: false},
        { text: '操作', value: 'actions', width: '200', align: 'center', sortable: false},
      ],
      batch: {
        create_header: [],
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      defaultBatch: {
        create_header: [],
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      dlgInfo: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      warnDlg: false,
      deleteId: '',
      searchAccount: null,
      editDlg: false,
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      contractItem: {
        business_type: '',
        area: '',
        file_no: '',
        contract_name: '',
        contract_object: '',
        contract_amount: '',
        dept: '',
        person_in_charge: '',
        contract_start: '',
        contract_end: '',
        contact_name: '',
        contact_phone: '',
        href: '',
        file_name: '',
      },
      defaultItem: {
        business_type: '',
        area: '',
        file_no: '',
        contract_name: '',
        contract_object: '',
        contract_amount: '',
        dept: '',
        person_in_charge: '',
        contract_start: '',
        contract_end: '',
        contact_name: '',
        contact_phone: '',
        href: '',
        file_name: '',
      },
      contract_end_menu: false,
      contract_start_menu: false,
      preUrl: '',
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fileName: '请上传文件',
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    searchAccount (val) {
      val && val !== this.customerItem.customer_managers && this.searchEmployee(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_ACCOUNT_LIST, 'limit=-1')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.setWatermark()
  },
  computed: {
    ...mapGetters([
      "currentUser", "accountList", "accountLoading", "areaList", "contractList", "contractCount", "contractLoading", "uploadSign"
    ]),
    otherBusinessType () {
      return Util.categories('otherBusinessType')
    },
    otherDept () {
      return Util.categories('otherDept')
    },
  },
  mounted() {},
  methods: {
    setWatermark() {
      this.wmText = this.currentUser.personal.name + this.currentUser.phone.substr(-4)
    },
    removeImg() {
      this.preUrl = ''
      this.contractItem.href = ''
      this.contractItem.file_name = ''
    },
    clickUploadInput() {
      store.dispatch(FETCH_UPLOAD, 'private')
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name.replace(/[%#&]/g,"");
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key',  'contract/other/' + this.uploadSign.key + '/' + this.fileName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.contractItem.href = param.get('key')
          this.contractItem.file_name = param.get('name')
          this.$forceUpdate();
          // store.dispatch(FETCH_URL, this.contractItem.href).then((fileUrl) => {
          //   this.preUrl = fileUrl;
          //   this.$forceUpdate();
          // });
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    resetFilter() {
      this.query = Object.assign({}, this.defaultQuery)
      this.getList()
    },
    searchList() {
      this.getList(this.query)
    },
    deleteContract() {
      this.$store.dispatch(REMOVE_CONTRACT, this.deleteId).then(() => {
        this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
        this.warnDlg = false
        this.deleteId = ''
        this.getList()
      }).catch((err) => {
        this.warnDlg = false
        this.deleteId = ''
        this.$store.commit(SET_ERROR, {msg: err.response.data.message})
      })
    },
    getList(query = {}) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }

      let urlQuery = '&contract_type=4'
      if (query.key) urlQuery += '&key=' + query.key
      if (query.person_in_charge && query.person_in_charge.length) urlQuery += '&person_in_charge=' + query.person_in_charge
      if (query.business_type) urlQuery += '&business_type=' + query.business_type

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CONTRACT_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    resetData() {
      let contractItem = JSON.parse(JSON.stringify(this.contractItem))
      contractItem.contract_amount = Number(contractItem.contract_amount)

      return contractItem
    },
    saveSubmit() {
      let json = {}
      json.item = this.resetData()
      if (json.item._id) {
        json.id = json.item._id
        json.item.area = json.item.area._id
        this.$store.dispatch(EDIT_CONTRACT, json).then(() => {
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
          this.getList(this.query)
          this.editDlg = false
          this.contractItem = JSON.parse(JSON.stringify(this.defaultItem))
        })
      }else {
        json.item.contract_type = 4
        this.$store.dispatch(CREATE_CONTRACT, json.item).then(() => {
          this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.getList(this.query)
          this.editDlg = false
          this.contractItem = JSON.parse(JSON.stringify(this.defaultItem))
        })
      }
    },
    doAction(action, item = {}) {
      switch(action) {
        case 'batch_create': {
          this.dlgInfo = true
          break
        }
        case 'input_create': {
          this.editDlg = true
          break
        }
        case 'edit_contract': {
          this.contractItem = JSON.parse(JSON.stringify(item))
          this.contractItem.contract_start = this.$options.filters['formatDate'](this.contractItem.contract_start)
          this.contractItem.contract_end = this.$options.filters['formatDate'](this.contractItem.contract_end)
          if (this.contractItem.href) {
            this.$store.dispatch(FETCH_URL, this.contractItem.href).then((url) => {
              this.preUrl = url
            })
          }
          this.editDlg = true
          break
        }
        case 'close_edit': {
          this.editDlg = false
          this.contractItem = Object.assign({}, this.defaultItem)
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'downloadHref': {
          store.dispatch(FETCH_URL, item)
            .then((fileUrl) => {
              let w = window.open()
              w.location.href = fileUrl
              // window.location.href = fileUrl;
            })
            .catch((err) => {
              store.commit(SET_ERROR, { msg: err });
            });
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'download_create_template': {
          ApiService.download('/contract/export?page=1&limit=-1&onlyheader=yes', {
            responseType:'blob'
          })
            .then(resp => {
              let fileName = '批量创建其他合同模版.xlsx';
              let contentDisposition = resp.headers['content-disposition'];
              if (contentDisposition) {
                fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
              }
              let blob = new Blob([resp.data], {type: 'application/octet-stream'});
              if (window.navigator.msSaveOrOpenBlob) { //支持IE
                navigator.msSaveBlob(blob, fileName);
              } else {
                let link = document.createElement('a');
                link.style.display = "none";
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                this.downloading = false
                //释放内存
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
              }
            })
            .catch(err => { // 请求失败处理
              this.downloading = false
              store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'delete_contract': {
          this.deleteId = item
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.deleteId = ''
          this.warnDlg = false
          break
        }
      }
    },
    importCreateFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importCase(fileList[x]);
      })
    },
    importCase(file) {
      this.batch.import_end = false
      let param = new FormData()
      param.append('file', file)
      ApiService.post('/contract/import', param)
        .then((data) => {
          this.batch.import_result = data.data.Success
          this.batch.import_failed = data.data.Failed
          this.$refs.uploadImport.value = ''
          this.batch.import_end = true
          this.getList()
          if (!this.batch.import_failed.length) {
            this.dlgInfo = false
            store.commit(SET_ERROR, {msg: '成功导入' + this.batch.import_result + '条合同', color: 'primary'})
          }
        })
        .catch((err) => {
          this.$refs.uploadImport.value = ''
          this.batch.import_result = { updateSum: 0, insertSum: 0 }
          this.batch.import_failed = [{line: 2, error: err.response.data.message}]
          this.batch.import_end = true
        })
    },
  },
  directives: {}
}
</script>

<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
