var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/distributor/report');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("区域汇总数据")]), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "icon": "",
            "disabled": !_vm.editDataId
          },
          on: {
            "click": _vm.openEditDlg
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("edit")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("修改数据")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "icon": "",
            "disabled": !_vm.reportAreaList || !_vm.reportAreaList.length
          },
          on: {
            "click": _vm.downloadReport
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("download")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("下载数据")])]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("filter_list")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("过滤数据")])])], 1), _c('v-divider'), _vm.business_type === 3 ? _c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "location_city",
      "count": _vm.$options.filters['formatMoney'](_vm.report.xt_service_fee),
      "label": "国网开票金额(元)(含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "real_estate_agent",
      "count": _vm.$options.filters['formatMoney'](_vm.report.xt_service_fee_no_tax),
      "label": "国网开票金额(元)(不含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "bolt",
      "count": _vm.report.settle_elec_total,
      "label": "结算电量(兆瓦时)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "attach_money",
      "count": _vm.$options.filters['formatMoney'](_vm.report.agency_service_fee),
      "label": "代理商服务费(含税)(元)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "price_check",
      "count": _vm.$options.filters['formatMoney'](_vm.report.agency_service_fee_no_tax),
      "label": "代理商服务费(不含税)(元)"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "sync_alt",
      "count": _vm.$options.filters['formatMoney'](_vm.report.diff_assess_fee),
      "label": "偏差考核费用(元)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "add_business",
      "count": _vm.$options.filters['formatMoney'](_vm.report.market_share_fee),
      "label": "市场补偿和分摊费用(元)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "balance",
      "count": _vm.$options.filters['formatMoney'](_vm.report.unbalance_money),
      "label": "不平衡资金(元)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "percent",
      "count": _vm.report.agency_service_fee_ratio + '%',
      "label": "代理商服务费占比"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "payments",
      "count": _vm.$options.filters['formatMoney'](_vm.report.net_profit),
      "label": "毛利(元)"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.business_type === 1 ? _c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "apartment",
      "count": _vm.$options.filters['formatMoney'](_vm.report.xt_service_fee),
      "label": "鑫泰开票金额(元)(含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "apartment",
      "count": _vm.$options.filters['formatMoney'](_vm.report.xt_service_fee_no_tax),
      "label": "鑫泰开票金额(元)(不含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "factory",
      "count": _vm.$options.filters['formatMoney'](_vm.report.industry_xt_service_fee),
      "label": "工业开票金额(元)(含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "factory",
      "count": _vm.$options.filters['formatMoney'](_vm.report.industry_xt_service_fee_no_tax),
      "label": "工业开票金额(元)(不含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "receipt_long",
      "count": _vm.$options.filters['formatMoney'](_vm.report.business_xt_service_fee),
      "label": "商业开票金额(元)(含税)"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": "",
      "justify": "space-between"
    }
  }, [_c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "receipt_long",
      "count": _vm.$options.filters['formatMoney'](_vm.report.business_xt_service_fee_no_tax),
      "label": "商业开票金额(元)(不含税)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "attach_money",
      "count": _vm.$options.filters['formatMoney'](_vm.report.agency_service_fee),
      "label": "代理商服务费(含税)(元)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "price_check",
      "count": _vm.$options.filters['formatMoney'](_vm.report.agency_service_fee_no_tax),
      "label": "代理商服务费(不含税)(元)"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "percent",
      "count": _vm.report.agency_service_fee_ratio + '%',
      "label": "代理商服务费占比"
    }
  })], 1), _c('v-col', [_c('report-card-count', {
    attrs: {
      "icon": "payments",
      "count": _vm.$options.filters['formatMoney'](_vm.report.gross_profit),
      "label": "税后毛利(元)"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.defaultHeader,
      "items": _vm.reportAreaList,
      "server-items-length": _vm.reportAreaListCount,
      "loading": _vm.reportAreaListLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.agency_name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.agency_name ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.agency_name))]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": _vm.clear_filter
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.typeItems,
      "label": "业务类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.searchList('business_type');
      }
    },
    model: {
      value: _vm.business_type,
      callback: function callback($$v) {
        _vm.business_type = $$v;
      },
      expression: "business_type"
    }
  })], 1), _vm.business_type === 1 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-menu', {
    ref: "report_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.query.start_date,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "start_date", $$v);
            },
            expression: "query.start_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 3208935378),
    model: {
      value: _vm.start_date_menu,
      callback: function callback($$v) {
        _vm.start_date_menu = $$v;
      },
      expression: "start_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "type": "month",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.start_date_menu = false;
      },
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.start_date,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "start_date", $$v);
      },
      expression: "query.start_date"
    }
  })], 1)], 1) : _vm._e(), _vm.business_type === 1 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-menu', {
    ref: "report_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "结束时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.query.end_date,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "end_date", $$v);
            },
            expression: "query.end_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 4178725338),
    model: {
      value: _vm.end_date_menu,
      callback: function callback($$v) {
        _vm.end_date_menu = $$v;
      },
      expression: "end_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "type": "month",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.end_date_menu = false;
      },
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.end_date,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "end_date", $$v);
      },
      expression: "query.end_date"
    }
  })], 1)], 1) : _vm._e(), _vm.business_type === 3 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-menu', {
    ref: "report_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.query.date,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "date", $$v);
            },
            expression: "query.date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1175778470),
    model: {
      value: _vm.report_date_menu,
      callback: function callback($$v) {
        _vm.report_date_menu = $$v;
      },
      expression: "report_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "type": "month",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.report_date_menu = false;
      },
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.date,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "date", $$v);
      },
      expression: "query.date"
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.area,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "area", $$v);
      },
      expression: "query.area"
    }
  })], 1), _vm.business_type === 3 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.agencyLoading,
      "items": _vm.agencyList,
      "search-input": _vm.searchAgency,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "label": "代理商名称"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAgency = $event;
      },
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.agency,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "agency", $$v);
      },
      expression: "query.agency"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "550"
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600"
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("编辑结算数据")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEditDlg
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "国网开票金额(元)(含税)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.xt_service_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "xt_service_fee", $$v);
      },
      expression: "editItem.xt_service_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "国网开票金额(元)(不含税)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.xt_service_fee_no_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "xt_service_fee_no_tax", $$v);
      },
      expression: "editItem.xt_service_fee_no_tax"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "结算电量(兆瓦时)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.settle_elec_total,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "settle_elec_total", $$v);
      },
      expression: "editItem.settle_elec_total"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商服务费(含税)(元)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.agency_service_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_service_fee", $$v);
      },
      expression: "editItem.agency_service_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商服务费(不含税)(元)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.agency_service_fee_no_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_service_fee_no_tax", $$v);
      },
      expression: "editItem.agency_service_fee_no_tax"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "偏差考核费用(元)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.diff_assess_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "diff_assess_fee", $$v);
      },
      expression: "editItem.diff_assess_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "市场补偿和分摊费用(元)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.market_share_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "market_share_fee", $$v);
      },
      expression: "editItem.market_share_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "不平衡资金(元)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.unbalance_money,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "unbalance_money", $$v);
      },
      expression: "editItem.unbalance_money"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "税后净利润(元)",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editItem.net_profit,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "net_profit", $$v);
      },
      expression: "editItem.net_profit"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEditDlg
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveSubmit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }