var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/distributor/settle');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(" 创建工单 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": "",
      "disabled": !_vm.valid || !this.settleSelected.list || !this.settleSelected.list.length
    },
    on: {
      "click": _vm.saveCase
    }
  }, [_vm._v(" 创建 ")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "结算单表头*",
      "rules": [_vm.rules.required],
      "hint": "默认系统生成，可编辑",
      "append-outer-icon": "generating_tokens"
    },
    on: {
      "click:append-outer": _vm.resetTitle
    },
    model: {
      value: _vm.editedItem.settleTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "settleTitle", $$v);
      },
      expression: "editedItem.settleTitle"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "制表人*",
      "rules": [_vm.rules.selected],
      "hint": "请查找并选择用户"
    },
    model: {
      value: _vm.editedItem.producedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "producedBy", $$v);
      },
      expression: "editedItem.producedBy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "核定人*",
      "rules": [_vm.rules.selected],
      "hint": "请查找并选择用户"
    },
    model: {
      value: _vm.editedItem.approvedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "approvedBy", $$v);
      },
      expression: "editedItem.approvedBy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "财务复核人*",
      "rules": [_vm.rules.selected],
      "hint": "请查找并选择用户"
    },
    model: {
      value: _vm.editedItem.reviewedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "reviewedBy", $$v);
      },
      expression: "editedItem.reviewedBy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "财务付款人*",
      "rules": [_vm.rules.selected],
      "hint": "请查找并选择用户"
    },
    model: {
      value: _vm.editedItem.closedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "closedBy", $$v);
      },
      expression: "editedItem.closedBy"
    }
  })], 1)], 1)], 1), _c('v-tabs', [_c('v-tab', [_vm._v("数据")]), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _c('v-tab-item', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.settleSelected.headers,
      "items": _vm.settleSelected.list,
      "transition": "",
      "fixed-header": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "body.append",
      fn: function fn() {
        return [_vm.settleSelected.type === 1 ? _c('tr', {
          staticClass: "grey lighten-3 primary--text",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('td', [_vm._v("合计")]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        }), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.xt_service_fee_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.xt_service_fee_no_tax_total)))]), _c('td'), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_no_tax_total)))]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.tax_total)))]), _c('td'), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_no_tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_total)))]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        })]) : _vm._e(), _vm.settleSelected.type === 2 ? _c('tr', {
          staticClass: "grey lighten-3 primary--text",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('td', [_vm._v("合计")]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        }), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.project_income_total)))]), _c('td', {
          attrs: {
            "colspan": "10"
          }
        }), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_no_tax_total)))]), _c('td'), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_total)))]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        })]) : _vm._e(), _vm.settleSelected.type === 3 ? _c('tr', {
          staticClass: "grey lighten-3 primary--text",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('td', [_vm._v("合计")]), _c('td', {
          attrs: {
            "colspan": "9"
          }
        }), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_no_tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_no_tax_total)))]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        })]) : _vm._e(), _vm.settleSelected.type === 4 ? _c('tr', {
          staticClass: "grey lighten-3 primary--text",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('td', [_vm._v("合计")]), _c('td', {
          attrs: {
            "colspan": "6"
          }
        }), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_no_tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_no_tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_total)))]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        })]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.date",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatMonth")(item.date)))])];
      }
    }, {
      key: "item.area",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.area ? _c('span', [_vm._v(_vm._s(item.area.name))]) : _vm._e()];
      }
    }, {
      key: "item.agency",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.agency ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.agency.name))]) : _vm._e()];
      }
    }, {
      key: "item.customer",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.customer ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.customer.name))]) : _vm._e()];
      }
    }, {
      key: "item.report_elec_total",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.report_elec_total || item.report_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.report_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.read_elec_total",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.read_elec_total || item.read_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.read_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.settle_elec_total",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.settle_elec_total || item.settle_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.settle_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.diff_elec_total",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.diff_elec_total || item.diff_elec_total === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.diff_elec_total.toFixed(3)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_service_fee",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.agency_service_fee || item.agency_service_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_service_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_service_fee_no_tax",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.agency_service_fee_no_tax || item.agency_service_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_service_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_fee",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.agency_fee || item.agency_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_fee_no_tax",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.agency_fee_no_tax || item.agency_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.diff_ratio_total",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.diff_ratio_total || item.diff_ratio_total === 0 ? _c('span', [_vm._v(_vm._s(item.diff_ratio_total.toFixed(2)))]) : _vm._e()];
      }
    }, {
      key: "item.tax",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.tax || item.tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.tax)))]) : _vm._e()];
      }
    }, {
      key: "item.project_income",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.project_income || item.project_income === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.project_income)))]) : _vm._e()];
      }
    }, {
      key: "item.project_income_no_tax",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.project_income_no_tax || item.project_income_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.project_income_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.customer_ratio",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.customer_ratio || item.customer_ratio === 0 ? _c('span', [_vm._v(_vm._s(item.customer_ratio) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.customer_tax_rate",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [item.customer_tax_rate || item.customer_tax_rate === 0 ? _c('span', [_vm._v(_vm._s(item.customer_tax_rate.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.agency_tax_rate",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [item.agency_tax_rate || item.agency_tax_rate === 0 ? _c('span', [_vm._v(_vm._s(item.agency_tax_rate.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.tax_rate",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.tax_rate || item.tax_rate === 0 ? _c('span', [_vm._v(_vm._s(item.tax_rate.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.customer_invoice_type",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [item.customer_invoice_type === 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e(), item.customer_invoice_type === 0 ? _c('span', [_vm._v("增值税普通发票")]) : _vm._e()];
      }
    }, {
      key: "item.customer_service_fee_no_tax",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [item.customer_service_fee_no_tax || item.customer_service_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.customer_service_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.customer_tax",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [item.customer_tax || item.customer_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.customer_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.customer_fee",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [item.customer_fee || item.customer_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.customer_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_ratio",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [item.agency_ratio || item.agency_ratio === 0 ? _c('span', [_vm._v(_vm._s(item.agency_ratio) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.agency_tax",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [item.agency_tax || item.agency_tax === 0 ? _c('span', [_vm._v(_vm._s(item.agency_tax.toFixed(2)) + "%")]) : _vm._e()];
      }
    }, {
      key: "item.agency_invoice_type",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [item.agency_invoice_type === 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e(), item.agency_invoice_type === 0 ? _c('span', [_vm._v("增值税普通发票")]) : _vm._e()];
      }
    }, {
      key: "item.xt_service_fee",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [item.xt_service_fee || item.xt_service_fee === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.xt_service_fee)))]) : _vm._e()];
      }
    }, {
      key: "item.xt_service_fee_no_tax",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [item.xt_service_fee_no_tax || item.xt_service_fee_no_tax === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.xt_service_fee_no_tax)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_fee_total",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [item.agency_fee_total || item.agency_fee_total === 0 ? _c('span', [_vm._v(_vm._s(item.agency_fee_total.toFixed(2)))]) : _vm._e()];
      }
    }, {
      key: "item.gross_income",
      fn: function fn(_ref31) {
        var item = _ref31.item;
        return [item.gross_income || item.gross_income === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.gross_income)))]) : _vm._e()];
      }
    }, {
      key: "item.settle_status",
      fn: function fn(_ref32) {
        var item = _ref32.item;
        return [item.settle_status === 1 ? _c('span', [_vm._v("待结算")]) : _vm._e(), item.settle_status === 2 ? _c('span', {
          staticClass: "success--text"
        }, [_vm._v("结算中")]) : _vm._e(), item.settle_status === 3 ? _c('span', [_vm._v("已结算")]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn() {
        return [_c('span', [_vm._v("-")])];
      },
      proxy: true
    }])
  })], 1), _c('v-tab-item', [_c('v-card-text', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_vm._l(_vm.editedItem.files, function (file, i) {
    return _c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    })], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.removeAttach(i);
        }
      }
    }, [_vm._v("delete")])], 1), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.downloadAttach(file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1);
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createAttach();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件 ")], 1)], 1)], 1)], 1)], 2)], 1)], 1), _c('v-tab-item', [_c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.editedItem.remarks, function (item, index) {
    return [item.divider ? _c('v-divider', {
      key: index + '_remarks_hr',
      attrs: {
        "inset": item.inset
      }
    }) : _c('v-list-item', {
      key: index + '_remarks_title',
      attrs: {
        "three-line": ""
      }
    }, [_c('account-dialogs', {
      attrs: {
        "account": item.account
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar ? _vm.ossURL + '/' + item.avatar : '/static/grey_silhouette.png'
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title) + " " + _vm._s(_vm._f("fromDate")(item.createTime)) + " " + _vm._s(item.private ? ' - 仅个人可见' : ''))]), _vm._v(" " + _vm._s(item.context) + " ")], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.removeRemark(item);
        }
      }
    }, [_vm._v("delete")])], 1)], 1)], 1)];
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createRemarks();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("rate_review")]), _vm._v("增加备注 ")], 1)], 1)], 1)], 1)], 2)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.dlgAttach,
      callback: function callback($$v) {
        _vm.dlgAttach = $$v;
      },
      expression: "dlgAttach"
    }
  }, [_vm.editedAttach.progressUpload && _vm.editedAttach.progressUpload.length ? _c('v-card', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-card-title', [_vm._v("文件上传中...")]), _c('v-card-text', [_c('v-row', _vm._l(_vm.editedAttach.progressUpload, function (progress, i) {
    return _c('v-col', {
      key: 'progress_' + i,
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(_vm.editedAttach.files[i].name) + " "), _c('v-progress-linear', {
      attrs: {
        "color": "white",
        "value": progress
      }
    })], 1);
  }), 1)], 1)], 1) : _c('v-card', [_c('v-card-title', [_vm._v(" 上传附件 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeAttach();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "label": "单击此处选择文件上传",
      "outlined": "",
      "dense": "",
      "small-chips": "",
      "multiple": "",
      "show-size": "",
      "counter": ""
    },
    model: {
      value: _vm.editedAttach.files,
      callback: function callback($$v) {
        _vm.$set(_vm.editedAttach, "files", $$v);
      },
      expression: "editedAttach.files"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeAttach();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedAttach.files.length
    },
    on: {
      "click": function click($event) {
        return _vm.addAttach();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.dlgRemarks,
      callback: function callback($$v) {
        _vm.dlgRemarks = $$v;
      },
      expression: "dlgRemarks"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 增加备注 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeRemarks
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "备注内容",
      "value": ""
    },
    model: {
      value: _vm.editedRemark.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "context", $$v);
      },
      expression: "editedRemark.context"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeRemarks();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedRemark.context
    },
    on: {
      "click": function click($event) {
        return _vm.addRemarks(_vm.editedRemark);
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }