<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="$router.back()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>代理商详情</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <widgets-watermark :watermarkText="wmText"></widgets-watermark>
        <v-toolbar flat>
          <span class="panelTitle">基本信息</span>
          <v-spacer></v-spacer>
          <v-btn v-if="isEdit" depressed color="error" @click="doAction('open_delete')"><v-icon left>delete</v-icon>删除客户</v-btn>
        </v-toolbar>
        <v-card flat>
          <v-card-title class="pl-8">
            {{ agencyInfo.name }}
            <v-btn v-if="isEdit" icon small class="ml-2"  @click="editAgency('name', '代理商名称')">
              <v-icon small>edit</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        所属地区
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editAgency('area', '所属地区')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="agencyInfo.area">
                        {{ agencyInfo.area.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        代理客户数量
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ agencyInfo.customer_count || '0' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        代理商联系人
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editAgency('contact_name', '代理商联系人')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ agencyInfo.contact_name || '无' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        联系电话
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editAgency('contact_phone', '联系电话')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ agencyInfo.contact_phone || '无' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        内部负责人
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editAgency('person_in_charge', '内部负责人')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="agencyInfo.person_in_charge">
                        {{ agencyInfo.person_in_charge.map( item => item.personal.name ) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="3">
                <v-list subheader three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="body-1">
                        发票信息
                        <v-btn v-if="isEdit" icon small class="ml-2 mb-1" @click="editAgency('invoice', '发票信息')">
                          <v-icon x-small>edit</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="agencyInfo.invoice_type == 0">增值税普通发票</span>
                        <span v-else-if="agencyInfo.invoice_type == 1">增值税专用发票</span>
                        <span> | </span>
                        <span>{{ agencyInfo.invoice_rate?agencyInfo.invoice_rate+'%':'0' }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">代理合同</span>
          <v-spacer></v-spacer>
          <contract-edit getType="agency" :getId="agencyId" :isEdit="isEdit">
            <v-btn v-if="isEdit" color="primary" depressed>
              <v-icon left>add</v-icon>
              新增合同
            </v-btn>
          </contract-edit>
        </v-toolbar>
        <contract-list getType="agency" contractType="2" :getId="agencyId" :isEdit="isEdit" :checkOnly="checkOnly" @reload="reloadAgency"></contract-list>
        <v-toolbar flat class="mt-4">
          <span class="panelTitle">合作客户</span>
        </v-toolbar>
        <agency-customer getType="agency" contractType="3" :getId="agencyId" @reload="reloadAgency"></agency-customer>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDlg" max-width="600px" persistent>
      <v-form v-model="editValid">
        <v-card>
          <v-card-title>{{ this.editDlgTitle }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-if="editDlgTitle === '代理商名称'"
              v-model="agencyItem.name"
              label="代理商名称"
              outlined
              :rules="[rules.required]"
              dense
              prepend-icon="person"
            ></v-text-field>
            <v-select
              v-if="editDlgTitle === '所属地区'"
              v-model="agencyItem.area"
              :items="areaList"
              label="所属区域"
              item-text="name"
              item-value="_id"
              outlined
              dense
              prepend-icon="map"
            ></v-select>
            <v-autocomplete
              v-if="editDlgTitle === '内部负责人'"
              v-model="agencyItem.person_in_charge"
              :items="accountList"
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="公司内部负责人"
              hint="请查找并选择公司内部负责人"
              multiple
              prepend-icon="emoji_people"
            ></v-autocomplete>
            <v-text-field
              v-if="editDlgTitle === '代理商联系人'"
              v-model="agencyItem.contact_name"
              label="代理商联系人"
              outlined
              dense
              prepend-icon="connect_without_contact"
            ></v-text-field>
            <v-text-field
              v-if="editDlgTitle === '联系电话'"
              v-model="agencyItem.contact_phone"
              label="联系电话"
              outlined
              dense
              prepend-icon="phone"
            ></v-text-field>
            <v-row v-if="editDlgTitle === '发票信息'">
              <v-col>
                <v-select
                  v-model="agencyItem.invoice_type"
                  :items="invoiceType"
                  label="发票类型"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  prepend-icon="format_list_numbered_rtl"
                ></v-select>
                <v-text-field
                  v-model="agencyItem.invoice_rate"
                  label="发票费率"
                  outlined
                  dense
                  type="number"
                  prepend-icon="percent"
                  suffix="%"
                ></v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.title"
                  label="抬头名称"
                  outlined
                  dense
                  prepend-icon="apartment"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.license_code"
                  label="统一社会信用代码"
                  outlined
                  dense
                  prepend-icon="pin"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.bank_name"
                  label="开户行"
                  outlined
                  dense
                  prepend-icon="account_balance"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.bank_account"
                  label="银行账号"
                  outlined
                  dense
                  prepend-icon="account_balance_wallet"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.license_address"
                  label="企业地址"
                  outlined
                  dense
                  prepend-icon="location_on"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.license_telephone"
                  label="企业电话"
                  outlined
                  dense
                  prepend-icon="ring_volume"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.delivery_name"
                  label="发票接收人"
                  outlined
                  dense
                  prepend-icon="person"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.delivery_phone"
                  label="接收人电话"
                  outlined
                  dense
                  prepend-icon="phone_in_talk"
                >
                </v-text-field>
                <v-text-field
                  v-model="agencyItem.invoice_info.delivery_address"
                  label="发票邮寄地址"
                  outlined
                  dense
                  prepend-icon="home"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEdit">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="submitEdit"
              :disabled="!editValid"
            >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="imgDlg" persistent scrollable width="600px">
      <v-card height="600">
        <v-card-title>
          代理商营业执照
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_img')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img :src="imgUrl"></v-img>
          <v-overlay
            v-model="overlay"
            absolute
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除代理商
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除此代理商吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="doAction('confirm_delete')">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import {
  FETCH_AGENCY,
  EDIT_AGENCY,
  FETCH_URL,
  FETCH_AREA_LIST,
  FETCH_ACCOUNT_LIST, REMOVE_AGENCY
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import store from "@/store";
export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      agencyInfo: '',
      agencyItem: {
        name: '',
        area: '',
        person_in_charge: '',
        contact_name: '',
        contact_phone: '',
        invoice_type: '',
        invoice_rate: '',
        invoice_info: {
          title: '',
          license_code: '',
          bank_name: '',
          bank_account: '',
          license_address: '',
          license_telephone: '',
          delivery_name: '',
          delivery_phone: '',
          delivery_address: '',
        },
      },
      defaultItem: {
        name: '',
        area: '',
        person_in_charge: '',
        contact_name: '',
        contact_phone: '',
        invoice_type: '',
        invoice_rate: '',
        invoice_info: {
          title: '',
          license_code: '',
          bank_name: '',
          bank_account: '',
          license_address: '',
          license_telephone: '',
          delivery_name: '',
          delivery_phone: '',
          delivery_address: '',
        },
      },
      editDlg: false,
      editValid: true,
      editDlgTitle: '',
      activeAction: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      editedAttach: {
        files: [],
        progressUpload: [],
      },
      defaultAttach: {
        files: [],
        progressUpload: [],
      },
      agency: [],
      area: [],
      agencyId: '',
      reload: false,
      searchAccount: null,
      wmText: '',
      isEdit: false,
      checkOnly: false,
      warnDlg: false,
    }
  },
  watch: {
    searchAccount (val) {
      val && val !== this.agencyItem.person_in_charge && this.searchEmployee(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_ACCOUNT_LIST, 'limit=-1')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.setWatermark()
  },
  computed: {
    ...mapGetters([
      "currentUser", "uploadSign", "areaList", "accountLoading", "accountList"
    ]),
    invoiceType () {
      return Util.categories('invoiceType')
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.agencyId = this.$route.params.id
      this.getAgency(this.agencyId)
    }
  },
  methods: {
    checkPermission(info) {
      if (info.creator._id === this.currentUser._id || info.person_in_charge.find(item => item._id === this.currentUser._id) || this.currentUser.hasAdmin.includes('distributor') || this.currentUser.permission.includes('Agent_Edit_Detail')) this.isEdit = true
      if (this.currentUser.permission.includes('Agent_Check_Confirm')) this.checkOnly = true
    },
    setWatermark() {
      this.wmText = this.currentUser.personal.name + this.currentUser.phone.substr(-4)
    },
    reloadAgency() {
      this.getAgency(this.agencyId)
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    doAction(action) {
      switch(action) {
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, this.agencyInfo.license_image).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'open_delete': {
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.warnDlg = false
          break
        }
        case 'confirm_delete': {
          this.$store.dispatch(REMOVE_AGENCY, this.agencyInfo._id).then(() => {
            this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
            this.warnDlg = false
            this.$router.push('/distributor/agency')
          }).catch((err) => {
            this.warnDlg = false
            this.$store.commit(SET_ERROR, {msg: err.response.data.message})
          })
          break
        }
      }
    },
    createContract() {
      this.$router.push('/distributor/agency/contract/edit')
    },
    getAgency(id) {
      this.$store.dispatch(FETCH_AGENCY, {query: id}).then((res) => {
        this.agencyInfo = res
        this.agencyInfo.invoice_type = this.agencyInfo.invoice_type.toString()
        this.checkPermission(this.agencyInfo)
        this.$forceUpdate()
      })
    },
    editAgency(action, title) {
      this.agencyItem = JSON.parse(JSON.stringify(this.agencyInfo))
      this.activeAction = action
      this.editDlgTitle = title
      this.editDlg = true
    },
    uploadAttach(file, index) {
      return new Promise((resolve, reject) => {
        let fileFullName = file.name
        fileFullName = fileFullName.replace(/[%#&]/g,"");

        let param = new FormData();
        param.append("name", fileFullName);
        param.append("key", "agency/" + this.uploadSign.key + "/" + fileFullName);
        param.append("policy", this.uploadSign.policy);
        param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
        param.append("success_action_status", 200);
        param.append("signature", this.uploadSign.signature);
        param.append("file", file, fileFullName);

        let config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.$set(this.editedAttach.progressUpload,index,Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            ))
          },
        };
        axios.post(this.uploadSign.host, param, config)
          .then(() => {
            resolve({
              href: param.get("key")
            })
          })
          .catch((error) => {
            reject(error.message)
          });
      });
    },
    async submitEdit() {
      let json = {}
      json.id = this.agencyInfo._id
      let obj = {}
      if (this.activeAction === 'invoice') {
        obj.invoice_type = Number(this.agencyItem.invoice_type)
        obj.invoice_rate = Number(this.agencyItem.invoice_rate)
        obj.invoice_info = this.agencyItem.invoice_info
        json.item = obj
        this.fetchSubmit(json)
      }else {
        obj[this.activeAction] = this.agencyItem[this.activeAction]
        json.item = obj
        this.fetchSubmit(json)
      }
    },
    fetchSubmit(json) {
      this.$store.dispatch(EDIT_AGENCY, json).then(() => {
        this.getAgency(this.agencyId)
        this.$store.commit(SET_ERROR, { msg: "修改成功！", color: "primary" })
        this.closeEdit()
      }).catch((err) => {
        this.$store.commit(SET_ERROR, { msg: err.data })
      })
    },
    closeEdit() {
      this.agencyItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.activeAction = ''
      this.editDlgTitle = ''
      this.editDlg = false
    },
  },
  directives: {}
}
</script>

<style scoped>
.panelTitle {
  padding-left: 14px;
  border-left: 2px solid #1976D2;
}
</style>
