var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "pa-4",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": _vm.editItemData.profileImage ? _vm.ossURL + '/' + _vm.editItemData.profileImage : '/static/error/empty_banner.png',
      "aspect-ratio": 24 / 9,
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "dark": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-center justify-center"
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-img', {
          attrs: {
            "src": _vm.editItemData.logoImage ? _vm.ossURL + '/' + _vm.editItemData.logoImage : '/static/error/empty_icon.png',
            "aspect-ratio": "1",
            "contain": "",
            "max-width": "125"
          }
        }, [_c('v-expand-transition', [hover ? _c('div', {
          staticClass: "d-flex transition-fast-in-fast-out white hover-btn"
        }, [_c('v-btn', {
          staticClass: "mr-4",
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem('logo_image', _vm.distributor);
            }
          }
        }, [_c('v-icon', [_vm._v("add_photo_alternate")])], 1)], 1) : _vm._e()])], 1)];
      }
    }])
  })], 1), _c('v-card-title', {
    staticClass: "text-center justify-center"
  }, [_vm._v(" " + _vm._s(_vm.editItemData.sub_title) + " ")]), _c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "dark": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('profile_image', _vm.distributor);
      }
    }
  }, [_c('v-icon', [_vm._v("add_photo_alternate")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "two-line": ""
    }
  }, [_c('v-subheader', [_vm._v("管理账号")]), _vm._l(_vm.editItemData.admin_account, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("assignment_ind")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.personal.name))]), _c('v-list-item-subtitle', [_vm._v("状态：" + _vm._s(item.isBlock ? '停用' : item.status === 1 ? '正常' : '停用') + " | 最后更新于 " + _vm._s(_vm._f("formatTime")(item.updateTime)))])], 1), _c('v-list-item-icon', [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.editItem('remove_admin', _vm.dealer, item);
        }
      }
    }, [_vm._v("remove_circle")])], 1)], 1);
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('add_admin', _vm.distributor);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加管理人员")], 1)], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "two-line": ""
    }
  }, [_c('v-subheader', [_vm._v("主体信息")]), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('name', _vm.editItemData);
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.name))]), _c('v-list-item-subtitle', [_vm._v("状态：" + _vm._s(_vm.editItemData.isBlock ? '暂停运营' : _vm.editItemData.status === 0 ? '正常' : '待审批') + " | 最后更新于 " + _vm._s(_vm._f("formatTime")(_vm.editItemData.updateTime)) + " ")])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("vpn_key")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.inviteCode))]), _c('v-list-item-subtitle', [_vm._v("邀请码作为员工注册时填写依据，不能修改。")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('sub_title', _vm.editItemData);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("subtitles")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.sub_title))]), _c('v-list-item-subtitle', [_vm._v("主体理念：企业宣传口号，文化理念")])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('contact', _vm.editItemData);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("contact_phone")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.contactName))]), _c('v-list-item-subtitle', [_vm._v("主体联系人及电话 " + _vm._s(_vm.editItemData.contactPhone))])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('address', _vm.editItemData);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("room")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.address))]), _c('v-list-item-subtitle', [_vm._v("主体所在地址 " + _vm._s(_vm.editItemData.province) + " " + _vm._s(_vm.editItemData.city) + " ")])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('limit', _vm.editItemData);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("vertical_align_top")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("该主体可以服务管理的数量上限")]), _c('v-list-item-subtitle', [_vm._v("代理商：" + _vm._s(_vm.editItemData.limit && _vm.editItemData.limit.agency) + " | 工单：" + _vm._s(_vm.editItemData.limit && _vm.editItemData.limit.cases) + " ")])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('block', _vm.editItemData);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("block")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("是否阻止主体使用本平台服务？")]), _c('v-list-item-subtitle', [_vm._v("当前主体运营状态：" + _vm._s(_vm.editItemData.isBlock ? '暂停' : '正常') + "，暂停运营同时将暂停该主体员工登陆。")])], 1), _c('v-list-item-action', [_c('v-switch', {
    model: {
      value: _vm.editItemData.isBlock,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "isBlock", $$v);
      },
      expression: "editItemData.isBlock"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.editItemData.status === 1 ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('auth', _vm.editItemData);
      }
    }
  }, [_vm._v("审核")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/admin/distributor/'
        });
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('delete', _vm.editItemData);
      }
    }
  }, [_vm._v("删除")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _vm.dlgTitle === '主体名称' ? _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "企业名称",
      "hint": "请输入主体的名称，该名称为全局唯一命名",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "name", $$v);
      },
      expression: "editItemData.name"
    }
  })], 1) : _vm._e(), _vm.dlgTitle === '主体理念' ? _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "主体理念",
      "hint": "请输入主体宣传口号，文化理念",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.sub_title,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "sub_title", $$v);
      },
      expression: "editItemData.sub_title"
    }
  })], 1) : _vm._e(), _vm.dlgTitle === '主体联系人' ? _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系人",
      "hint": "联系人作为主体和平台的主要沟通接口人",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "contactName", $$v);
      },
      expression: "editItemData.contactName"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.phonenum],
      "type": "number",
      "label": "联系电话",
      "hint": "电话号码作为主体和平台的主要沟通方式",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "contactPhone", $$v);
      },
      expression: "editItemData.contactPhone"
    }
  })], 1) : _vm._e(), _vm.dlgTitle === '主体地址' ? _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系地址",
      "hint": "请填写主体实际地址",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "address", $$v);
      },
      expression: "editItemData.address"
    }
  })], 1) : _vm._e(), _vm.dlgTitle === '服务上限' ? _c('v-card-text', [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    attrs: {
      "rules": [_vm.rules.required],
      "label": "代理商上限",
      "hint": "主体可以服务企业的数量上限",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.limit.agency,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.limit, "agency", $$v);
      },
      expression: "editItemData.limit.agency"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '#######',
      expression: "'#######'"
    }],
    attrs: {
      "rules": [_vm.rules.required],
      "label": "工单上限",
      "hint": "主体可以创建工单的数量上限",
      "outlined": ""
    },
    model: {
      value: _vm.editItemData.limit.cases,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.limit, "cases", $$v);
      },
      expression: "editItemData.limit.cases"
    }
  })], 1) : _vm._e(), _vm.dlgTitle === '增加管理人员' ? _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.register.msgName,
      "clearable": _vm.register.msgName !== '',
      "type": "text",
      "label": "账号名称",
      "hint": "账号名称默认为手机号码，将作为登陆名称使用",
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.register.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 3916666512),
    model: {
      value: _vm.register.username,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "username", $$v);
      },
      expression: "register.username"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.showPass ? 'text' : 'password',
      "append-icon": _vm.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.register.password,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "password", $$v);
      },
      expression: "register.password"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "error-messages": _vm.register.msgPhone,
      "clearable": _vm.register.msgPhone !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "手机号码可作为登陆名称使用",
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.register.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 857356587),
    model: {
      value: _vm.register.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "phone", $$v);
      },
      expression: "register.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": ""
    },
    model: {
      value: _vm.register.personal.name,
      callback: function callback($$v) {
        _vm.$set(_vm.register.personal, "name", $$v);
      },
      expression: "register.personal.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": ""
    },
    model: {
      value: _vm.register.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "employeeNo", $$v);
      },
      expression: "register.employeeNo"
    }
  })], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.$route.params.id !== '0' ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgEdit = false;
      }
    }
  }, [_vm._v("取消")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }