var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("代理商详情")])], 1), _c('v-divider'), _c('v-card-text', [_c('widgets-watermark', {
    attrs: {
      "watermarkText": _vm.wmText
    }
  }), _c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("基本信息")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "depressed": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_delete');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("delete")]), _vm._v("删除客户")], 1) : _vm._e()], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pl-8"
  }, [_vm._v(" " + _vm._s(_vm.agencyInfo.name) + " "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editAgency('name', '代理商名称');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 所属地区 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editAgency('area', '所属地区');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _vm.agencyInfo.area ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.agencyInfo.area.name) + " ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 代理客户数量 ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.agencyInfo.customer_count || '0') + " ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 代理商联系人 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editAgency('contact_name', '代理商联系人');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.agencyInfo.contact_name || '无') + " ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 联系电话 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editAgency('contact_phone', '联系电话');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.agencyInfo.contact_phone || '无') + " ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 内部负责人 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editAgency('person_in_charge', '内部负责人');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _vm.agencyInfo.person_in_charge ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.agencyInfo.person_in_charge.map(function (item) {
    return item.personal.name;
  })) + " ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1"
  }, [_vm._v(" 发票信息 "), _vm.isEdit ? _c('v-btn', {
    staticClass: "ml-2 mb-1",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editAgency('invoice', '发票信息');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm.agencyInfo.invoice_type == 0 ? _c('span', [_vm._v("增值税普通发票")]) : _vm.agencyInfo.invoice_type == 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e(), _c('span', [_vm._v(" | ")]), _c('span', [_vm._v(_vm._s(_vm.agencyInfo.invoice_rate ? _vm.agencyInfo.invoice_rate + '%' : '0'))])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("代理合同")]), _c('v-spacer'), _c('contract-edit', {
    attrs: {
      "getType": "agency",
      "getId": _vm.agencyId,
      "isEdit": _vm.isEdit
    }
  }, [_vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v(" 新增合同 ")], 1) : _vm._e()], 1)], 1), _c('contract-list', {
    attrs: {
      "getType": "agency",
      "contractType": "2",
      "getId": _vm.agencyId,
      "isEdit": _vm.isEdit,
      "checkOnly": _vm.checkOnly
    },
    on: {
      "reload": _vm.reloadAgency
    }
  }), _c('v-toolbar', {
    staticClass: "mt-4",
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("合作客户")])]), _c('agency-customer', {
    attrs: {
      "getType": "agency",
      "contractType": "3",
      "getId": _vm.agencyId
    },
    on: {
      "reload": _vm.reloadAgency
    }
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.editValid,
      callback: function callback($$v) {
        _vm.editValid = $$v;
      },
      expression: "editValid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(this.editDlgTitle))]), _c('v-divider'), _c('v-card-text', [_vm.editDlgTitle === '代理商名称' ? _c('v-text-field', {
    attrs: {
      "label": "代理商名称",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "person"
    },
    model: {
      value: _vm.agencyItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "name", $$v);
      },
      expression: "agencyItem.name"
    }
  }) : _vm._e(), _vm.editDlgTitle === '所属地区' ? _c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "prepend-icon": "map"
    },
    model: {
      value: _vm.agencyItem.area,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "area", $$v);
      },
      expression: "agencyItem.area"
    }
  }) : _vm._e(), _vm.editDlgTitle === '内部负责人' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "公司内部负责人",
      "hint": "请查找并选择公司内部负责人",
      "multiple": "",
      "prepend-icon": "emoji_people"
    },
    model: {
      value: _vm.agencyItem.person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "person_in_charge", $$v);
      },
      expression: "agencyItem.person_in_charge"
    }
  }) : _vm._e(), _vm.editDlgTitle === '代理商联系人' ? _c('v-text-field', {
    attrs: {
      "label": "代理商联系人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "connect_without_contact"
    },
    model: {
      value: _vm.agencyItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "contact_name", $$v);
      },
      expression: "agencyItem.contact_name"
    }
  }) : _vm._e(), _vm.editDlgTitle === '联系电话' ? _c('v-text-field', {
    attrs: {
      "label": "联系电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone"
    },
    model: {
      value: _vm.agencyItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "contact_phone", $$v);
      },
      expression: "agencyItem.contact_phone"
    }
  }) : _vm._e(), _vm.editDlgTitle === '发票信息' ? _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.invoiceType,
      "label": "发票类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "format_list_numbered_rtl"
    },
    model: {
      value: _vm.agencyItem.invoice_type,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "invoice_type", $$v);
      },
      expression: "agencyItem.invoice_type"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "发票费率",
      "outlined": "",
      "dense": "",
      "type": "number",
      "prepend-icon": "percent",
      "suffix": "%"
    },
    model: {
      value: _vm.agencyItem.invoice_rate,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem, "invoice_rate", $$v);
      },
      expression: "agencyItem.invoice_rate"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "抬头名称",
      "outlined": "",
      "dense": "",
      "prepend-icon": "apartment"
    },
    model: {
      value: _vm.agencyItem.invoice_info.title,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "title", $$v);
      },
      expression: "agencyItem.invoice_info.title"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "统一社会信用代码",
      "outlined": "",
      "dense": "",
      "prepend-icon": "pin"
    },
    model: {
      value: _vm.agencyItem.invoice_info.license_code,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "license_code", $$v);
      },
      expression: "agencyItem.invoice_info.license_code"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "开户行",
      "outlined": "",
      "dense": "",
      "prepend-icon": "account_balance"
    },
    model: {
      value: _vm.agencyItem.invoice_info.bank_name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "bank_name", $$v);
      },
      expression: "agencyItem.invoice_info.bank_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "银行账号",
      "outlined": "",
      "dense": "",
      "prepend-icon": "account_balance_wallet"
    },
    model: {
      value: _vm.agencyItem.invoice_info.bank_account,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "bank_account", $$v);
      },
      expression: "agencyItem.invoice_info.bank_account"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "企业地址",
      "outlined": "",
      "dense": "",
      "prepend-icon": "location_on"
    },
    model: {
      value: _vm.agencyItem.invoice_info.license_address,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "license_address", $$v);
      },
      expression: "agencyItem.invoice_info.license_address"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "企业电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "ring_volume"
    },
    model: {
      value: _vm.agencyItem.invoice_info.license_telephone,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "license_telephone", $$v);
      },
      expression: "agencyItem.invoice_info.license_telephone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "发票接收人",
      "outlined": "",
      "dense": "",
      "prepend-icon": "person"
    },
    model: {
      value: _vm.agencyItem.invoice_info.delivery_name,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "delivery_name", $$v);
      },
      expression: "agencyItem.invoice_info.delivery_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "接收人电话",
      "outlined": "",
      "dense": "",
      "prepend-icon": "phone_in_talk"
    },
    model: {
      value: _vm.agencyItem.invoice_info.delivery_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "delivery_phone", $$v);
      },
      expression: "agencyItem.invoice_info.delivery_phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "发票邮寄地址",
      "outlined": "",
      "dense": "",
      "prepend-icon": "home"
    },
    model: {
      value: _vm.agencyItem.invoice_info.delivery_address,
      callback: function callback($$v) {
        _vm.$set(_vm.agencyItem.invoice_info, "delivery_address", $$v);
      },
      expression: "agencyItem.invoice_info.delivery_address"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.editValid
    },
    on: {
      "click": _vm.submitEdit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.imgDlg,
      callback: function callback($$v) {
        _vm.imgDlg = $$v;
      },
      expression: "imgDlg"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "600"
    }
  }, [_c('v-card-title', [_vm._v(" 代理商营业执照 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.imgUrl
    }
  }), _c('v-overlay', {
    staticClass: "align-center justify-center",
    attrs: {
      "absolute": ""
    },
    model: {
      value: _vm.overlay,
      callback: function callback($$v) {
        _vm.overlay = $$v;
      },
      expression: "overlay"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除代理商 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除此代理商吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('confirm_delete');
      }
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }