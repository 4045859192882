<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>代理商管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" depressed>
              <v-icon left>add</v-icon>
              新增代理商
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="doAction('input_create')">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>手动填写</v-list-item-title>
            </v-list-item>
            <v-list-item @click="doAction('batch_create')">
              <v-list-item-icon>
                <v-icon>library_add</v-icon>
              </v-list-item-icon>
              <v-list-item-title>批量导入</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="query.key"
              label="代理商名称"
              outlined
              dense
              clearable
              hide-details
              @input="searchList"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="query.business_type"
              :items="userBusinessType"
              label="业务类型"
              item-text="name"
              item-value="code"
              multiple
              small-chips
              deletable-chips
              outlined
              dense
              clearable
              hide-details
              @change="searchList"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="query.area"
              :items="areaList"
              label="所属区域"
              item-text="name"
              item-value="_id"
              outlined
              dense
              clearable
              hide-details
              @change="searchList"
            ></v-select>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="query.person_in_charge"
              :items="accountList"
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              chips
              small-chips
              deletable-chips
              hide-details
              label="公司内部负责人"
              hint="请查找并选择公司内部负责人"
              multiple
              @change="searchList"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <widgets-watermark :watermarkText="wmText"></widgets-watermark>
        <v-data-table
          :options.sync="optionsItem"
          :headers="defaultHeader"
          :items="agencyList"
          :server-items-length="agencyCount"
          :loading="agencyLoading"
          loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
          class="elevation-0 mt-4"
          transition
          fixed-header
          height="600"
        >
          <template v-slot:item.code="{ item }">
            <td nowrap="true">{{ item.code }}</td>
          </template>
          <template v-slot:item.name="{ item }">
<!--            <td nowrap="true" class="v-list-item&#45;&#45;link primary&#45;&#45;text text-center" @click="goToDetail(item._id)">{{ item.name }}</td>-->
            <td nowrap="true">
              <router-link :to="'/distributor/agency/detail/' + item._id">{{ item.name }}</router-link>
            </td>
          </template>
          <template v-slot:item.area="{ item }">
            <span v-if="item.area">{{ item.area.name }}</span>
          </template>
          <template v-slot:item.customer_count="{ item }">
            <td nowrap="true" class="text-right">{{item.customer_count}}</td>
          </template>
          <template v-slot:item.invoice_rate="{ item }">
            <td nowrap="true" class="text-right">{{item.invoice_rate}}%</td>
          </template>
          <template v-slot:item.person_in_charge="{ item }">
            <td nowrap="true">{{ item.person_in_charge.map( item => item.personal.name ) }}</td>
          </template>
          <template v-slot:item.invoice_type="{ item }">
            <span v-if="item.invoice_type === 0">增值税普通发票</span>
            <span v-else-if="item.invoice_type === 1">增值税专用发票</span>
          </template>
          <template v-slot:item.creator="{ item }">
            <span>{{ item.creator ? item.creator.personal.name : '-' }}</span>
          </template>
          <template v-slot:item.createTime="{ item }">
            <span>{{ item.createTime | formatTime}}</span>
          </template>
          <template v-slot:item.edit_time="{ item }">
            <span>{{ item.updateTime | formatTime}}</span>
          </template>
<!--          <template v-slot:item.actions="{ item }">-->
<!--            <v-btn text color="error" small @click="doAction('delete_agency', item._id)">-->
<!--              <v-icon x-small>delete</v-icon>-->
<!--              删除-->
<!--            </v-btn>-->
<!--          </template>-->
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dlgInfo" persistent scrollable width="600px">
      <v-card>
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="6">
              <p>1. 下载新增模版，填写代理商信息.</p>
              <v-btn text @click="doAction('download_create_template')">下载模版<v-icon>file_download</v-icon></v-btn>
            </v-col>
            <v-col cols="6">
              <input
                id="import_file"
                type="file"
                name="import_file"
                ref="uploadImport"
                accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .zip,.rar,application/x-rar-compressed,application/zip,application/x-zip-compressed,application/octet-stream"
                :multiple="false"
                @change="importCreateFiles($event)"/>
              <p>2. 上传填好的文件.</p>
              <v-btn text @click="doAction('upload_create_file')" :loading="!batch.import_end"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="batch.import_failed.length">
            <v-col cols="12">
              <v-divider></v-divider>
              <v-alert
                outlined
                type="error"
                text
              >
                导入失败：<span v-if="batch.import_failed.length === 0">无</span><ul v-else><li v-for="(fail,index) in batch.import_failed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
              </v-alert>
              <v-alert
                outlined
                type="success"
                text
              >
                成功创建：{{ batch.import_result }} 条记录。
<!--                <v-row v-if="batch.import_result.length">-->
<!--                  <v-col class="grow">-->
<!--                    <p class="font-weight-black" v-for="item in batch.import_result" :key="item._id">{{ item.name }}</p>-->
<!--                  </v-col>-->
<!--                </v-row>-->
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除代理商
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除此代理商吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="deleteCustomer">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Util from "@/common/util";
import ApiService from "@/common/http";
import store from "@/store";
import {mapGetters} from "vuex";
import {
  FETCH_ACCOUNT_LIST,
  FETCH_AREA_LIST,
  FETCH_AGENCY_LIST,
  FETCH_URL,
  REMOVE_AGENCY
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  data() {
    return {
      optionsItem: {},
      query: {
        key: '',
        business_type: [],
        area: '',
        person_in_charge: [],
        contract_start: '',
        contract_end: '',
        contract_status: '',
      },
      defaultQuery: {
        key: '',
        business_type: '',
        area: '',
        person_in_charge: '',
        contract_start: '',
        contract_end: '',
        contract_status: '',
      },
      defaultHeader: [
        { text: '代理商编码', value: 'code', width: '120'},
        { text: '代理商名称', value: 'name', width: '120'},
        { text: '所属区域', value: 'area', width: '120'},
        { text: '代理客户数量', value: 'customer_count', width: '120'},
        { text: '内部负责人', value: 'person_in_charge', width: '140'},
        { text: '联系人', value: 'contact_name', width: '120'},
        { text: '联系电话', value: 'contact_phone', width: '120'},
        { text: '发票类型', value: 'invoice_type', width: '134'},
        { text: '发票费率(%)', value: 'invoice_rate', width: '120'},
        { text: '创建人', value: 'creator', width: '140'},
        { text: '创建时间', value: 'createTime', width: '120'},
        { text: '修改时间', value: 'edit_time', width: '120'},
        // { text: '操作', value: 'actions', width: '120', align: 'center', sortable: false},
      ],
      batch: {
        create_header: [],
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      defaultBatch: {
        create_header: [],
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      dlgInfo: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      warnDlg: false,
      deleteId: '',
      searchAccount: null,
      contract_end_menu: false,
      contract_start_menu: false,
      wmText: '',
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    searchAccount (val) {
      val && val !== this.query.person_in_charge && this.searchEmployee(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_ACCOUNT_LIST, 'limit=-1')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.setWatermark()
  },
  computed: {
    ...mapGetters([
      "currentUser", "userBusinessType", "agencyList", "agencyCount", "agencyLoading", "areaList", "accountLoading", "accountList"
    ]),
    contractStatus () {
      return Util.categories('contractStatus')
    },
  },
  mounted() {},
  methods: {
    setWatermark() {
      this.wmText = this.currentUser.personal.name + this.currentUser.phone.substr(-4)
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    resetFilter() {
      this.query = Object.assign({}, this.defaultQuery)
      this.getList()
    },
    searchList() {
      this.$router.push({ query: this.query });
      this.getList(this.query)
    },
    deleteCustomer() {
      this.$store.dispatch(REMOVE_AGENCY, this.deleteId).then(() => {
        this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
        this.warnDlg = false
        this.deleteId = ''
        this.getList(this.query)
      }).catch((err) => {
        this.warnDlg = false
        this.deleteId = ''
        this.$store.commit(SET_ERROR, {msg: err.response.data.message})
      })
    },
    getList(query = {}) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }

      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.business_type && !Array.isArray(this.query.business_type)) this.query.business_type = this.query.business_type.split(',')
        if (this.query.person_in_charge && !Array.isArray(this.query.person_in_charge)) this.query.person_in_charge = this.query.person_in_charge.split(',')
        this.query.business_type = this.query.business_type.map(i => Number(i))
      }

      let urlQuery = ''
      if (query.key) urlQuery += '&key=' + this.query.key
      if (query.business_type && query.business_type.length) urlQuery += '&business_type=' + this.query.business_type
      if (query.area) urlQuery += '&area=' + query.area
      if (query.person_in_charge && query.person_in_charge.length) urlQuery += '&person_in_charge=' + this.query.person_in_charge
      // if (query.contract_start) urlQuery += '&contract_start=' + query.contract_start
      // if (query.contract_end) urlQuery += '&contract_end=' + query.contract_end
      // if (query.contract_status) urlQuery += '&contract_status=' + query.contract_status

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_AGENCY_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    doAction(action, item = {}) {
      switch(action) {
        case 'batch_create': {
          this.dlgInfo = true
          break
        }
        case 'input_create': {
          this.$router.push('/distributor/agency/edit')
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, item).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'download_create_template': {
          ApiService.download('/agency/export?page=1&limit=-1&onlyheader=yes', {
            responseType:'blob'
          })
            .then(resp => {
              let fileName = '批量创建代理商模版.xlsx';
              let contentDisposition = resp.headers['content-disposition'];
              if (contentDisposition) {
                fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
              }
              let blob = new Blob([resp.data], {type: 'application/octet-stream'});
              if (window.navigator.msSaveOrOpenBlob) { //支持IE
                navigator.msSaveBlob(blob, fileName);
              } else {
                let link = document.createElement('a');
                link.style.display = "none";
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                this.downloading = false
                //释放内存
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
              }
            })
            .catch(err => { // 请求失败处理
              this.downloading = false
              store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'delete_agency': {
          this.deleteId = item
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.deleteId = ''
          this.warnDlg = false
          break
        }
      }
    },
    importCreateFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importCase(fileList[x]);
      })
    },
    importCase(file) {
      this.batch.import_end = false
      let param = new FormData()
      param.append('file', file)
      ApiService.post('/agency/import', param)
        .then((data) => {
          this.batch.import_result = data.data.Success
          this.batch.import_failed = data.data.Failed
          this.$refs.uploadImport.value = ''
          this.batch.import_end = true
          this.getList(this.query)
          if (!this.batch.import_failed.length) {
            this.dlgInfo = false
            store.commit(SET_ERROR, {msg: '成功导入' + this.batch.import_result + '个代理商', color: 'primary'})
          }
        })
        .catch((err) => {
          this.$refs.uploadImport.value = ''
          this.batch.import_result = { updateSum: 0, insertSum: 0 }
          this.batch.import_failed = [{line: 2, error: err.response.data.message}]
          this.batch.import_end = true
        })
    },
    goToDetail(id) {
      this.$router.push('/distributor/agency/detail/' + id)
    },
  },
  directives: {}
}
</script>

<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
