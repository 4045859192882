var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/distributor/customer/detail');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("新增合同")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.contractType,
      "label": "合同类型",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "list"
    },
    model: {
      value: _vm.customerItem.contract_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contract_type", $$v);
      },
      expression: "customerItem.contract_type"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "合同名称",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "gavel"
    },
    model: {
      value: _vm.customerItem.contract_name,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contract_name", $$v);
      },
      expression: "customerItem.contract_name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "年用电量",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "兆瓦/时",
      "prepend-icon": "bolt"
    },
    model: {
      value: _vm.customerItem.electric_use,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "electric_use", $$v);
      },
      expression: "customerItem.electric_use"
    }
  }), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "签约时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "fact_check"
          },
          model: {
            value: _vm.customerItem.sign_date,
            callback: function callback($$v) {
              _vm.$set(_vm.customerItem, "sign_date", $$v);
            },
            expression: "customerItem.sign_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.sign_date_menu,
      callback: function callback($$v) {
        _vm.sign_date_menu = $$v;
      },
      expression: "sign_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.sign_date_menu = false;
      }
    },
    model: {
      value: _vm.customerItem.sign_date,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "sign_date", $$v);
      },
      expression: "customerItem.sign_date"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同开始日期",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available"
          },
          model: {
            value: _vm.customerItem.contract_start,
            callback: function callback($$v) {
              _vm.$set(_vm.customerItem, "contract_start", $$v);
            },
            expression: "customerItem.contract_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      }
    },
    model: {
      value: _vm.customerItem.contract_start,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contract_start", $$v);
      },
      expression: "customerItem.contract_start"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同结束日期",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_busy"
          },
          model: {
            value: _vm.customerItem.contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.customerItem, "contract_end", $$v);
            },
            expression: "customerItem.contract_end"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      }
    },
    model: {
      value: _vm.customerItem.contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "contract_end", $$v);
      },
      expression: "customerItem.contract_end"
    }
  })], 1), _c('v-text-field', {
    attrs: {
      "label": "签约电价",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.customerItem.price,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "price", $$v);
      },
      expression: "customerItem.price"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.signType,
      "label": "签约方式",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "checklist"
    },
    model: {
      value: _vm.customerItem.sign_type,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "sign_type", $$v);
      },
      expression: "customerItem.sign_type"
    }
  }), _vm.customerItem.sign_type === 1 ? _c('v-text-field', {
    attrs: {
      "label": "鑫泰比例",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "%",
      "prepend-icon": "group_add"
    },
    model: {
      value: _vm.customerItem.xt_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "xt_ratio", $$v);
      },
      expression: "customerItem.xt_ratio"
    }
  }) : _vm._e(), _vm.customerItem.sign_type === 1 ? _c('v-text-field', {
    attrs: {
      "label": "客户比例",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "%",
      "prepend-icon": "group_remove"
    },
    model: {
      value: _vm.customerItem.supplier_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "supplier_ratio", $$v);
      },
      expression: "customerItem.supplier_ratio"
    }
  }) : _vm._e(), _vm.customerItem.sign_type === 2 ? _c('v-text-field', {
    attrs: {
      "label": "固定单价",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元/兆瓦/时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.customerItem.fix_price,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "fix_price", $$v);
      },
      expression: "customerItem.fix_price"
    }
  }) : _vm._e(), _vm.customerItem.sign_type === 3 ? _c('v-text-field', {
    attrs: {
      "label": "固定服务费",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.customerItem.fix_service,
      callback: function callback($$v) {
        _vm.$set(_vm.customerItem, "fix_service", $$v);
      },
      expression: "customerItem.fix_service"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": ""
    }
  }, [_vm._v(" 保存 ")]), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }