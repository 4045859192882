var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    staticClass: "fill-height overflow-y-auto"
  }, [_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Report_Company') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/report_company.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 公司数据 ")]), _c('v-card-text', [_vm._v("鑫泰能源公司内部数据统计")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/report/company'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Report_Area') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/report_area.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 区域汇总数据 ")]), _c('v-card-text', [_vm._v("各区域汇总数据统计")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/report/area'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Report_Agency') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/report_agency_1.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 代理商明细数据 ")]), _c('v-card-text', [_vm._v("鑫泰能源公司代理商明细数据统计")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/report/agency'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Report_Agency_Summary') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/card/report_agency.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 代理商汇总数据 ")]), _c('v-card-text', [_vm._v("鑫泰能源公司代理商汇总数据统计")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/distributor/report/agencySummary'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }