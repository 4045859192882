var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("代理商管理")]), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "depressed": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("add")]), _vm._v(" 新增代理商 ")], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.doAction('input_create');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("edit")])], 1), _c('v-list-item-title', [_vm._v("手动填写")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.doAction('batch_create');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("批量导入")])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "代理商名称",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.searchList
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "业务类型",
      "item-text": "name",
      "item-value": "code",
      "multiple": "",
      "small-chips": "",
      "deletable-chips": "",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.business_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "business_type", $$v);
      },
      expression: "query.business_type"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.areaList,
      "label": "所属区域",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.area,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "area", $$v);
      },
      expression: "query.area"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "hide-details": "",
      "label": "公司内部负责人",
      "hint": "请查找并选择公司内部负责人",
      "multiple": "",
      "clearable": ""
    },
    on: {
      "change": _vm.searchList
    },
    model: {
      value: _vm.query.person_in_charge,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "person_in_charge", $$v);
      },
      expression: "query.person_in_charge"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('widgets-watermark', {
    attrs: {
      "watermarkText": _vm.wmText
    }
  }), _c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.defaultHeader,
      "items": _vm.agencyList,
      "server-items-length": _vm.agencyCount,
      "loading": _vm.agencyLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": "",
      "height": "600"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.code",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.code))])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('router-link', {
          attrs: {
            "to": '/distributor/agency/detail/' + item._id
          }
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }, {
      key: "item.area",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.area ? _c('span', [_vm._v(_vm._s(item.area.name))]) : _vm._e()];
      }
    }, {
      key: "item.customer_count",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          staticClass: "text-right",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.customer_count))])];
      }
    }, {
      key: "item.invoice_rate",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          staticClass: "text-right",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.invoice_rate) + "%")])];
      }
    }, {
      key: "item.person_in_charge",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.person_in_charge.map(function (item) {
          return item.personal.name;
        })))])];
      }
    }, {
      key: "item.invoice_type",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.invoice_type === 0 ? _c('span', [_vm._v("增值税普通发票")]) : item.invoice_type === 1 ? _c('span', [_vm._v("增值税专用发票")]) : _vm._e()];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', [_vm._v(_vm._s(item.creator ? item.creator.personal.name : '-'))])];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.edit_time",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }])
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载新增模版，填写代理商信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_create_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .zip,.rar,application/x-rar-compressed,application/zip,application/x-zip-compressed,application/octet-stream",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importCreateFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.batch.import_end
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_create_file');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.batch.import_failed.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.batch.import_failed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.batch.import_failed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.batch.import_result) + " 条记录。 ")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 删除代理商 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm._v(" 确定删除此代理商吗？ ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_delete');
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteCustomer
    }
  }, [_vm._v("确认删除")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }