<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <widgets-watermark :watermarkText="wmText"></widgets-watermark>
        <v-data-table
          v-model="selectedItem"
          :headers="computedHeaders"
          :items="settleList"
          :options.sync="optionsItem"
          :server-items-length="settleCount"
          :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
          :loading="settleLoading"
          loading-text="数据读取中... 请稍后"
          item-key="_id"
          class="elevation-0 mt-4"
          transition
          fixed-header
          :show-select="!!query.agency"
          selectable-key="isSelectable"
          height="600"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{business_type ? userBusinessType.find(item => item.code === business_type).name +'结算数据' : '请先筛选业务类型后查看结算数据' }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mr-2" depressed @click="doAction('batch_create')" :disabled="!business_type || !isEdit">
                <v-icon left>upgrade</v-icon>
                数据导入
              </v-btn>
              <v-btn color="primary" depressed @click="doAction('create_case')" :disabled="!business_type || !selectedItem.length || !isEdit">
                <v-icon left>add</v-icon>
                创建工单
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small style="cursor: pointer;" class="ml-2">info</v-icon>
                </template>
                <span>创建工单必须先选择代理商</span>
              </v-tooltip>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon :disabled="!isEdit || !settleList.length" @click.stop="doAction('download_create_template', 'settle')">
                    <v-icon small>save_alt</v-icon>
                  </v-btn>
                </template>
                <span>数据导出</span>
              </v-tooltip>
              <widgets-custom-header v-if="defaultHeader.length" :defaultHeader="defaultHeader" :headerList="headerItem" :version=2 :tableName="settleHeader(business_type)" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('filter_item')">
                    <v-icon small>filter_list</v-icon>
                  </v-btn>
                </template>
                <span>过滤数据</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('reload_item')">
                    <v-icon small>autorenew</v-icon>
                  </v-btn>
                </template>
                <span>刷新列表</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{ item.date | formatMonth}}</span>
          </template>
          <template v-slot:item.area="{ item }">
            <span v-if="item.area">{{ item.area.name }}</span>
          </template>
          <template v-slot:item.agency="{ item }">
            <td nowrap="true" v-if="item.agency">{{ item.agency.name }}</td>
          </template>
          <template v-slot:item.customer="{ item }">
            <td nowrap="true" v-if="item.customer">{{ item.customer.name }}</td>
          </template>
          <template v-slot:item.report_elec_total="{ item }">
            <td nowrap="true" v-if="item.report_elec_total || item.report_elec_total === 0">{{ (item.report_elec_total).toFixed(3) }}</td>
          </template>
          <template v-slot:item.read_elec_total="{ item }">
            <td nowrap="true" v-if="item.read_elec_total || item.read_elec_total === 0">{{ (item.read_elec_total).toFixed(3) }}</td>
          </template>
          <template v-slot:item.settle_elec_total="{ item }">
            <td nowrap="true" v-if="item.settle_elec_total || item.settle_elec_total === 0">{{ (item.settle_elec_total).toFixed(3) }}</td>
          </template>
          <template v-slot:item.diff_elec_total="{ item }">
            <td nowrap="true" v-if="item.diff_elec_total || item.diff_elec_total === 0">{{ (item.diff_elec_total).toFixed(3) }}</td>
          </template>
          <template v-slot:item.agency_service_fee="{ item }">
            <td nowrap="true" v-if="item.agency_service_fee || item.agency_service_fee === 0">{{ item.agency_service_fee | formatMoney }}</td>
          </template>
          <template v-slot:item.agency_service_fee_no_tax="{ item }">
            <td nowrap="true" v-if="item.agency_service_fee_no_tax || item.agency_service_fee_no_tax === 0">{{ item.agency_service_fee_no_tax | formatMoney }}</td>
          </template>
          <template v-slot:item.agency_fee="{ item }">
            <td nowrap="true" v-if="item.agency_fee || item.agency_fee === 0">{{ item.agency_fee | formatMoney }}</td>
          </template>
          <template v-slot:item.agency_fee_no_tax="{ item }">
            <td nowrap="true" v-if="item.agency_fee_no_tax || item.agency_fee_no_tax === 0">{{ item.agency_fee_no_tax | formatMoney }}</td>
          </template>
          <template v-slot:item.diff_ratio_total="{ item }">
            <span v-if="item.diff_ratio_total || item.diff_ratio_total === 0">{{ (item.diff_ratio_total).toFixed(2) }}</span>
          </template>
          <template v-slot:item.tax="{ item }">
            <span v-if="item.tax || item.tax === 0">{{ (item.tax).toFixed(2) }}</span>
          </template>
          <!--调峰-->
          <template v-slot:item.project_income="{ item }">
            <td nowrap="true"  v-if="item.project_income || item.project_income === 0">{{ item.project_income | formatMoney }}</td>
          </template>
          <template v-slot:item.project_income_no_tax="{ item }">
            <td nowrap="true" v-if="item.project_income_no_tax || item.project_income_no_tax === 0">{{ item.project_income_no_tax | formatMoney }}</td>
          </template>
          <template v-slot:item.customer_ratio="{ item }">
            <span v-if="item.customer_ratio || item.customer_ratio === 0">{{ item.customer_ratio }}%</span>
          </template>
          <template v-slot:item.customer_tax_rate="{ item }">
            <span v-if="item.customer_tax_rate || item.customer_tax_rate === 0">{{ item.customer_tax_rate.toFixed(2) }}%</span>
          </template>
          <template v-slot:item.agency_tax_rate="{ item }">
            <span v-if="item.agency_tax_rate || item.agency_tax_rate === 0">{{ item.agency_tax_rate.toFixed(2) }}%</span>
          </template>
          <template v-slot:item.customer_invoice_type="{ item }">
            <span v-if="item.customer_invoice_type === 1">增值税专用发票</span>
            <span v-if="item.customer_invoice_type === 0">增值税普通发票</span>
          </template>
          <template v-slot:item.customer_service_fee_no_tax="{ item }">
            <td nowrap="true" v-if="item.customer_service_fee_no_tax || item.customer_service_fee_no_tax === 0">{{ item.customer_service_fee_no_tax | formatMoney }}</td>
          </template>
          <template v-slot:item.customer_tax="{ item }">
            <td nowrap="true" v-if="item.customer_tax || item.customer_tax === 0">{{ item.customer_tax | formatMoney }}</td>
          </template>
          <template v-slot:item.customer_fee="{ item }">
            <td nowrap="true" v-if="item.customer_fee || item.customer_fee === 0">{{ item.customer_fee | formatMoney }}</td>
          </template>
          <template v-slot:item.agency_ratio="{ item }">
            <span v-if="item.agency_ratio && item.agency_ratio.$numberDecimal">{{ item.agency_ratio?.$numberDecimal }}%</span>
            <span v-else-if="item.agency_ratio || item.agency_ratio === 0">{{ item.agency_ratio }}%</span>
            <span v-else></span>
          </template>
          <template v-slot:item.agency_tax="{ item }">
            <span v-if="item.agency_tax || item.agency_tax === 0">{{ item.agency_tax.toFixed(2) }}%</span>
          </template>
          <template v-slot:item.agency_invoice_type="{ item }">
            <span v-if="item.agency_invoice_type === 1">增值税专用发票</span>
            <span v-if="item.agency_invoice_type === 0">增值税普通发票</span>
          </template>
<!--          <template v-slot:item.agency_service_fee_no_tax="{ item }">-->
<!--            <span v-if="item.agency_service_fee_no_tax">{{ (item.agency_service_fee_no_tax).toFixed(2) }}</span>-->
<!--          </template>-->
          <template v-slot:item.tax="{ item }">
            <td nowrap="true" v-if="item.tax || item.tax === 0">{{ item.tax | formatMoney }}</td>
          </template>
<!--          <template v-slot:item.agency_fee="{ item }">-->
<!--            <span v-if="item.agency_fee">{{ (item.agency_fee).toFixed(2) }}</span>-->
<!--          </template>-->
          <!--能源-->
<!--          <template v-slot:item.agency_fee_no_tax_minus="{ item }">-->
<!--            <span v-if="item.agency_fee_no_tax_minus">{{ (item.agency_fee_no_tax_minus).toFixed(2) }}</span>-->
<!--          </template>-->
          <template v-slot:item.tax_rate="{ item }">
            <span v-if="item.tax_rate || item.tax_rate === 0">{{ item.tax_rate.toFixed(2) }}%</span>
          </template>
          <template v-slot:item.xt_service_fee="{ item }">
            <td nowrap="true" v-if="item.xt_service_fee || item.xt_service_fee === 0">{{ item.xt_service_fee | formatMoney }}</td>
          </template>
          <template v-slot:item.xt_service_fee_no_tax="{ item }">
            <td nowrap="true" v-if="item.xt_service_fee_no_tax || item.xt_service_fee_no_tax === 0">{{ item.xt_service_fee_no_tax | formatMoney }}</td>
          </template>
          <template v-slot:item.agency_fee_total="{ item }">
            <span v-if="item.agency_fee_total || item.agency_fee_total === 0">{{ (item.agency_fee_total).toFixed(2) }}</span>
          </template>
          <template v-slot:item.gross_income="{ item }">
            <td nowrap="true" v-if="item.gross_income || item.gross_income === 0">{{ item.gross_income | formatMoney }}</td>
          </template>
          <template v-slot:item.settle_status="{ item }">
            <span v-if="item.settle_status === 1">待结算</span>
            <span v-if="item.settle_status === 2" class="success--text">结算中</span>
            <span v-if="item.settle_status === 3">已结算</span>
          </template>
          <template v-slot:item.createTime="{ item }">
            <span>{{ item.createTime | formatTime }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                >
                  <v-icon small>more_vert</v-icon>
                </v-btn>
              </template>
                <v-list dense>
                  <settle-edit :editData="item" @reload="getList" v-if="item.settle_status === 1 && business_type === 3 && isEdit">
                    <v-list-item class="v-list-item&#45;&#45;link" @click="doAction('default')">
                      <v-list-item-icon>
                        <v-icon small>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> 编辑 </v-list-item-title>
                    </v-list-item>
                  </settle-edit>
                  <v-list-item class="v-list-item&#45;&#45;link" @click="doAction('change_record', item)">
                    <v-list-item-icon>
                      <v-icon small>edit_note</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> 变更记录 </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.settle_status !== 2 && isEdit" class="v-list-item&#45;&#45;link" @click="doAction('delete_settle', item._id)">
                    <v-list-item-icon>
                      <v-icon small color="error">delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> 删除 </v-list-item-title>
                  </v-list-item>
                </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <v-menu ref="sign_date" v-model="contract_start_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="query.start_date"
              label="结算日期开始月份"
              readonly
              outlined
              dense
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="resetQuery('start_date')"
            ></v-text-field>
          </template>
          <v-date-picker v-model="query.start_date" no-title scrollable type="month" width="225" @input="contract_start_menu = false" @change="searchList">
          </v-date-picker>
        </v-menu>
        <v-menu ref="sign_date" v-model="contract_end_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="query.end_date"
              label="结算日期结束月份"
              readonly
              outlined
              dense
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="resetQuery('end_date')"
            ></v-text-field>
          </template>
          <v-date-picker v-model="query.end_date" no-title scrollable type="month" width="225" @input="contract_end_menu = false" @change="searchList">
          </v-date-picker>
        </v-menu>
        <v-select
          v-model="query.area"
          :items="areaList"
          label="所属区域"
          item-text="name"
          item-value="_id"
          outlined
          dense
          clearable
          @change="searchList()"
        ></v-select>
        <v-autocomplete
          v-model="query.agency"
          :loading="agencyLoading"
          :items="agencyList"
          :search-input.sync="searchAgency"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          @change="searchList"
          @click:clear="resetAgencyList"
          outlined
          dense
          clearable
          label="代理商名称"
        ></v-autocomplete>
        <v-autocomplete
          v-model="query.customer"
          :loading="customerLoading"
          :items="customerList"
          :search-input.sync="searchCustomer"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          outlined
          clearable
          dense
          label="客户名称"
          @change="searchList()"
        ></v-autocomplete>
        <v-select
          v-model="business_type"
          :items="userBusinessType"
          label="业务类型"
          item-text="name"
          item-value="code"
          outlined
          dense
          @change="searchList('changeType')"
        ></v-select>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="doAction('clear_filter')">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgInfo" persistent scrollable width="600px">
      <v-card>
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"><v-divider></v-divider></v-col>
            <v-col cols="6">
              <p>1. 下载新增模版，填写信息.</p>
              <v-btn text @click="doAction('download_create_template', 'template')">下载模版<v-icon>file_download</v-icon></v-btn>
            </v-col>
            <v-col cols="6">
              <input
                id="import_file"
                type="file"
                name="import_file"
                ref="uploadImport"
                accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :multiple="false"
                @change="importCreateFiles($event)"/>
              <p>2. 上传填好的文件.</p>
              <v-btn text @click="doAction('upload_create_file')" :loading="!batch.import_end"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="batch.import_failed.length">
            <v-col cols="12">
              <v-divider></v-divider>
              <v-alert
                outlined
                type="error"
                text
              >
                导入失败：<span v-if="batch.import_failed.length === 0">无</span><ul v-else><li v-for="(fail,index) in batch.import_failed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
              </v-alert>
              <v-alert
                outlined
                type="success"
                text
              >
                成功创建：{{ batch.import_result }} 条记录。
<!--                <v-row v-if="batch.import_result.length">-->
<!--                  <v-col class="grow">-->
<!--                    <p class="font-weight-black" v-for="item in batch.import_result" :key="item._id">{{ item.name }}</p>-->
<!--                  </v-col>-->
<!--                </v-row>-->
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imgDlg" persistent scrollable width="600px">
      <v-card height="600">
        <v-card-title>
          客户营业执照
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_img')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-img :src="imgUrl"></v-img>
          <v-overlay
            v-model="overlay"
            absolute
            class="align-center justify-center"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="warnDlg" persistent scrollable width="400">
      <v-card>
        <v-card-title>
          删除结算数据
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_delete')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center py-6">
          确定删除此条结算数据吗？
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('close_delete')">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="deleteSettle">确认删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="recordDlg" persistent scrollable width="600px">
      <v-card>
        <v-card-title>
          变更记录
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_record')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <case-action-logs :caseData="settleRecord"></case-action-logs>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Util from "@/common/util";
import ApiService from "@/common/http";
import store from "@/store";
import {mapGetters} from "vuex";
import {
  FETCH_AREA_LIST,
  FETCH_AGENCY_LIST,
  FETCH_SETTLE_LIST,
  FETCH_URL,
  REMOVE_SETTLE, FETCH_CUSTOMER_LIST
} from "@/store/actions.type";
import {SET_ERROR, SET_SELECTED_SETTLE, SET_SETTLE_LIST, SET_AGENCY_LIST} from "@/store/mutations.type";
export default {
  data() {
    return {
      optionsItem: {},
      business_type: '',
      query: {
        start_date: '',
        end_date: '',
        agency: '',
        customer: '',
        area: '',
      },
      defaultQuery: {
        start_date: '',
        end_date: '',
        agency: '',
        customer: '',
        area: '',
      },
      defaultHeader: [],
      headerItem: [],
      sellingHeader: [
        { text: '结算日期', value: 'date', width: '180',  sortable: false},
        { text: '所属区域', value: 'area', width: '180',  sortable: false},
        { text: '客户名称', value: 'customer', width: '180',  sortable: false},
        { text: '代理商名称', value: 'agency', width: '180',  sortable: false},
        { text: '申报电量(兆瓦时)', value: 'report_elec_total', width: '180',  sortable: false},
        { text: '抄表电量(兆瓦时)', value: 'read_elec_total', width: '180',  sortable: false},
        { text: '结算电量(兆瓦时)', value: 'settle_elec_total', width: '180',  sortable: false},
        { text: '偏差电量(兆瓦时)', value: 'diff_elec_total', width: '180',  sortable: false},
        { text: '偏差率(%)', value: 'diff_ratio_total', width: '134',  sortable: false},
        { text: '代理商分成比例(%)', value: 'agency_ratio', width: '180',  sortable: false},
        { text: '代理商服务费(含税)(元)', value: 'agency_service_fee', width: '180',  sortable: false},
        { text: '代理商服务费(不含税)(元)', value: 'agency_service_fee_no_tax', width: '180',  sortable: false},
        { text: '税金及附加(元)', value: 'tax', width: '180',  sortable: false},
        { text: '代理商费用(含税)(元)', value: 'agency_fee', width: '180',  sortable: false},
        { text: '代理商费用(不含税)(元)', value: 'agency_fee_no_tax', width: '180',  sortable: false},
        { text: '状态', value: 'settle_status', width: '180',  sortable: false},
        { text: '创建时间', value: 'createTime', width: '180',  sortable: false},
        { text: '操作', value: 'actions', width: '180',  sortable: false},
      ],
      peakHeader: [
        { text: '所属区域', value: 'area', width: '180',  sortable: false},
        { text: '客户名称', value: 'customer', width: '180',  sortable: false},
        { text: '代理商名称', value: 'agency', width: '180',  sortable: false},
        { text: '结算日期', value: 'date', width: '180',  sortable: false},
        { text: '项目收入(含税)(元)', value: 'project_income', width: '180',  sortable: false},
        { text: '项目收入(不含税)(元)', value: 'project_income_no_tax', width: '180',  sortable: false},
        { text: '用户分成比例(%)', value: 'customer_ratio', width: '180',  sortable: false},
        { text: '用户税率(%)', value: 'customer_tax_rate', width: '180',  sortable: false},
        { text: '用户发票类型', value: 'customer_invoice_type', width: '134',  sortable: false},
        { text: '用户收益(不含税)(元)', value: 'customer_service_fee_no_tax', width: '180',  sortable: false},
        { text: '用户减：增值税差-税金及附加(元)', value: 'customer_tax', width: '180',  sortable: false},
        { text: '用户收益税价合计(元)', value: 'customer_fee', width: '180',  sortable: false},
        { text: '代理商分成比例(%)', value: 'agency_ratio', width: '180',  sortable: false},
        { text: '代理商税率(%)', value: 'agency_tax_rate', width: '180',  sortable: false},
        { text: '代理商发票类型', value: 'agency_invoice_type', width: '134',  sortable: false},
        { text: '代理商收益(不含税)(元)', value: 'agency_service_fee_no_tax', width: '180',  sortable: false},
        { text: '代理商减：增值税差-税金及附加(元)', value: 'tax', width: '180',  sortable: false},
        { text: '代理商收益税价合计(元)', value: 'agency_fee', width: '180',  sortable: false},
        { text: '状态', value: 'settle_status', width: '180',  sortable: false},
        { text: '创建时间', value: 'createTime', width: '180',  sortable: false},
        { text: '操作', value: 'actions', width: '180',  sortable: false},
      ],
      energyHeader: [
        { text: '所属区域', value: 'area', width: '180',  sortable: false},
        { text: '客户名称', value: 'customer', width: '180',  sortable: false},
        { text: '代理商名称', value: 'agency', width: '180',  sortable: false},
        { text: '结算日期', value: 'date', width: '180',  sortable: false},
        { text: '甲方收益(含税)(元)', value: 'xt_service_fee', width: '180',  sortable: false},
        { text: '甲方收益(不含税)(元)', value: 'xt_service_fee_no_tax', width: '180',  sortable: false},
        { text: '代理商分成比例(%)', value: 'agency_ratio', width: '180',  sortable: false},
        { text: '代理商费用(不含税)(元)', value: 'agency_service_fee_no_tax', width: '180',  sortable: false},
        { text: '代理商发票类型', value: 'agency_invoice_type', width: '134',  sortable: false},
        { text: '代理商税率(%)', value: 'agency_tax', width: '180',  sortable: false},
        { text: '税金及附加(元)', value: 'tax', width: '180',  sortable: false},
        { text: '税金及附加的税率(%)', value: 'tax_rate', width: '180',  sortable: false},
        { text: '代理商费用(不含税)(元)(减去税金和附加)', value: 'agency_fee_no_tax', width: '180',  sortable: false},
        { text: '代理商费用(价税合计)(元)', value: 'agency_fee', width: '180',  sortable: false},
        { text: '收益毛利(元)', value: 'gross_income', width: '180',  sortable: false},
        { text: '状态', value: 'settle_status', width: '180',  sortable: false},
        { text: '创建时间', value: 'createTime', width: '180',  sortable: false},
        { text: '操作', value: 'actions', width: '180',  sortable: false},
      ],
      zhikeHeader: [
        { text: '结算日期', value: 'date', width: '180',  sortable: false},
        { text: '所属区域', value: 'area', width: '180',  sortable: false},
        { text: '客户名称', value: 'customer', width: '180',  sortable: false},
        { text: '代理商名称', value: 'agency', width: '180',  sortable: false},
        { text: '代理商分成比例(%)', value: 'agency_ratio', width: '180',  sortable: false},
        { text: '代理商税率(%)', value: 'agency_tax_rate', width: '180',  sortable: false},
        { text: '代理商发票类型', value: 'agency_invoice_type', width: '134',  sortable: false},
        { text: '代理商服务费(含税)(元)', value: 'agency_service_fee', width: '180',  sortable: false},
        { text: '代理商服务费(不含税)(元)', value: 'agency_service_fee_no_tax', width: '180',  sortable: false},
        { text: '税金及附加(元)', value: 'tax', width: '180',  sortable: false},
        { text: '代理商费用(不含税)(元)', value: 'agency_fee_no_tax', width: '180',  sortable: false},
        { text: '代理商费用(含税)(元)', value: 'agency_fee', width: '180',  sortable: false},
        { text: '状态', value: 'settle_status', width: '180',  sortable: false},
        { text: '创建时间', value: 'createTime', width: '180',  sortable: false},
        { text: '操作', value: 'actions', width: '180',  sortable: false},
      ],
      batch: {
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      defaultBatch: {
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      dlgInfo: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      warnDlg: false,
      deleteId: '',
      searchAgency: null,
      searchCustomer: null,
      contract_end_menu: false,
      contract_start_menu: false,
      checkOutExcelHeader: [
        '人员姓名', '证件类型', '证件号码',
        '离开类型', '转出地址'
      ],
      checkOutExcelNotice: [['预解除人员清单导入系统后将更新解除人员状态，隔离人员在离开时扫描身份证后更新离开时间。']],
      selectedItem: [],
      filterDrawer: false,
      wmText: '',
      recordDlg: false,
      settleRecord: {},
      isEdit: false,
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    searchAgency (val) {
      val && val !== this.query.agency && this.getAgencyList(val)
    },
    searchCustomer (val) {
      val && val !== this.query.customer && this.getCustomerList(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true')
    ]).then(() => {
      next();
    });
  },
  created() {
    this.resetList()
    // store.dispatch(FETCH_AGENCY_LIST, {query: ''})
    if (this.userBusinessType && this.userBusinessType.length) {
      this.business_type = this.userBusinessType[0].code;
      this.searchList('changeType')
    }
    this.checkPermission()
    this.setWatermark()
  },
  computed: {
    ...mapGetters([
      "currentUser", "userBusinessType", "agencyList", "agencyCount", "agencyLoading", "areaList", "customerLoading", "customerList", "settleList", "settleCount", "settleLoading"
    ]),
    contractStatus () {
      return Util.categories('contractStatus')
    },
    computedHeaders() {
      let arrHeaderItem = this.headerItem
      return arrHeaderItem.filter(item => !item.align)
    },
  },
  mounted() {},
  methods: {
    checkPermission() {
      if (this.currentUser.hasAdmin.includes('distributor') || this.currentUser.permission.includes('Settle_Edit')) this.isEdit = true
    },
    setWatermark() {
      this.wmText = this.currentUser.personal.name + this.currentUser.phone.substr(-4)
    },
    settleHeader(settleType) {
      if (settleType === 1) {
        return 'settleEnergyHeader'
      } else if (settleType === 2) {
        return 'settlePeakHeader'
      } else if (settleType === 3) {
        return 'settleSaleHeader'
      } else {
        return 'settleZhikeHeader'
      }
    },
    resetQuery(p) {
      this.query[p] = ''
      this.searchList()
    },
    resetAgencyList() {
      store.commit(SET_AGENCY_LIST, {
        query: {},
        docs: []
      })
    },
    resetList() {
      store.commit(SET_SETTLE_LIST, {
        query: {},
        docs: []
      })
    },
    setHeader(val) {
      this.resetList()
      switch (val) {
        case 4: {
          this.defaultHeader = JSON.parse(JSON.stringify(this.zhikeHeader))
          this.getHeader('settle', 2)
          break
        }
        case 3: {
          this.defaultHeader = JSON.parse(JSON.stringify(this.sellingHeader))
          this.getHeader('settle', 2)
          break
        }
        case 2: {
          this.defaultHeader = JSON.parse(JSON.stringify(this.peakHeader))
          this.getHeader('settle', 2)
          break
        }
        case 1: {
          this.defaultHeader = JSON.parse(JSON.stringify(this.energyHeader))
          this.getHeader('settle', 2)
          break
        }
        default: {
          this.defaultHeader = []
          this.getHeader(null, 2)
          break
        }
      }
    },
    getHeader(model, version) {
      if (model) {
        let header = ''
        if (this.business_type === 1) {
          header = 'settleEnergyHeader'
        } else if (this.business_type === 2) {
          header = 'settlePeakHeader'
        } else if (this.business_type === 3) {
          header = 'settleSaleHeader'
        } else {
          header = 'settleZhikeHeader'
        }
        let userHeader = this.currentUser[header];
        if (userHeader.content.length) {
          if (userHeader.version < version) {
            this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
          } else {
            this.headerItem = JSON.parse(JSON.stringify(userHeader.content))
          }
        } else {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
        }
      } else {
        this.headerItem = []
      }
    },
    resetSelected() {
      this.selectedItem = []
    },
    getAgencyList (key) {
      store.dispatch(FETCH_AGENCY_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    getCustomerList (key) {
      store.dispatch(FETCH_CUSTOMER_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    resetFilter() {
      this.query = Object.assign({}, this.defaultQuery)
      this.getList()
    },
    searchList(val) {
      if (val === 'changeType') {
        this.setHeader(this.business_type)
      }
      this.selectedItem = []
      this.getList(this.query)
    },
    deleteSettle() {
      this.$store.dispatch(REMOVE_SETTLE, this.deleteId + '?business_type=' + this.business_type).then(() => {
        this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
        this.warnDlg = false
        this.deleteId = ''
        this.getList(this.query)
      }).catch((err) => {
        this.warnDlg = false
        this.deleteId = ''
        this.$store.commit(SET_ERROR, {msg: err.response.data.message})
      })
    },
    getList(query = {}) {
      if (this.business_type) {
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
        let sort = "";
        if (sortBy && sortBy.length === 1) {
          if (sortDesc[0] === true) {
            sort = "-" + sortBy[0];
          } else {
            sort = sortBy[0];
          }
        }

        let urlQuery = ''
        if (this.business_type) urlQuery += '&business_type=' + this.business_type
        if (query.area) urlQuery += '&area=' + query.area
        if (query.agency) urlQuery += '&agency=' + query.agency
        if (query.customer) urlQuery += '&customer=' + query.customer
        if (query.start_date) urlQuery += '&start_date=' + query.start_date
        if (query.end_date) urlQuery += '&end_date=' + query.end_date

        if (Object.keys(this.optionsItem).length > 0) {
          store.dispatch(FETCH_SETTLE_LIST, {
            query:
              "page=" +
              page +
              "&limit=" +
              itemsPerPage +
              "&sort=" +
              sort +
              urlQuery,
          })
        }
      }
    },
    doAction(action, item = {}) {
      switch(action) {
        case 'batch_create': {
          this.dlgInfo = true
          break
        }
        case 'create_case': {
          // 校验区域是否一致
          const settleArea = this.selectedItem.map(item => item.area._id)
          if (Util.findDifferentValues(settleArea).length > 1) {
            store.commit(SET_ERROR, {msg: '必须选择同一区域的数据进行结算。'});
            break
          }
          let headers = []
          if (this.business_type === 1) {
            headers = this.energyHeader
          } else if (this.business_type === 2) {
            headers = this.peakHeader
          } else if (this.business_type === 3) {
            headers = this.sellingHeader
          } else {
            headers = this.zhikeHeader
          }
          store.commit(SET_SELECTED_SETTLE, {
            headers: headers,
            list: this.selectedItem,
            type: this.business_type,
            agency: this.query.agency,
            area: settleArea[0]
          })
          this.$router.push('/distributor/settle/case')
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, item).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'download_create_template': {
          let urlQuery = '&onlyheader='
          if (item === 'template') {
            urlQuery += 'yes&business_type='+this.business_type
          } else if (item === 'settle') {
            if (this.business_type) urlQuery += '&business_type=' + this.business_type
            if (this.query.area) urlQuery += '&area=' + this.query.area
            if (this.query.agency) urlQuery += '&agency=' + this.query.agency
            if (this.query.customer) urlQuery += '&customer=' + this.query.customer
            if (this.query.start_date) urlQuery += '&start_date=' + this.query.start_date
            if (this.query.end_date) urlQuery += '&end_date=' + this.query.end_date
          }
          ApiService.download('/settle/export?page=1&limit=-1'+urlQuery, {
            responseType:'blob'
          })
            .then(resp => {
              let type = ''
              this.business_type === 1 ? type = '能源' : this.business_type === 2 ? type = '调峰' : this.business_type === 3 ? type = '售电' : type = '智科'
              let fileName = type + (item === 'template'?'-结算数据导入模版  .xlsx':'-结算基础数据导出  .xlsx');
              let contentDisposition = resp.headers['content-disposition'];
              if (contentDisposition) {
                fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
              }
              let blob = new Blob([resp.data], {type: 'application/octet-stream'});
              if (window.navigator.msSaveOrOpenBlob) { //支持IE
                navigator.msSaveBlob(blob, fileName);
              } else {
                let link = document.createElement('a');
                link.style.display = "none";
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                this.downloading = false
                //释放内存
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
              }
            })
            .catch(err => { // 请求失败处理
              this.downloading = false
              store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'delete_settle': {
          this.deleteId = item
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.deleteId = ''
          this.warnDlg = false
          break
        }
        case 'filter_item': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.business_type = ''
          this.searchList('changeType')
          this.$router.push({ query: {} });
          this.getList()
          break
        }
        case 'reload_item': {
          this.optionsItem.page = 1
          this.getList()
          break
        }
        case 'cancel_header': {
          this.getHeader('settle', 2)
          this.$forceUpdate()
          break
        }
        case 'set_header': {
          this.getHeader('settle', 2)
          this.$forceUpdate()
          break
        }
        case 'change_record': {
          this.settleRecord = item
          this.recordDlg = true
          break
        }
        case 'close_record': {
          this.recordDlg = false
          this.settleRecord = {}
          break
        }
        case 'default': {
          console.log('action')
          break
        }
      }
    },
    importCreateFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importCase(fileList[x]);
      })
    },
    importCase(file) {
      this.batch.import_end = false
      let param = new FormData()
      param.append('business_type', this.business_type)
      param.append('file', file)
      ApiService.post('/settle/import', param)
        .then((data) => {
          this.batch.import_result = data.data.Success
          this.batch.import_failed = data.data.Failed
          this.$refs.uploadImport.value = ''
          this.batch.import_end = true
          this.getList(this.query)
          if (!this.batch.import_failed.length) {
            this.dlgInfo = false
            store.commit(SET_ERROR, {msg: '成功导入' + this.batch.import_result + '条结算数据', color: 'primary'})
          }
        })
        .catch((err) => {
          this.$refs.uploadImport.value = ''
          this.batch.import_result = { updateSum: 0, insertSum: 0 }
          this.batch.import_failed = [{line: 2, error: err.response.data.message}]
          this.batch.import_end = true
        })
    },
    setRowClass(item) {
      if (item.settle_status !== 1) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
  },
  directives: {}
}
</script>

<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
